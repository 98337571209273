import React, { Component } from "react";
import { Paper, Tabs, Tab } from "@material-ui/core";
import { connect } from "react-redux";

import FleetDialog from "../ui/dialogbox/FleetDialog";
import { PAGE_ACTIONS } from "../../actions/PageActions";

import MyAccount from "./MyAccount";
import IncidentListTab from "./FireApp/IncidentList";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  handleChange = (event, value) => {
    this.setState({
      activeTab: value,
    });
  };

  toggleSettingsDialog = () => {
    const { settingsMenu } = this.props.pageReducer;
    this.props.toggleSettingsMenu({
      settingsMenu: !settingsMenu,
    });
  };

  saveSettings = () => {};

  render() {
    const { activeTab } = this.state;
    const { pageReducer } = this.props;
    const { settingsMenu } = pageReducer;

    return (
      <FleetDialog
        open={settingsMenu}
        onCloseSettings={this.toggleSettingsDialog}
        title={"Settings"}
        maxWidth="md"
        fullWidth={true}
        saveButton={true}
        cancelButton={true}
        cancelAction={this.toggleSettingsDialog}
        saveAction={this.saveSettings}
        saveBtnLabel="OK"
        classNameWrapper="profileSettingsWrapper"
        draggable={false}
      >
        <Paper square className="settingsTabContainer">
          <Tabs
            value={activeTab}
            onChange={this.handleChange}
            variant="scrollable"
            scrollButtons="auto"
            className="settingsTab"
            classes={{
              root: "settingsTabs",
              indicator: "settingsTabIndicator",
              scrollButtons: "scrollButtons",
            }}
          >
            <Tab
              label="My Account"
              classes={{
                root: "myAccount",
              }}
              className={activeTab === 0 ? "activeTab" : ""}
              key={"settings-myaccount"}
              disabled={false}
            />
            <Tab
              label="Incident List"
              classes={{
                root: "incidentListTab",
              }}
              className={activeTab === 1 ? "activeTab" : ""}
              key={"settings-incidents"}
              disabled={false}
            />
            {/* <Tab
              label="Vehicles List"
              classes={{
                root: "vehiclesListTab"
              }}
              className={activeTab === 1 ? "activeTab" : ""}
              key={"settings-vehicles"}
              disabled={false}
            />
            <Tab
              label="Vehicle Users List"
              classes={{
                root: "vehicleUsersListTab"
              }}
              className={activeTab === 2 ? "activeTab" : ""}
              key={"settings-vehicleusers"}
              disabled={false}
            />
            <Tab
              label="Vehicle Groups List"
              classes={{
                root: "vehicleGroupsListTab"
              }}
              className={activeTab === 3 ? "activeTab" : ""}
              key={"settings-vehiclegroups"}
              disabled={false}
            />
            <Tab
              label="Sub Accounts"
              classes={{
                root: "subAccounts"
              }}
              className={activeTab === 4 ? "activeTab" : ""}
              key={"settings-subaccounts"}
              disabled={false}
            /> */}
          </Tabs>

          {activeTab === 0 && <MyAccount />}

          {activeTab === 1 && <IncidentListTab />}

          {/* {activeTab === 1 && <VehiclesList />}

          {activeTab === 2 && <UsersVehiclesList />}

          {activeTab === 3 && <UserGroups />}

          {activeTab === 4 && <SubAccounts />} */}
        </Paper>
      </FleetDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageReducer: state.pageReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSettingsMenu: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_SETTINGS_MENU,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
