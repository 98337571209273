import React, { Component } from "react";
import { IconButton } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import "./videocall.css";

import {
  ZoomOutMap,
  SwitchVideo,
  FullscreenExit,
  SignalCellularConnectedNoInternet1Bar as NoSignal,
  Rotate90DegreesCcw as RotateVideoIcon,
  Repeat as MirrorIcon,
} from "@material-ui/icons";

class VideoCall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switchCamStatus: true,
      fullScreen: null,
    };
  }

  componentDidMount() {
    let self = this;

    if (self.props.videoCallDialog === "md") {
      self.props.callRequest();
    }

    navigator.permissions
      .query({ name: "microphone" })
      .then((permissionObj) => {})
      .catch((error) => {
        console.log("Got error :", error);
      });

    navigator.permissions
      .query({ name: "camera" })
      .then((permissionObj) => {})
      .catch((error) => {
        console.log("Got error :", error);
      });
  }

  componentWillUnmount() {}

  videoResize = () => {};

  onToggleSwitchCam = () => {
    const { switchCamStatus, fullScreen } = this.state;
    let screen = fullScreen;
    if (fullScreen) {
      if (fullScreen === "video1") {
        screen = "video2";
      } else {
        screen = "video1";
      }
    }
    this.setState({
      switchCamStatus: !switchCamStatus,
      fullScreen: screen,
    });
  };

  onFullScreen = (videodiv, result) => {
    if (result === videodiv) {
      videodiv = null;
    }
    this.setState({
      fullScreen: videodiv,
    });
  };

  onToggleRotateCam = () => {
    let elem = document.querySelector(".videoRoom.large video");
    if (elem) {
      if (elem.classList.contains("rotated90")) {
        elem.classList.remove("rotated90");
        elem.classList.add("rotated270");
      } else if (elem.classList.contains("rotated270")) {
        this.removeAllRotates();
      } else {
        elem.classList.add("rotated90");
      }
    }
  };

  removeAllRotates = () => {
    let elem = document.querySelector(".videoRoom.large video");
    elem.classList.remove("rotated90");
    elem.classList.remove("rotated270");
  };

  onToggleMirrorCam = () => {
    let elem = document.querySelector(".videoRoom.large video");
    if (elem) {
      if (elem.classList.contains("mirror")) {
        elem.classList.remove("mirror");
      } else {
        elem.classList.add("mirror");
      }
    }
  };

  render() {
    const { switchCamStatus, fullScreen } = this.state;

    const { remoteUserName, loginReducer, fireReducer } = this.props;

    const { userName } = loginReducer;

    const { slowLink } = fireReducer;

    return (
      <div id="videocallWrapper">
        <div id="videoRooms">
          <div className="switchCamBtn">
            <IconButton
              classes={{ root: "camerarotate" }}
              onClick={this.onToggleRotateCam}
              title="Rotate Video"
            >
              <RotateVideoIcon />
            </IconButton>
            <IconButton
              classes={{ root: "menucolor" }}
              onClick={this.onToggleSwitchCam}
              title="Toggle Video"
            >
              <SwitchVideo />
            </IconButton>
            <IconButton
              classes={{ root: "cameramirror" }}
              onClick={this.onToggleMirrorCam}
              title="Mirror Camera"
            >
              <MirrorIcon />
            </IconButton>
            {slowLink && (
              <div className="slowlink" title="Slow Network">
                <NoSignal />
              </div>
            )}
          </div>

          <div
            id="videoRoom1"
            className={
              (switchCamStatus ? "videoRoom small" : "videoRoom large") +
              (fullScreen === null
                ? ""
                : fullScreen === "video2"
                ? " hide"
                : " show")
            }
            style={{ display: "none" }}
          >
            <h4>{userName}</h4>
            <video id="video1" autoPlay={true} muted="muted"></video>
            <div className="actionbtns">
              <IconButton
                classes={{ root: "menucolor" }}
                onClick={() => this.onFullScreen("video1", fullScreen)}
              >
                {fullScreen === "video1" ? <FullscreenExit /> : <ZoomOutMap />}
              </IconButton>
            </div>
          </div>

          <div
            id="videoRoom2"
            className={
              (switchCamStatus ? "videoRoom large" : "videoRoom small") +
              (fullScreen === null
                ? ""
                : fullScreen === "video1"
                ? " hide"
                : " show")
            }
          >
            <h4>{remoteUserName}</h4>
            <video id="video2" autoPlay={true}></video>
            <div className="actionbtns">
              <IconButton
                classes={{ root: "menucolor" }}
                onClick={() => this.onFullScreen("video2", fullScreen)}
              >
                {fullScreen === "video2" ? <FullscreenExit /> : <ZoomOutMap />}
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
    pageReducer: state.pageReducer,
    fireReducer: state.fireReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(VideoCall));
