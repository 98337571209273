import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { ArrowForward } from "@material-ui/icons";
import {
  CircularProgress,
  Link,
  Breadcrumbs,
  Backdrop,
} from "@material-ui/core";

import jQuery from "jquery";
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.flash";
import "pdfmake/build/vfs_fonts";
import "pdfmake/build/pdfmake";

import ReportTopbar from "../layouts/ReportTopbar";
import ReportLeftBar from "../layouts/ReportLeftBar";
import ReportFooter from "../layouts/ReportFooter";

import "../reports.css";
import "../datatables.min.css";

import UiDatePicker from "../../ui/uidatepicker/UiDatePicker";
import AutoComplete from "../../ui/autocomplete/AutoComplete";
import Btn from "../../ui/button/Btn";

import { API_Map } from "../../apihandler/Map";
import { API_UserLogin } from "../../apihandler/UserLogin";
import { COMMON_ACTIONS } from "../../../actions/CommonActions";
import { REPORT_ACTIONS } from "../../../actions/ReportActions";
import { dateFunction } from "../../common/datefunctions";
import {
  NOTIFY,
  ALERTS,
  FORMATS,
  REPORT_NAMES,
  REPORT_DESCRIPTION,
  DEFAULTS,
} from "../../common/consts";
import { commonJs } from "../../common/common";
import { API_Reports } from "../../apihandler/Reports";

const DefaultDismissBtn = () => {
  return <button className="snackbar-btn">{"Dismiss"}</button>;
};

var $ = jQuery;

class JourneyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicles: [],
      selectedVehicle: [],
      stopTimes: [
        {
          id: "5",
          name: "5 Minutes",
        },
        {
          id: "10",
          name: "10 Minutes",
        },
        {
          id: "15",
          name: "15 Minutes",
        },
        {
          id: "30",
          name: "30 Minutes",
        },
        {
          id: "60",
          name: "60 Minutes",
        },
      ],
      selectedStoptime: {
        id: "5",
        name: "5 Minutes",
      },
      fdt: dateFunction.getDate("thismonth", "from", FORMATS.SQL_DATE),
      tdt: dateFunction.getDate("today", "to", FORMATS.SQL_DATE),
    };
  }

  componentDidMount() {
    document.getElementById("root").classList.add("scroll");
    this.getInitialData();
  }

  getInitialData = () => {
    const args = {};
    let self = this;
    args.pageNo = 0;
    args.pageLength = 100000;
    args.orderBy = "ASC";
    args.sortColumn = "";
    API_UserLogin.setToken(localStorage.token);
    API_Map.getAllVehicles(args)
      .then((res) => {
        self.props.setVehicles({
          vehicles: res,
        });
        self.props.setLoading({
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onChangeVehicle = (vehicle) => {
    this.setState({
      selectedVehicle: vehicle,
    });
  };

  onChangeStoptime = (val) => {
    this.setState({
      selectedStoptime: val,
    });
  };

  loadReport = () => {
    const { selectedVehicle, selectedStoptime, fdt, tdt } = this.state;
    let self = this;

    if (selectedVehicle.length === 0) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        ALERTS.PLEASE_SELECT_VEHICLE,
        <DefaultDismissBtn />
      );
      return;
    } else if (selectedStoptime.length === 0) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        ALERTS.PLEASE_SELECT_STOPTIME,
        <DefaultDismissBtn />
      );
      return;
    }

    this.props.setLoading({
      loading: true,
    });

    API_Reports.getJourneyReport({
      pageNo: 1,
      pageLength: DEFAULTS.DEFAULT_PAGE_LENGTH,
      fromDate: fdt,
      toDate: tdt,
      imei: selectedVehicle.id,
      samplingTime: selectedStoptime.id,
    })
      .then((res) => {
        self.props.setLoading({
          loading: false,
        });
        if (res.Result === 200) {
          const { cols, rows } = res.Response;
          let columns = [];
          for (var c = 0; c < cols.length; c++) {
            columns.push({
              title: cols[c],
            });
          }

          for (let r = 0; r < rows.length; r++) {
            rows[r][6] = dateFunction.convSecondsToString(rows[r][6]);
          }

          if ($.fn.dataTable.isDataTable("#reportGrid")) {
            let table = $("#reportGrid").DataTable();
            table.destroy();
          }
          $("#reportGrid").empty();

          let title =
            "Journey Report (" +
            fdt +
            " - " +
            tdt +
            " - " +
            selectedVehicle.name +
            ")";

          $("#reportGrid").DataTable({
            dom: "Bfrtip",
            buttons: [
              {
                extend: "copy",
                className: "reportBtn",
                title: title,
              },
              {
                extend: "pdf",
                className: "reportBtn",
                title: title,
              },
              {
                extend: "excel",
                className: "reportBtn",
                title: title,
              },
              {
                extend: "csv",
                className: "reportBtn",
                title: title,
                text: "CSV",
              },
              {
                extend: "print",
                className: "reportBtn",
                title: title,
                customize: function(win) {
                  $(win.document.body)
                    .find("h1")
                    .remove();
                  $(win.document.body)
                    .find("table")
                    .attr("cellpadding", "3");
                  $(win.document.body)
                    .find("table")
                    .css("font-size", "10px");
                  $(win.document.body)
                    .find("table")
                    .css(
                      "cssText",
                      "border-bottom: none;border-left: none;border-right: none;"
                    );
                  $(win.document.body)
                    .find("td")
                    .css("font-size", "8px");
                  $(win.document.body)
                    .find("th")
                    .css("font-size", "8px");
                  $(win.document.body)
                    .find("tbody td")
                    .css(
                      "cssText",
                      "border: 1px solid #000;border-top: 0;border-right: 0;"
                    );
                  $(win.document.body)
                    .find("tbody td:last-child")
                    .css("cssText", "border: 1px solid #000;border-top: 0;");
                  $(win.document.body)
                    .find("table th")
                    .css("cssText", "border: 1px solid #000;border-right: 0;");
                  $(win.document.body)
                    .find("table th:last-child")
                    .css("cssText", "border: 1px solid #000;");
                },
              },
            ],
            paging: false,
            scrollY: 400,
            columns: columns,
            data: rows,
          });
        }
      })
      .catch((err) => {
        console.log("getDailyTravel: err:", err);
      });
  };

  handleDateChange = (type, dt) => {
    if (type === "from") {
      this.setState({
        fdt: dt,
      });
    } else {
      this.setState({
        tdt: dt,
      });
    }
  };

  render() {
    const { vehiclesList } = this.props.commonReducer;

    const { loading } = this.props.reportReducer;

    const {
      selectedVehicle,
      fdt,
      tdt,
      stopTimes,
      selectedStoptime,
    } = this.state;

    let vehicles = [];
    for (var i = 0; i < vehiclesList.length; i++) {
      vehicles.push({ id: vehiclesList[i].imei, name: vehiclesList[i].name });
    }

    return (
      <div className="reports">
        <ReportTopbar />
        <ReportLeftBar />
        <div className="reportPageWrapper height100">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Link color="inherit" href="/">
              Home
            </Link>
            <p>{REPORT_NAMES.JOURNEY_REPORT}</p>
          </Breadcrumbs>
          <p className="reportDescription">
            {REPORT_DESCRIPTION.JOURNEY_REPORT}
          </p>
          <div className="reportFilters">
            <div
              className="width20"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <AutoComplete
                label={"Select Vehicle"}
                list={vehicles}
                onChange={this.onChangeVehicle}
                defaultValue={selectedVehicle}
                multiple={false}
                size="small"
              />
            </div>
            <div className="width15" style={{ paddingLeft: 8 }}>
              <AutoComplete
                label={"Select Stoptime"}
                list={stopTimes}
                onChange={this.onChangeStoptime}
                defaultValue={selectedStoptime}
                multiple={false}
                size="small"
              />
            </div>
            <div className="width30 uidatepicker">
              <UiDatePicker
                label="From Date :"
                dt={fdt}
                type="from"
                disablePast={false}
                disableFuture={true}
                disabled={false}
                datepicker={false}
                // maxDate={dateFunction.getDate("thismonth", "to")}
                handleDateChange={this.handleDateChange}
              />
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ArrowForward />
              </div>
              <UiDatePicker
                label="To Date :"
                dt={tdt}
                type="to"
                disablePast={false}
                disableFuture={true}
                disabled={false}
                // maxDate={dateFunction.getDate("thismonth", "to")}
                handleDateChange={this.handleDateChange}
              />
            </div>
            <div className="width10 padLeft-10">
              <Btn onClick={this.loadReport} className="primaryBtn">
                Submit
              </Btn>
            </div>
          </div>
          <div className="reportGridWrapper">
            <table
              id="reportGrid"
              className="mdl-data-table width100"
              cellSpacing="0"
              cellPadding="3"
            />
          </div>
        </div>

        <ReportFooter />

        <Backdrop className={"backdrop"} open={loading}>
          <CircularProgress className="whiteLoader" />
        </Backdrop>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commonReducer: state.commonReducer,
    reportReducer: state.reportReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setVehicles: (values) => {
      dispatch({
        type: COMMON_ACTIONS.SET_VEHICLES,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: REPORT_ACTIONS.SET_LOADING,
        values,
      });
    },
    toggleReportPopup: (values) => {
      dispatch({
        type: REPORT_ACTIONS.TOGGLE_REPORT_POPUP,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(JourneyReport));
