import React, { Component } from "react";

import FleetDialog from "../../../../ui/dialogbox/FleetDialog";

class ConfirmDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { showConfirmDialog: false, id: "", name: "" };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  toggleConfirmDialog = (id, name) => {
    const { showConfirmDialog } = this.state;
    if (typeof id === "object") {
      this.setState({
        showConfirmDialog: !showConfirmDialog
      });
    } else {
      this.setState({
        showConfirmDialog: !showConfirmDialog,
        id,
        name
      });
    }
  };

  render() {
    const { showConfirmDialog, id, name } = this.state;
    return (
      <FleetDialog
        open={showConfirmDialog}
        onCloseSettings={this.toggleConfirmDialog}
        title={id === "" ? "Delete All Zones" : "Delete Zone"}
        maxWidth="xs"
        fullWidth={false}
        cancelButton={true}
        cancelAction={this.toggleConfirmDialog}
        saveAction={() => this.props.deleteZone()}
        saveBtnLabel="OK"
        disableBackdropClick={false}
        disableBackdrop={false}
        classNameWrapper="confirmDialog"
      >
        <p style={{ fontSize: 14 }}>
          Are you sure want to{" "}
          {id === "" ? "delete all zones" : 'delete "' + name + '" zone..?'}..?
        </p>
      </FleetDialog>
    );
  }
}

export default ConfirmDialog;
