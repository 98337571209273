import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import FixedTopBar from "../layouts/topbar/FixedTopBar";
import LeftBar from "../layouts/leftbar/LeftBar";
import FleetMap from "./fleetmap";
import BottomBar from "./bottombar/BottomBar";
import LoadingDiv from "../loading/LoadingDiv";

import {
  ALERTS,
  NOTIFY,
  LOCAL_STORAGE,
  DEFAULTS,
  FORMATS,
} from "../common/consts";
import { API_UserLogin } from "../apihandler/UserLogin";

import "./mappage.css";

import { commonJs } from "../common/common";
import { dateFunction } from "../common/datefunctions";
import { TRACK_ACTIONS } from "../../actions/TrackActions";
import { MAP_ACTIONS } from "../../actions/MapActions";
import { API_FireApp } from "../apihandler/FireApp";

class MapPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    var self = this;
    //Check Login
    API_UserLogin.checkLogin()
      .then((res) => {
        if (!res.Response) {
          commonJs.showNotify(
            self.props,
            NOTIFY.WARNING,
            ALERTS.INTERNAL_SERVER_ERROR
          );
        }
      })
      .catch((err) => {
        commonJs.showNotify(self.props, NOTIFY.WARNING, ALERTS.SESSION_TIMEOUT);
        var userData = commonJs.getItemFromLocal(LOCAL_STORAGE.USER_DATA);
        userData.token = null;
        commonJs.setItemToLocal(LOCAL_STORAGE.USER_DATA, userData);
        window.location = "/";
      });
  }

  onPrintMap = () => {
    this._fleetmap.printMap();
  };

  logout = () => {
    let self = this;
    API_FireApp.revokeJanus()
      .then((res) => {
        self.initiateLogout();
      })
      .catch((err) => {
        self.initiateLogout();
      });
  };

  initiateLogout = () => {
    API_UserLogin.logout()
      .then((res) => {
        window.location = "/";
        //TODO: Clear cache && reset login
        commonJs.setItemToLocal("map", null);
      })
      .catch((err) => {
        window.location = "/";
      });
  };

  updateMapRegion = (lat, lng, zoom) => {
    this._fleetmap.updateRegion(lat, lng, zoom);
  };

  showTrack = (filter, imei) => {
    //Update Imei, Filter
    this.props.setSelectedVehicle({
      selectedVehicle: imei,
    });
    this.props.setTrackFilter({
      fdt: dateFunction.getDate(filter, "from", FORMATS.SQL_DATE),
      tdt: dateFunction.getDate(filter, "to", FORMATS.SQL_DATE),
      stopTime: DEFAULTS.DEFAULT_TRACK_STOPTIME,
      selectedTrackDuration: filter,
    });
    if (!document.querySelector(".mapTabWrapper")) {
      //Fix given when lefttab closed
      this._leftbar.onMenuToggle("leftMapMenu");
      let self = this;
      setTimeout(() => {
        self._leftbar._mapTabs.switchTab(2, "track");
      }, 500);
    } else {
      this._leftbar._mapTabs.switchTab(2, "track");
    }
  };

  trackFitBounds = () => {
    this._fleetmap.trackFitBounds();
  };

  mapFitBounds = () => {
    this._fleetmap.mapFitBounds();
  };

  render() {
    return (
      <div className={"mapPage "}>
        <LoadingDiv />

        <FixedTopBar
          onPrintMap={this.onPrintMap}
          mapFitBounds={this.mapFitBounds}
        />

        <LeftBar
          varient={DEFAULTS.LEFT_BAR_VARIENT}
          updateMapRegion={this.updateMapRegion}
          trackFitBounds={this.trackFitBounds}
          childRef={(ref) => (this._leftbar = ref)}
        />

        <FleetMap
          childRef={(ref) => (this._fleetmap = ref)}
          showTrack={this.showTrack}
        />

        <BottomBar updateMapRegion={this.updateMapRegion} />

        {/* <FleetDialog
          open={settingsOpen}
          onCloseSettings={this.onCloseSettings}
          title={LABELS.SETTINGS}
          maxWidth="md"
          fullWidth={true}
        >
          <SettingsTab />
        </FleetDialog>
        <FleetModal />
        <FleetDialog
          open={locateMarkerOpen}
          onCloseSettings={this.closeLocateMarker}
          title={LABELS.LOCATE_MARKER}
          maxWidth="sm"
          fullWidth={true}
          saveBtnLabel={LABELS.SHOW}
        >
          <LocateMarker />
        </FleetDialog>*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTrackFilter: (values) => {
      dispatch({
        type: TRACK_ACTIONS.SET_TRACK_FILTER,
        values,
      });
    },
    setSelectedVehicle: (values) => {
      dispatch({
        type: MAP_ACTIONS.SELECT_VEHICLE,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(MapPage));
