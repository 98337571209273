import React, { Component } from "react";
import {
  Grid,
  Link,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import TopProgressBar from "../ui/progressbar/TopProgressBar";
import Txt from "../ui/textbox/Txt";
import Btn from "../ui/button/Btn";
import Img from "../ui/image/Img";

import { LOGIN_ACTIONS } from "../../actions/LoginActions";
import { MAP_ACTIONS } from "../../actions/MapActions";
import { API_UserLogin } from "../apihandler/UserLogin";
import { commonJs } from "../common/common";
import {
  LABELS,
  ALERTS,
  NOTIFY,
  LOCAL_STORAGE,
  HELPER_TEXT,
} from "../common/consts";

import logo from "../../images/logo/logo.png";

import "./login.css";

const DefaultDismissBtn = () => {
  return <button className="snackbar-btn">{LABELS.DISMISS}</button>;
};

class Login extends Component {
  constructor(props) {
    super(props);
    let userDataJson = commonJs.getItemFromLocal(LOCAL_STORAGE.USER_DATA);
    this.state = {
      progress: this.props.loading || false,
      showPassword: false,
      rememberMe: userDataJson.rememberMe || false,
      userPassword: userDataJson.rememberMe ? userDataJson.userPassword : "",
      userName: userDataJson.rememberMe ? userDataJson.userName : "",
    };
  }

  componentDidMount() {
    document.getElementById("root").classList.add("login");
  }

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleRememberMe = () => {
    this.setState({
      rememberMe: !this.state.rememberMe,
    });
    let userDataJson = commonJs.getItemFromLocal(LOCAL_STORAGE.USER_DATA);
    userDataJson.rememberMe = !this.state.rememberMe;
    if (userDataJson.rememberMe) {
      userDataJson.userName = document.getElementById("loginUsername").value;
      userDataJson.userPassword = document.getElementById(
        "loginPassword"
      ).value;
    } else {
      userDataJson.userName = "";
      userDataJson.userPassword = "";
    }
    commonJs.setItemToLocal(LOCAL_STORAGE.USER_DATA, userDataJson);
  };

  validateLogin = () => {
    const uname = document.getElementById("loginUsername").value.trim();
    const pwd = document.getElementById("loginPassword").value.trim();
    if (uname === "" && pwd === "") {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        ALERTS.USERNAME_AND_PASSWORD_EMPTY,
        <DefaultDismissBtn />
      );
    } else if (uname === "") {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        ALERTS.USERNAME_EMPTY,
        <DefaultDismissBtn />
      );
    } else if (pwd === "") {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        ALERTS.PASSWORD_EMPTY,
        <DefaultDismissBtn />
      );
    } else {
      var userDataJson = commonJs.getItemFromLocal(LOCAL_STORAGE.USER_DATA);
      if (this.state.rememberMe) {
        userDataJson.userName = uname;
        userDataJson.userPassword = pwd;
        commonJs.setItemToLocal(LOCAL_STORAGE.USER_DATA, userDataJson);
      }
      this.setState({
        progress: true,
      });
      var self = this;
      API_UserLogin.login(uname, pwd)
        .then((res) => {
          if (res.Result === 200) {
            API_UserLogin.setToken(res.Token);
            userDataJson.login = true;
            userDataJson.token = res.Token;
            userDataJson.uid = res.id;
            userDataJson.logo = res.logo;
            userDataJson.role = res.role;
            userDataJson.privileges = res.privileges;
            commonJs.setItemToLocal(LOCAL_STORAGE.USER_DATA, userDataJson);

            commonJs.showNotify(
              this.props,
              NOTIFY.SUCCESS,
              ALERTS.LOGIN_SUCESSSFUL,
              <button className="snackbar-btn">{LABELS.DISMISS}</button>
            );
            self.props.setLoginInfo({
              login: true,
              id: res.Response.id,
              userName: res.Response.username,
              logo: res.Response.logo,
              role: res.Response.role,
              privileges: res.Response.privileges,
            });
            self.props.setMapPosition({
              initialLat: res.Response.initialLat,
              initialLng: res.Response.initialLng,
            });
            // window.location = "/map";
          } else {
            commonJs.showNotify(
              this.props,
              NOTIFY.ERROR,
              ALERTS.LOGIN_UNSUCESSSFUL,
              <button className="snackbar-btn">{LABELS.DISMISS}</button>
            );
            self.setState({
              progress: false,
            });
          }
        })
        .catch((err) => {
          err = err.toString();
          if (err.indexOf("no rows") > -1) {
            commonJs.showNotify(
              self.props,
              NOTIFY.ERROR,
              ALERTS.INVALID_USERNAME,
              <button className="snackbar-btn">{LABELS.DISMISS}</button>
            );
          } else if (err.indexOf("Invalid password") > -1) {
            commonJs.showNotify(
              self.props,
              NOTIFY.ERROR,
              ALERTS.INVALID_PASSWORD,
              <button className="snackbar-btn">{LABELS.DISMISS}</button>
            );
          }
          self.setState({
            progress: false,
          });
        });
    }
  };

  onPasswordKeyPress = (e) => {
    if (e.which === 13) {
      this.validateLogin();
    }
  };

  render() {
    const { progress, showPassword, rememberMe } = this.state;

    const { userName, userPassword } = this.props.loginReducer;

    return (
      <React.Fragment>
        <TopProgressBar
          className={progress ? "showprogress" : "hideprogress"}
        />

        <div className={progress ? "loading" : ""} />

        <Grid item sm={12} id="logintopbar">
          <Img src={logo} width="150" height="150" />
          <Link href="/">Help</Link>
        </Grid>

        <Grid item sm={12} id="loginbody">
          <div id="loginForm">
            <center>
              <Img src={logo} width="100" />
              <p className="loginFormTitle">{LABELS.SIGN_IN}</p>
            </center>
            <Txt
              label={LABELS.USERNAME}
              id="loginUsername"
              helptext={HELPER_TEXT.PLEASE_ENTER_USERNAME}
              classname="width100 mB20"
              defaultValue={userName}
            />
            <Txt
              label={LABELS.PASSWORD}
              id="loginPassword"
              helptext={HELPER_TEXT.PLEASE_ENTER_PASSWORD}
              type={showPassword ? "text" : "password"}
              classname="width100"
              defaultValue={userPassword}
              onKeyPress={this.onPasswordKeyPress}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowPassword}
                  >
                    {showPassword ? (
                      <Visibility className="iconColor" />
                    ) : (
                      <VisibilityOff className="iconColor" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            <ul id="rememberPassword">
              <li>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rememberMe}
                      onChange={this.handleRememberMe}
                      className="rememberMeCheckbox"
                    />
                  }
                  label={LABELS.REMEMBER_ME}
                />
              </li>
              {/* <li>
                <Link href={CONFIG.FORGOT_PASSWORD_URL}>
                  {LABELS.FORGOT_PASSWORD}
                </Link>
              </li> */}
            </ul>
            <Btn
              id="loginFormBtn"
              variant="contained"
              onClick={this.validateLogin}
            >
              {LABELS.LOGIN}
            </Btn>
          </div>
        </Grid>

        <Grid item sm={12} id="loginfooter">
          <p>{LABELS.SIGN_IN_NOTE}</p>
          <ul>
            <li>
              <Link href="\privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginInfo: (values) => {
      commonJs.setItemToLocal(LOCAL_STORAGE.USER_SETTINGS, values);
      dispatch({
        type: LOGIN_ACTIONS.SET_LOGIN_INFO,
        values,
      });
    },
    setMapPosition: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_MAP_POSITION,
        values,
      });
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Login))
);
