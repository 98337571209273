import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { connect } from "react-redux";

import AutoComplete from "../../../../../../ui/autocomplete/AutoComplete";
import { DEFAULTS } from "../../../../../../common/consts";

class EditVehicle extends Component {
  constructor(props) {
    super(props);
    let selectedLoadStatus = [];
    const { imei } = this.props;
    const { tmpVehicles } = this.props.mapReducer;
    const vehicle = tmpVehicles.filter((arr) => {
      return arr.imei === imei;
    });
    if (vehicle.length > 0) {
      selectedLoadStatus = {
        id: vehicle[0].loadstatus,
        name: vehicle[0].loadstatus,
      };
    }
    this.state = {
      iconPopup: false,
      icon: DEFAULTS.DEFAULT_VEHICLE_ICON,
      loadStatusList: [
        {
          id: "free",
          name: "free",
        },
        {
          id: "busy",
          name: "busy",
        },
      ],
      selectedLoadStatus: selectedLoadStatus,
    };
    this.anchorEl = true;
  }

  handleClick = () => {};

  handleClose = () => {};

  handleChange = () => {};

  toggleIconSelector = () => {
    const { iconPopup } = this.state;
    this.setState({
      iconPopup: !iconPopup,
    });
  };

  onIconChange = (img) => {};

  onChangeStatus = (val) => {
    this.setState({
      selectedLoadStatus: val,
    });
  };

  render() {
    const { imei, mapReducer } = this.props;

    const { loadStatusList, selectedLoadStatus } = this.state;

    const { tmpVehicles } = mapReducer;

    var vname = "";
    // var vicon = "";
    const vehicle = tmpVehicles.filter((arr) => {
      return arr.imei === imei;
    });
    if (vehicle.length > 0) {
      vname = vehicle[0].name;
      // vicon = vehicle[0].icon;
    }

    return (
      <React.Fragment>
        <div className="row">
          <div className="col">
            <TextField
              id="ev-VehicleName"
              label="Vehicle Name"
              classes={{ root: "txtfield" }}
              autoFocus={true}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              value={vname}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              id="ev-VehicleImei"
              label="IMEI"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              value={imei}
              disabled
            />
          </div>
          <div className="col" style={{ marginTop: "-14px" }}>
            <AutoComplete
              label={"Select Vehicle Status"}
              list={loadStatusList}
              id="vehicleLoadStatus"
              style={{ marginBottom: 15, marginTop: 15 }}
              onChange={this.onChangeStatus}
              defaultValue={selectedLoadStatus}
              multiple={false}
              size="small"
            />
          </div>
          {/* <div className="col">
            <TextField
              id="ev-VehicleType"
              label="Vehicle Type"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div> */}
        </div>
        {/* <div className="row">
          <div className="col">
            <TextField
              id="ev-VehiclePlateNo"
              label="Plate No."
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col">
            <TextField
              id="ev-VehicleSimNo"
              label="SIM Card No."
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col">
            <TextField
              id="ev-VehicleDriverName"
              label="Driver Name"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
        </div> */}
        {/* <div className="row" style={{ justifyContent: "flex-start" }}>
          <div className="col" style={{ marginRight: "2%" }}>
            <TextField
              id="ev-VehicleDriverNo"
              label="Driver Mobile No."
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col" style={{ marginTop: "-14px" }}>
            <AutoComplete
              label={"Select Status"}
              list={loadStatusList}
              id="vehicleLoadStatus"
              style={{ marginBottom: 15, marginTop: 15 }}
              onChange={this.onChangeStatus}
              defaultValue={selectedLoadStatus}
              multiple={false}
              size="small"
            />
          </div>
        </div> */}
        {/* <div className="row">
          <div className="col">
            <TextField
              id="ev-VehicleTargetKms"
              label="Target Kms"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={e => e.stopPropagation()}
            />
          </div>
          <div className="col">
            <FleetDropDown
              label="Group"
              list={[
                { id: " ", text: "None" },
                { id: "1", text: "AVG" },
                { id: "2", text: "Temp" }
              ]}
              selectedValue="2"
              id="odometerDropdown"
            />
          </div>
          <div className="col" style={{ position: "relative" }}>
            <label className="label">Icon:</label>
            <Button onClick={this.toggleIconSelector}>
              <img src={CONFIG.BASE_URL + vicon} alt="" />
            </Button>
            {iconPopup && <IconsList onIconChange={this.onIconChange} />}
          </div>
        </div> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditVehicle);
