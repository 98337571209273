import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import ReportTopbar from "./layouts/ReportTopbar";
import ReportLeftBar from "./layouts/ReportLeftBar";
import ReportFooter from "./layouts/ReportFooter";

import "./reports.css";

import ReportPopupDialog from "./ReportPopupDialog";
import KmGraph from "./KmGraph";

import { REPORT_DESCRIPTION } from "../common/consts";
import { API_Map } from "../apihandler/Map";
import { API_UserLogin } from "../apihandler/UserLogin";
import { COMMON_ACTIONS } from "../../actions/CommonActions";
import { REPORT_ACTIONS } from "../../actions/ReportActions";
import {
  CircularProgress,
  Card,
  CardContent,
  Link,
  Breadcrumbs,
  Backdrop,
  Divider,
} from "@material-ui/core";

import { DirectionsBus as AllVehiclesIcon } from "@material-ui/icons";

class UserDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicles: [],
      reportType: "",
    };
  }
  onMenuClick = (menu) => {
    if (menu === "home") {
      window.open("/", "_self");
    }
  };

  showDetails = (type) => {
    this.props.toggleReportPopup({
      reportPopupDialog: true,
    });
    this.setState({
      reportType: type,
    });
  };

  componentDidMount() {
    document.getElementById("root").classList.add("scroll");
    this.getDashboardData();
  }

  getDashboardData = () => {
    const args = {};
    let self = this;
    args.pageNo = 0;
    args.pageLength = 100000;
    args.orderBy = "ASC";
    args.sortColumn = "";
    API_UserLogin.setToken(localStorage.token);
    API_Map.getAllVehicles(args)
      .then((res) => {
        self.props.setVehicles({
          vehicles: res,
        });
        self.props.setLoading({
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { vehiclesList } = this.props.commonReducer;

    const { loading, reportPopupDialog } = this.props.reportReducer;

    let totalCount = vehiclesList.length;
    let runningCount = 0;
    let idleCount = 0;
    let stoppedCount = 0;
    let nosignalCount = 0;
    let availableCount = 0;
    let busyCount = 0;
    for (var i = 0; i < vehiclesList.length; i++) {
      switch (vehiclesList[i].status) {
        case 0:
          nosignalCount++;
          break;
        case 1:
          stoppedCount++;
          break;
        case 2:
          idleCount++;
          break;
        case 3:
          runningCount++;
          break;
        default:
          break;
      }

      switch (vehiclesList[i].loadstatus) {
        case "busy":
          busyCount++;
          break;
        case "free":
          availableCount++;
          break;
        default:
          break;
      }
    }

    const { reportType } = this.state;

    let title = "";

    switch (reportType) {
      case "all":
        title = "All Vehicles (" + vehiclesList.length + ")";
        break;
      case "running":
        title = "Running Vehicles (" + runningCount + ")";
        break;
      case "idle":
        title = "Idle Vehicles (" + idleCount + ")";
        break;
      case "nosignal":
        title = "No Signal Vehicles (" + nosignalCount + ")";
        break;
      case "stopped":
        title = "Stopped Vehicles (" + stoppedCount + ")";
        break;
      case "free":
        title = "Available Vehicles (" + availableCount + ")";
        break;
      case "busy":
        title = "Engaged Vehicles (" + busyCount + ")";
        break;
      default:
        break;
    }

    return (
      <div className="reports">
        <ReportTopbar />
        <ReportLeftBar />
        <div className="reportPageWrapper">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Link
              color="inherit"
              href="/"
              onClick={() => this.onMenuClick("home")}
            >
              Home
            </Link>
            <p>Dashboard</p>
          </Breadcrumbs>
          <p className="reportDescription">{REPORT_DESCRIPTION.DASHBOARD}</p>

          <div className="cards" style={{ marginTop: 20 }}>
            <div className="card">
              <Card className="reports-card">
                <CardContent>
                  <div className="reportcard-header">
                    <div className="reportcard-icon total">
                      <AllVehiclesIcon />
                    </div>
                    <div className="reportcard-details">
                      <h4 className="reportcard-title">Total</h4>
                      <p className="reportcard-desc">{totalCount}</p>
                    </div>
                  </div>
                  <Divider />
                  <p
                    className="viewmore-link"
                    onClick={() => this.showDetails("all")}
                  >
                    View Details
                  </p>
                </CardContent>
              </Card>
            </div>
            <div className="card">
              <Card className="reports-card">
                <CardContent>
                  <div className="reportcard-header">
                    <div className="reportcard-icon running">
                      <AllVehiclesIcon />
                    </div>
                    <div className="reportcard-details">
                      <h4 className="reportcard-title">Running</h4>
                      <p className="reportcard-desc">{runningCount}</p>
                    </div>
                  </div>
                  <Divider />
                  <p
                    className="viewmore-link"
                    onClick={() => this.showDetails("running")}
                  >
                    View Details
                  </p>
                </CardContent>
              </Card>
            </div>
            <div className="card">
              <Card className="reports-card">
                <CardContent>
                  <div className="reportcard-header">
                    <div className="reportcard-icon idle">
                      <AllVehiclesIcon />
                    </div>
                    <div className="reportcard-details">
                      <h4 className="reportcard-title">Idle</h4>
                      <p className="reportcard-desc">{idleCount}</p>
                    </div>
                  </div>
                  <Divider />
                  <p
                    className="viewmore-link"
                    onClick={() => this.showDetails("idle")}
                  >
                    View Details
                  </p>
                </CardContent>
              </Card>
            </div>
            <div className="card">
              <Card className="reports-card">
                <CardContent>
                  <div className="reportcard-header">
                    <div className="reportcard-icon stopped">
                      <AllVehiclesIcon />
                    </div>
                    <div className="reportcard-details">
                      <h4 className="reportcard-title">Stopped</h4>
                      <p className="reportcard-desc">{stoppedCount}</p>
                    </div>
                  </div>
                  <Divider />
                  <p
                    className="viewmore-link"
                    onClick={() => this.showDetails("stopped")}
                  >
                    View Details
                  </p>
                </CardContent>
              </Card>
            </div>
            <div className="card">
              <Card className="reports-card">
                <CardContent>
                  <div className="reportcard-header">
                    <div className="reportcard-icon nosignal">
                      <AllVehiclesIcon />
                    </div>
                    <div className="reportcard-details">
                      <h4 className="reportcard-title">No Signal</h4>
                      <p className="reportcard-desc">{nosignalCount}</p>
                    </div>
                  </div>
                  <Divider />
                  <p
                    className="viewmore-link"
                    onClick={() => this.showDetails("nosignal")}
                  >
                    View Details
                  </p>
                </CardContent>
              </Card>
            </div>
          </div>

          <div className="cards" style={{ justifyContent: "flex-start" }}>
            <div className="card" style={{ marginRight: "2.2%" }}>
              <Card className="reports-card">
                <CardContent>
                  <div className="reportcard-header">
                    <div className="reportcard-icon available">
                      <AllVehiclesIcon />
                    </div>
                    <div className="reportcard-details">
                      <h4 className="reportcard-title">Available Vehicles</h4>
                      <p className="reportcard-desc">{availableCount}</p>
                    </div>
                  </div>
                  <Divider />
                  <p
                    className="viewmore-link"
                    onClick={() => this.showDetails("available")}
                  >
                    View Details
                  </p>
                </CardContent>
              </Card>
            </div>
            <div className="card">
              <Card className="reports-card">
                <CardContent>
                  <div className="reportcard-header">
                    <div className="reportcard-icon busy">
                      <AllVehiclesIcon />
                    </div>
                    <div className="reportcard-details">
                      <h4 className="reportcard-title">Engaged Vehicles</h4>
                      <p className="reportcard-desc">{busyCount}</p>
                    </div>
                  </div>
                  <Divider />
                  <p
                    className="viewmore-link"
                    onClick={() => this.showDetails("busy")}
                  >
                    View Details
                  </p>
                </CardContent>
              </Card>
            </div>
          </div>

          <div className="charts">
            <KmGraph />
          </div>
        </div>

        <ReportFooter />

        <Backdrop className={"backdrop"} open={loading}>
          <CircularProgress className="whiteLoader" />
        </Backdrop>

        {reportPopupDialog && (
          <ReportPopupDialog
            maxWidth={"md"}
            title={title}
            reportType={reportType}
            vehiclesList={vehiclesList}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commonReducer: state.commonReducer,
    reportReducer: state.reportReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setVehicles: (values) => {
      dispatch({
        type: COMMON_ACTIONS.SET_VEHICLES,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: REPORT_ACTIONS.SET_LOADING,
        values,
      });
    },
    toggleReportPopup: (values) => {
      dispatch({
        type: REPORT_ACTIONS.TOGGLE_REPORT_POPUP,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(UserDashboard));
