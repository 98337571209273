import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import FleetDialog from "../ui/dialogbox/FleetDialog";
import Btn from "../ui/button/Btn";
import { commonJs } from "../common/common";
import { NOTIFY, LOCAL_STORAGE } from "../common/consts";
import { PAGE_ACTIONS } from "../../actions/PageActions";
import { API_FireApp } from "../apihandler/FireApp";
import VideoCall from "./index";
import Janus from "./janus";
import { FIREAPP_ACTIONS } from "../../actions/FireAppActions";

import audio from "../../audio/ringing.wav";

class DialogVideoCall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      selectedUser: "",
      remoteUserName: "--",
      gatewayDownCount: 0,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    this.startJanus();
  }

  startJanus = () => {
    let self = this;
    API_FireApp.getJanusToken()
      .then((res) => {
        if (res.Result === 200) {
          localStorage.WebRtcToken = res.Response.Token;
          self.initJanus(
            res.Response.Token,
            res.Response.GatewayUrl,
            res.Response.StunUri
          );
        }
      })
      .catch((err) => {
        console.log("getJanusToken err:", err);
      });
  };

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
    if (this.videoCall) {
      this.videoCall.hangup();
    }
    // if (this._janus) {
    //   this._janus.destroy();
    // }
  }

  toggleDialog = (val) => {
    if (
      document.getElementById("videoRoom2").classList.contains("online") ===
      false
    ) {
      //Manual
      this.onMissedCall("missedcall", true);
    }
    if (val === "close") {
      this.props.toggleDialog({
        videoCallDialog: "",
      });
      this.onHangup();
    } else if (val === "cancel") {
      this.props.toggleDialog({
        videoCallDialog: "",
      });
      this.onHangup();
    }
    commonJs.showNotify(this.props, NOTIFY.WARNING, "Call Disconnected..!");
  };

  onChangeVehicle = (user) => {
    this.setState({
      selectedUser: user,
    });
  };

  connectCall = (type) => {
    if (type === "xs") {
      //Accept
      let callUser = commonJs.getItemFromLocal(LOCAL_STORAGE.CALL_USER);
      this.props.toggleDialog({
        videoCallDialog: "md",
        remoteUserName: callUser,
      });
      this.callAccept(this._jsep);
    } else {
      //Reject
      this.props.toggleDialog({
        videoCallDialog: "",
      });
    }
  };

  onHangup = () => {
    // Turn Off Local Camera
    commonJs.turnOffLocalCamera();

    document.getElementById("videoCallPopup").style.display = "none";

    this.props.toggleDialog({
      videoCallDialog: "",
      remoteUserName: "",
    });

    this.props.toggleSlowLink({
      slowLink: false,
    });

    try {
      this.videoCall.send({ message: { request: "hangup" } });
    } catch (error) {}
  };

  initJanus = (token, gatewayUrl, stunUri) => {
    var this_ = this;
    var userData = commonJs.getItemFromLocal(LOCAL_STORAGE.USER_SETTINGS);
    Janus.init({
      debug: true,
      callback: () => {
        // Create session
        this_._janus = new Janus({
          server: gatewayUrl,
          token: token,
          iceServers: [{ urls: stunUri }],
          success: () => {
            this_._janus.attach({
              plugin: "janus.plugin.videocall",
              success: (pluginHandle) => {
                this_.videoCall = pluginHandle;
                var body = {
                  request: "register",
                  username: userData.userName,
                };
                this_.videoCall.send({ message: body });
              },
              error: function(error) {
                console.log(error);
              },
              destroyed: function() {
                window.location.reload();
              },
              onmessage: function(msg, jsep) {
                var result = msg["result"];
                Janus.log("Got a message -> ", msg);
                if (result) {
                  if (result["list"]) {
                  } else if (result["event"] === "registered") {
                    if (document.getElementById("myProfileBtn")) {
                      document
                        .getElementById("myProfileBtn")
                        .classList.add("online");
                    }
                  } else if (result["event"] === "incomingcall") {
                    Janus.log("incomingcall: ", result.username);
                    commonJs.setItemToLocal(
                      LOCAL_STORAGE.CALL_USER,
                      result.username
                    );
                    this_.props.toggleDialog({
                      videoCallDialog: "xs",
                    });
                    this_.jsep = jsep;
                    this_.setState({
                      remoteUserName: result.username,
                    });
                  } else if (result["event"] === "accepted") {
                    if (jsep) {
                      this_.videoCall.handleRemoteJsep({ jsep: jsep });
                    }
                    document
                      .getElementById("videoRoom2")
                      .classList.add("online");
                  } else if (result["event"] === "hangup") {
                    if (result["reason"] === "User busy") {
                      commonJs.showNotify(
                        this_.props,
                        NOTIFY.WARNING,
                        "User is busy with another call..!"
                      );
                      // Set Missed Call Alert
                      this_.onMissedCall("busy");
                    } else if (result["reason"] === "We did the hangup") {
                      commonJs.showNotify(
                        this_.props,
                        NOTIFY.WARNING,
                        "Call Disconnected..!"
                      );
                      // this_.onMissedCall("rejected");
                    }
                  } else if (result["event"] === "slow_link") {
                    const { slowLink } = this_.props.fireReducer;
                    if (!slowLink) {
                      this_.props.toggleSlowLink({
                        slowLink: true,
                      });
                    }
                  }
                } else if (msg["error"]) {
                  let errorCode = msg["error_code"];
                  /* Error codes */
                  // JANUS_VIDEOCALL_ERROR_UNKNOWN_ERROR			499
                  // JANUS_VIDEOCALL_ERROR_NO_MESSAGE			    470
                  // JANUS_VIDEOCALL_ERROR_INVALID_JSON			    471
                  // JANUS_VIDEOCALL_ERROR_INVALID_REQUEST		    472
                  // JANUS_VIDEOCALL_ERROR_REGISTER_FIRST		    473
                  // JANUS_VIDEOCALL_ERROR_INVALID_ELEMENT		    474
                  // JANUS_VIDEOCALL_ERROR_MISSING_ELEMENT		    475
                  // JANUS_VIDEOCALL_ERROR_USERNAME_TAKEN		    476
                  // JANUS_VIDEOCALL_ERROR_ALREADY_REGISTERED	    477
                  // JANUS_VIDEOCALL_ERROR_NO_SUCH_USERNAME		    478
                  // JANUS_VIDEOCALL_ERROR_ALREADY_IN_CALL		    480
                  // JANUS_VIDEOCALL_ERROR_NO_CALL				    481
                  // JANUS_VIDEOCALL_ERROR_MISSING_SDP			    482
                  // JANUS_VIDEOCALL_ERROR_INVALID_SDP			    483
                  if (errorCode === 476 || errorCode === 477) {
                    document
                      .getElementById("myProfileBtn")
                      .classList.add("offline");
                    document
                      .getElementById("myProfileBtn")
                      .classList.remove("online");
                  } else if (msg["reason"] === "Remote WebRTC hangup") {
                    return;
                  } else if (
                    msg["reason"] === "We did the hangup" ||
                    msg["reason"] === "Explicit hangup"
                  ) {
                    commonJs.showNotify(
                      this_.props,
                      NOTIFY.WARNING,
                      "Call Disconnected..!"
                    );
                    return;
                  } else if (errorCode === 478) {
                    commonJs.showNotify(
                      this_.props,
                      NOTIFY.WARNING,
                      "Sorry, User is not online..!"
                    );
                    // Set Missed Call Alert
                    this_.onMissedCall("offline");
                  } else {
                    commonJs.showNotify(
                      this_.props,
                      NOTIFY.WARNING,
                      "Call Error: " + msg["error"]
                    );
                  }
                }
              },
              onlocalstream: function(stream) {
                Janus.log(
                  "Got a local stream -> Is Muted: ",
                  this_.videoCall.isAudioMuted(stream)
                );

                if (document.getElementById("videoRoom1")) {
                  document.getElementById("videoRoom1").style.display = "block";
                  Janus.attachMediaStream(
                    document.getElementById("video1"),
                    stream
                  );
                }

                if (
                  this_.videoCall.webrtcStuff.pc.iceConnectionState !==
                    "completed" &&
                  this_.videoCall.webrtcStuff.pc.iceConnectionState !==
                    "connected"
                ) {
                  console.log("No remote video yet");
                }

                var videoTracks = stream.getVideoTracks();
                if (
                  videoTracks === null ||
                  videoTracks === undefined ||
                  videoTracks.length === 0
                ) {
                  // No webcam
                  console.log("No webcam");
                } else {
                  console.log("Showing the video");
                }
              },
              onremotestream: function(stream) {
                Janus.log("Got a remote stream: ", stream);

                if (document.getElementById("video2")) {
                  Janus.attachMediaStream(
                    document.getElementById("video2"),
                    stream
                  );
                }

                var videoTracks = stream.getVideoTracks();
                if (
                  videoTracks === null ||
                  videoTracks === undefined ||
                  videoTracks.length === 0
                ) {
                  // No remote video
                  console.log("No remote video");
                } else {
                  console.log("Show remote video: ");
                }
              },
              ondataopen: function(data) {
                Janus.log("The DataChannel is available!");
              },
              ondata: function(data) {
                Janus.debug("We got data from the DataChannel! " + data);
              },
              oncleanup: function() {
                Janus.log(" ::: Got a cleanup notification :::");
                // commonJs.showNotify(
                //   this_.props,
                //   NOTIFY.WARNING,
                //   "Call Disconnected..!"
                // );
                setTimeout(() => {
                  this_.props.toggleDialog({
                    videoCallDialog: "",
                  });
                }, 1500);
              },
            });
          },
          error: (error) => {
            console.log("Error: ", error);
            let errString = error.toString();
            if (errString.indexOf("Is the gateway down") > -1) {
              this_.resetJanus();
            }
            document.getElementById("myProfileBtn").classList.add("offline");
            document.getElementById("myProfileBtn").classList.remove("online");
          },
        });
      },
    });
  };

  onMissedCall = (reason, rejected = false) => {
    // Rejected / Offline / Busy / Missed Call
    const { remoteUserName } = this.state;
    const { userName } = this.props.loginReducer;
    let args = {
      user: remoteUserName,
      reason: reason,
      message: "Got missed call from user: " + userName,
      messageType: "missed_call",
    };
    let args2 = {
      user: userName,
      reason: "notanswered",
      message: "Call not answered by user: " + remoteUserName,
      messageType: "noanswered_call",
    };
    if (rejected) {
      const { videoCallDialog } = this.props.pageReducer;
      if (videoCallDialog === "xs") {
        args = {
          user: userName,
          reason: "rejected",
          message: "Got missed call from user: " + remoteUserName,
          messageType: "missed_call",
        };
        args2 = {
          user: remoteUserName,
          reason: "notanswered",
          message: "Call not answered by user: " + userName,
          messageType: "missed_call",
        };
      }
    }
    if (args.user !== "" && args.user !== "--") {
      API_FireApp.missedCall(args)
        .then((res) => {})
        .catch((err) => {});
      API_FireApp.missedCall(args2)
        .then((res) => {})
        .catch((err) => {});
    }
  };

  onIncomingCall = (user) => {
    const { userName } = this.props.loginReducer;
    let args = {
      user: user,
      reason: "",
      message: "Incoming call from user: " + userName,
      messageType: "incoming_call",
    };
    if (user !== "") {
      API_FireApp.missedCall(args)
        .then((res) => {})
        .catch((err) => {});
    }
  };

  resetJanus = () => {
    console.log("Janus: resetJanus");
    if (this._janus) {
      try {
        this._janus.destroy();
      } catch (error) {}
    }
    this.startJanus();
  };

  callRequest = () => {
    let callUser = commonJs.getItemFromLocal(LOCAL_STORAGE.CALL_USER);
    let this_ = this;
    if (!this.videoCall) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Sorry you're not online, please wait..."
      );
      this.onHangup();
      return;
    }
    this.videoCall.createOffer({
      // No media provided: by default, it's sendrecv for audio and video
      media: {
        video: true,
        audio: { echoCancellation: true, noiseSuppression: true },
      }, // Let's negotiate data channels as well
      // If you want to test simulcasting (Chrome and Firefox only), then
      // pass a ?simulcast=true when opening this demo page: it will turn
      // the following 'simulcast' property to pass to janus.js to true
      simulcast: false,
      // simulcast2: false,
      success: function(jsep) {
        Janus.log("Got SDP!");
        Janus.log(jsep);
        var body = {
          request: "call",
          username: callUser,
        };
        Janus.log("Sending message: ", body);
        this_.videoCall.send({ message: body, jsep: jsep });
        this_.setState({
          remoteUserName: callUser,
        });
        setTimeout(() => {
          this_.onIncomingCall(callUser);
        }, 2 * 1000);
      },
      error: function(error) {
        Janus.log("WebRTC error:", error);
      },
    });
  };

  callAccept = (jsep) => {
    let this_ = this;
    this.videoCall.createAnswer({
      jsep: this_.jsep,
      media: {
        video: true,
        audio: { echoCancellation: true, noiseSuppression: true },
      }, // Let's negotiate data channels as well
      success: function(jsepOp) {
        Janus.log("Got SDP!");
        Janus.log(jsepOp);
        var body = { request: "accept" };
        this_.videoCall.send({ message: body, jsep: jsepOp });
        document.getElementById("videoRoom2").classList.add("online");
      },
      error: function(error) {
        Janus.log("WebRTC error:", error);
      },
    });
  };

  render() {
    const { remoteUserName } = this.state;

    const { videoCallDialog } = this.props.pageReducer;

    let title = "Connecting...";
    let saveBtn = true;
    let cancelBtn = true;
    let saveBtnTitle = "Accept";
    let cancelBtnTitle = "Reject";

    if (videoCallDialog === "xs") {
      title = '"' + remoteUserName + '" is calling you...';
      saveBtn = true;
      saveBtnTitle = "Answer";
    } else if (videoCallDialog === "md") {
      title = "Call";
      if (document.getElementById("video2")) {
        title = 'On call with "' + remoteUserName + '"';
      }
      saveBtn = false;
      saveBtnTitle = "";
      cancelBtnTitle = "Hangup";
    }

    // let ringBack = false; //Play Ringback
    // if (document.getElementById("videoRoom2")) {
    //   ringBack = document
    //     .getElementById("videoRoom2")
    //     .classList.contains("online");
    // }

    return (
      videoCallDialog !== "" && (
        <FleetDialog
          id="videoCallPopup"
          open={true}
          onCloseSettings={() => this.toggleDialog("close")}
          title={title}
          maxWidth={videoCallDialog}
          fullWidth={false}
          saveButton={false}
          cancelButton={false}
          cancelAction={() => this.toggleDialog("cancel")}
          saveAction={() => this.connectCall(videoCallDialog)}
          disableBackdropClick={true}
          disableBackdrop={true}
          saveBtnLabel={saveBtnTitle}
          cancelBtnLabel={cancelBtnTitle}
          classNameBody="zonePopupBody"
          classNameWrapper="zonePopupWrapper"
          draggable
        >
          <div className="grid">
            {videoCallDialog === "xs" && (
              <audio controls loop autoPlay style={{ display: "none" }}>
                <source src={audio} />
              </audio>
            )}
            <VideoCall
              callRequest={this.callRequest}
              videoCallDialog={videoCallDialog}
              remoteUserName={remoteUserName}
            />
            <div className="callBtns">
              {saveBtn && (
                <Btn
                  className="answer"
                  onClick={() => this.connectCall(videoCallDialog)}
                >
                  {saveBtnTitle}
                </Btn>
              )}
              {cancelBtn && (
                <Btn
                  className="hangup"
                  onClick={() => this.toggleDialog("close")}
                >
                  {cancelBtnTitle}
                </Btn>
              )}
            </div>
          </div>
        </FleetDialog>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageReducer: state.pageReducer,
    fireReducer: state.fireReducer,
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDialog: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_VIDEO_CALL_DIALOG,
        values,
      });
    },
    setOnlineUsers: (values) => {
      dispatch({
        type: FIREAPP_ACTIONS.SET_ONLINE_USERS,
        values,
      });
    },
    toggleSlowLink: (values) => {
      dispatch({
        type: FIREAPP_ACTIONS.TOGGLE_SLOW_LINK,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DialogVideoCall));
