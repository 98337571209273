import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

//Leaflet
import { Tooltip, Marker } from "react-leaflet";

import L from "leaflet";

import { DriftMarker } from "leaflet-drift-marker";

import MapPopup from "./MapPopup";

import { API_Map } from "../../apihandler/Map";

import { MAP_ACTIONS } from "../../../actions/MapActions";

import { UNITS } from "../../common/consts";

class FleetMarker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  getMapMarker = ({ status, angle }) => {
    let iconUrl = "https://fleetlogix.vaschan.com/img/markers/arrow_red.png";
    let customMarker = null;

    if (status === 0 || status === 1) {
      iconUrl = "https://fleetlogix.vaschan.com/img/markers/arrow_red.png";
    } else if (status === 2) {
      iconUrl = "https://fleetlogix.vaschan.com/img/markers/arrow_yellow.png";
    } else if (status === 3) {
      iconUrl = "https://fleetlogix.vaschan.com/img/markers/arrow_green.png";
    }

    const html =
      "<div class='movingMarker' style='transform: rotate(" +
      angle.toString() +
      "deg);'><img src='" +
      iconUrl +
      "' alt=''/></div>";

    customMarker = L.divIcon({
      html: html,
      iconSize: new L.Point(16, 16),
      iconAnchor: null,
      popupAnchor: [0, -3],
      className: "mapMarker",
    });

    return customMarker;
  };

  mapClick = (e) => {};

  markerClick = (imei) => {
    this.props.setSelectedVehicle({
      selectedVehicle: imei,
    });
    var self = this;
    API_Map.getVehicleData({ imei: imei }).then((res) => {
      if (res.Result === 200) {
        self.setState({
          data: res.Response,
        });
      }
    });
  };

  shouldComponentUpdate(props, state) {
    const { imei } = this.props;
    const { vehiclesList } = this.props.commonReducer;
    const { markerAnimation } = this.props.mapReducer;
    const vehiclesListOld = props.commonReducer.vehiclesList;
    let rslt = false;

    let vehicleNew = vehiclesList.filter((v) => {
      return v.imei === imei;
    });
    let vehicleOld = vehiclesListOld.filter((v) => {
      return v.imei === imei;
    });

    if (state.data.time) {
      if (this.state.data.time) {
        rslt = state.data.time.lastupdate !== this.state.data.time.lastupdate;
      } else {
        rslt = true;
      }
    }

    if (!rslt && vehicleNew.length > 0 && vehicleOld.length > 0) {
      rslt =
        vehicleOld[0].latitude !== vehicleNew[0].latitude ||
        vehicleOld[0].longitude !== vehicleNew[0].longitude;
    }

    if (!rslt) {
      rslt = markerAnimation !== props.mapReducer.markerAnimation;
    }

    return rslt;
  }

  render() {
    const { imei, showTrack } = this.props;
    const { vehiclesList } = this.props.commonReducer;
    const { markerAnimation } = this.props.mapReducer;
    const { data } = this.state;

    let vehicle = vehiclesList.filter((v) => {
      return v.imei === imei;
    });
    if (vehicle.length > 0) {
      vehicle = vehicle[0];
    } else {
      return null;
    }

    const pos = [vehicle.latitude, vehicle.longitude];
    const latLng = { lat: vehicle.latitude, lng: vehicle.longitude };

    return markerAnimation ? (
      <DriftMarker
        // if position changes, marker will drift its way to new position
        position={latLng}
        // time in ms that marker will take to reach its destination
        duration={30 * 1000}
        icon={this.getMapMarker(vehicle)}
      >
        {/* <MapPopup vehicle={vehicle} data={data} showTrack={showTrack} /> */}
        {/* <Tooltip>Hi here is a tooltip</Tooltip> */}
      </DriftMarker>
    ) : (
      <Marker
        position={pos}
        icon={this.getMapMarker(vehicle)}
        key={"mapMarker-" + vehicle.imei}
        ref={vehicle.imei}
        onclick={() => this.markerClick(vehicle.imei)}
        className="mapMarker"
      >
        <MapPopup vehicle={vehicle} data={data} showTrack={showTrack} />
        <Tooltip direction="right" offset={[10, -2]} permanent>
          <span>
            {vehicle.name +
              " (" +
              vehicle.speed.toFixed(1) +
              " " +
              UNITS.SPEED +
              ")"}
          </span>
        </Tooltip>
      </Marker>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commonReducer: state.commonReducer,
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedVehicle: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_SELECTED_VEHICLE,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(FleetMarker));
