export const CONFIG = {
  SITE_NAME: "KSFES",
  SITE_FULLNAME: "Karnataka State Fire and Emergency Services",
  SITE_FOOTER:
    "Karnataka State Fire & Emergency Service App by Government of Karnataka. All rights reserved.",
  VERSION: "1.3.2",
  FIRE_APP_GOOGLE_KEY: "AIzaSyCJzVBlgU9Mf8b-BMFIwW-zSYUNz_uiH6Q", // vaschantelecom@gmail.com -> Fire App
  BASE_URL: "https://fleetlogix.vaschan.com",
  SITE_URL: "https://ksfes.vaschan.com",
  REPORT_URL: "/reports",
  VIDEO_ROOM_URL: "/vc",
  WS_URL: "wss://fleetlogix.vaschan.com/api/v2/ws",
  PLAYSTORE_ID:
    "https://play.google.com/store/apps/details?id=gov.karnataka.ksfesapp",
  APPSTORE_ID: "",
  FORGOT_PASSWORD_URL: "/forgotpassword",
  MAP_PRINT_SIZE: "A4Landscape",
  MAP_BOX_TILE_URL_STREETS:
    "https://api.tiles.mapbox.com/v4/mapbox.streets/{z}/{x}/{y}.png?access_token=pk.eyJ1Ijoibml0aGluZG4iLCJhIjoiY2prYXBpaWgyMHB3NzNxcGQ4ZDQzaTEydCJ9.nL5PzNydbdVmrPUIPnGoCA",
  MAP_BOX_TILE_URL_SATELLITE:
    "https://api.tiles.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1Ijoibml0aGluZG4iLCJhIjoiY2prYXBpaWgyMHB3NzNxcGQ4ZDQzaTEydCJ9.nL5PzNydbdVmrPUIPnGoCA",
  OSM_TILE_URL: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  GOOGLE_STREETS_MAP_URL:
    "https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_STREETS_MAP_WITH_TRAFFIC_URL:
    "https://mt0.google.com/vt/lyrs=m,traffic&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_SATELLITE_MAP_URL:
    "https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_SATELLITE_MAP_WITH_TRAFFIC_URL:
    "https://mt0.google.com/vt/lyrs=s,traffic&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_TERRAIN_MAP_URL:
    "https://mt0.google.com/vt/lyrs=t&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_TERRAIN_MAP_WITH_TRAFFIC_URL:
    "https://mt0.google.com/vt/lyrs=t,traffic&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_HYBRID_MAP_URL:
    "https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_HYBRID_MAP_WITH_TRAFFIC_URL:
    "https://mt0.google.com/vt/lyrs=y,traffic&hl=en&x={x}&y={y}&z={z}&s=Ga",
  BUGSNAG_KEY: "999dd760ec4344471bd34f71b104d5b8",
  DOWNLOAD_APP: "DOWNLOAD THE APP",
};

// TODO: Load Server Settings

export const DEFAULTS = {
  DEFAULT_ROOM_ID: 76445809865979,
  MAX_PARTICIPANTS: 10,
  LEFT_BAR_VARIENT: "persistent",
  GOOGLE_MAP: true,
  TRACK_BOTTOM_BAR_HEIGHT: 150,
  TRACK_MESSAGES_HEIGHT: 192,
  TRACK_LINE_THICKNESS: 2,
  TRACK_LINE_COLOR: "#ff0000c7",
  DEFAULT_VEHICLE_ICON: "/img/markers/objects/31.png",
  DEFAULT_PAGE_LENGTH: 100000,
  DEFAULT_LAT: 19.787380181986222,
  DEFAULT_LNG: 73.19091796875001,
  SHOW_LABELS_ZOOM: 8,
  DEFAULT_ZOOM: 5,
  DEFAULT_MAP: 1,
  DEFAULT_VEHICLE_STATUS: 0,
  DEFAULT_TRACK_STOPTIME: 10,
  DEFAULT_SENSOR_TYPES: [
    {
      id: "acc",
      text: "Ignition (ACC)",
    },
    {
      id: "di",
      text: "Digital Input",
    },
    {
      id: "do",
      text: "Digital Output",
    },
    {
      id: "fuel",
      text: "Fuel Level",
    },
    {
      id: "temp",
      text: "Temperature",
    },
    {
      id: "ble",
      text: "Bluetooth Low Energy",
    },
    {
      id: "humidity",
      text: "Humidity",
    },
    {
      id: "load",
      text: "Load",
    },
    {
      id: "alcohol",
      text: "Alcohol",
    },
    {
      id: "cust",
      text: "Custom",
    },
  ],
  TRACK_STOPTIMES: [
    {
      text: "> 1 min",
      value: 1,
    },
    {
      text: "> 2 mins",
      value: 2,
    },
    {
      text: "> 5 mins",
      value: 5,
    },
    {
      text: "> 10 mins",
      value: 10,
    },
    {
      text: "> 20 mins",
      value: 20,
    },
    {
      text: "> 30 mins",
      value: 30,
    },
    {
      text: "> 1 hr",
      value: 60,
    },
    {
      text: "> 2 hrs",
      value: 120,
    },
    {
      text: "> 5 hrs",
      value: 300,
    },
  ],
  TRACK_DURATIONS: [
    { title: "Last hour", value: "lasthour" },
    { title: "Today", value: "today" },
    {
      title: "Yesterday",
      value: "yesterday",
    },
    {
      title: "This week",
      value: "thisweek",
    },
    {
      title: "Last week",
      value: "lastweek",
    },
    {
      title: "This month",
      value: "thismonth",
    },
    {
      title: "Last month",
      value: "lastmonth",
    },
  ],
  DEFAULT_MAPTYPES_LIST: [
    "OSM Map",
    "Google Streets",
    "Google Satellite",
    "Google Hybrid",
    "Map Box Streets",
    "Map Box Satellite",
  ],
  DEFAULT_VEHICLE_STATUS_LIST: [
    "All",
    "No Signal",
    "Stopped",
    "Idle",
    "Running",
  ],
  DEFAULT_VEHICLE_LOAD_STATUS: 0,
  DEFAULT_STARTUP_SCREEN: {
    label: "Vehicles",
    value: "vehicles",
    selected: true,
  },
  DEFAULT_VEHICLE_LOAD_STATUS_LIST: ["All", "Available", "Engaged"],
  MARKER_WIDTH: 16,
  MARKER_HEIGHT: 16,
  MAX_ZOOM: 16,
  VEHICLE_MAX_ZOOM: 18, // On vehicle select from the list
  GOOGLE_SEARCH_MAX_ZOOM: 14,
  TRACK_ZOOM: 18,
  TRACK_SPEED: 4,
  MIN_ZOOM: 4,
  VEHICLES_TIMER: 15, // In Seconds
  ONLINEUSERS_TIMER: 10 * 1000, // In Seconds
  loadArr: ["Other Issues", "In Transit", "Loaded", "Placed", "Empty", ""],
  SORT_BY: "vehicle asc",
  SORT_BY_LIST: [
    {
      name: "Vehicle (Asc)",
      value: "vehicle asc",
    },
    {
      name: "Vehicle (Desc)",
      value: "vehicle desc",
    },
    {
      name: "Speed (Asc)",
      value: "speed asc",
    },
    {
      name: "Speed (Desc)",
      value: "speed desc",
    },
  ],
};

export const LABELS = {
  PLAY_PLAYBACK: "Play",
  STOP_PLAYBACK: "Stop",
  SKIP_STOPS: "Skip Stops",
  SPEED_PLUS: "Increase Playback Speed",
  SPEED_MINUS: "Decrease Playback Speed",
  MOVE_DURATION: "Move Duration",
  STOP_DURATION: "Stop Duration",
  TOTAL_KMS: "Total Kms",
  SIGN_IN: "Sign In",
  SIGN_IN_NOTE:
    "Note: By signing in, I agree that this website can set cookies on my browser.",
  USERNAME: "Username",
  PASSWORD: "Password",
  REMEMBER_ME: "Remember me",
  LOGIN: "Login",
  LOGOUT: "Logout",
  CANCEL: "Cancel",
  OK: "OK",
  SHOW: "Show",
  FORGOT_PASSWORD: "Forgot Password ?",
  HOME: "Home",
  MAP: "Map",
  REPORTS: "Reports",
  FLEET_STATUS: "Fleet Status",
  CONFERENCE_ROOM: "Conference Room",
  CONFERENCE_ROOM_LINK: "Conference Room Link",
  CPANEL: "CPanel",
  SETTINGS: "Settings",
  MAXIMIZE: "Maximize",
  FULL_SCREEN: "Full Screen",
  EXIT_FULL_SCREEN: "Exit Full Screen",
  FIT_SCREEN: "Fit Screen",
  EXIT_FIT_SCREEN: "Exit Fit Screen",
  LOCATE_MARKER: "Locate Marker",
  ADD_INCIDENT: "Add Incident",
  BROADCAST_MSG: "Broadcast Message",
  ASSIGN_INCIDENT: "Assign Incident",
  SCALE: "Scale",
  PRINT_MAP: "Print Map",
  HIDE_UNHIDE_LABELS: "Hide/Unhide Labels",
  HIDE_UNHIDE_VEHICLES: "Hide/Unhide Vehicles",
  HIDE_UNHIDE_POINTS: "Hide/Unhide Points",
  HIDE_UNHIDE_ROUTES: "Hide/Unhide Routes",
  HIDE_UNHIDE_ZONES: "Hide/Unhide Zones",
  HIDE_UNHIDE_CLUSTERS: "Hide/Unhide Clusters",
  SHOW_DATA_POINTS: "Hide/Unhide Data Points",
  SHOW_TRAFFIC: "Show Traffic",
  SAVE_CHANGES: "Save Changes",
  DISMISS: "Dismiss",
  ROOM_ID: "Room ID",
};

export const HELPER_TEXT = {
  PLEASE_ENTER_USERNAME: "Please enter username",
  PLEASE_ENTER_PASSWORD: "Please enter password",
  PLEASE_ENTER_ROOM_ID: "Please enter conference room ID (Eg: 1234)",
  SHARE_THIS_ID: "Conference ID",
};

export const CONFIRMS = {
  CONFIRM_LOGOUT: "Are you sure want to logout?",
};

export const ALERTS = {
  PLEASE_SELECT_VEHICLE: "Please select vehicle..!",
  PLEASE_SELECT_STOPTIME: "Please select stoptime..!",
  USERNAME_AND_PASSWORD_EMPTY: "Username and Password are empty..!",
  USERNAME_EMPTY: "Username is empty..!",
  PASSWORD_EMPTY: "Password is empty..!",
  LOGOUT_SUCESSSFUL: "Logout successful, redirecting to login page...",
  LOGIN_SUCESSSFUL: "Login Successful..!",
  LOGIN_UNSUCESSSFUL: "Login Unsuccessful..!",
  SESSION_TIMEOUT: "Session Timeout..!",
  INTERNAL_SERVER_ERROR: "Internal Server Error..!",
  TO_LOCATION_ERROR: "To location empty Error..!",
  NO_INTERNET: "No Internet or Network Error..!",
  NO_RECORDS: "No Records Found..!",
  INVALID_USERNAME: "Invalid username..!",
  INVALID_PASSWORD: "Invalid password..!",
};

export const COLORS = {
  PRIMARY: "#800000",
  SECONDARY: "#daa520",
  ICON_COLOR: "#2A2A39",
  WHITE: "#FFF",
  RED: "red",
  BLACK: "#000",
  DIM_GRAY: "#CCC",
  PROGRESS_BAR_COLOR: "primary",
  PURPLE: "#6b6bde",
};

export const UNITS = {
  TEMP: "°C",
  SPEED: "km/h",
  DISTANCE: "Kms",
  FUEL: "Ltrs.",
  PERCENT: "%",
  ANGLE: "°",
  Altitude: "m",
};

export const FORMATS = {
  SQL_DATE: "YYYY-MM-DD HH:mm:ss",
  SQL_DATE_ONLY: "YYYY-MM-DD",
  DATE: "DD-MM-YYYY HH:mm:ss",
  DATE_ONLY: "DD-MM-YYYY",
};

export const NOTIFY = {
  WARNING: "warning",
  SUCCESS: "success",
  ERROR: "error",
  AUTO_HIDE_DURATION: 3000,
};

export const LOCAL_STORAGE = {
  USER_DATA: "userInfo",
  USER_SETTINGS: "userSettings",
  MAP: "map",
  MAP_TAB: "mapTab",
  POI_TAB: "poiTab",
  MAP_SETTINGS: "mapSettings",
  PAGE_SETTINGS: "pageSettings",
  CALL_USER: "callUser",
};

export const REPORT_NAMES = {
  INCIDENT_REPORT: "Incidents Report",
  VEHICLE_INFO_REPORT: "Vehicles Information Report",
  JOURNEY_REPORT: "Journey Report",
  KM_REPORT: "KM Log Report",
};

export const REPORT_DESCRIPTION = {
  DASHBOARD: "Dashboard shows the overall statistics of the system.",
  INCIDENT_REPORT:
    "This report will show incident details for the selected date range.",
  VEHICLE_INFO_REPORT:
    "This report will show vehicle information for the selected vehicle(s).",
  JOURNEY_REPORT:
    "This report will show journey details of a vehicle for the selected date range.",
  KM_REPORT:
    "This report will show journey kilometer details of vehicle(s) for the selected date range.",
};
