import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Map,
  ZoomControl,
  ScaleControl,
  TileLayer,
  FeatureGroup,
} from "react-leaflet";

import "react-leaflet-markercluster/dist/styles.min.css";
import "leaflet/dist/leaflet.css";

// import PrintControl from "react-leaflet-easyprint";
// import PrintControlDefault from "react-leaflet-easyprint";

import FleetMapMarkers from "./Markers";
import FireAppMarkers from "./FireMarkers";
import DataPoints from "./DataPoints";
import Trackline from "./Trackline";
import MovingMarkerButton from "./MovingMarkerButton";
import DialogVideoCall from "../../videocall/DialogVideoCall";
import AddressAutoComplete from "./AddressAutoComplete";

import { CONFIG, DEFAULTS, LOCAL_STORAGE } from "../../common/consts";
import { MAP_ACTIONS } from "../../../actions/MapActions";
import { commonJs } from "../../common/common";

// Custom CSS
import "./styles/fleetmap.css";

// const PrintControl = withLeaflet(PrintControlDefault);

class FleetMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapPosition: {
        lat: this.props.mapReducer.initialLat,
        lng: this.props.mapReducer.initialLng,
      },
      zoom: this.props.mapReducer.zoom,
    };
  }

  addIncidentLocation = (lat, lng, zoom) => {
    this.updateRegion(lat, lng, zoom);
  };

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    document.addEventListener(
      "fullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "mozfullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "MSFullscreenChange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "webkitfullscreenchange",
      this.onFullScreenChange,
      false
    );
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
    document.removeEventListener(
      "fullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.removeEventListener(
      "mozfullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.removeEventListener(
      "MSFullscreenChange",
      this.onFullScreenChange,
      false
    );
    document.removeEventListener(
      "webkitfullscreenchange",
      this.onFullScreenChange,
      false
    );
  }

  onFullScreenChange = (e) => {
    let clslist = document.getElementById("mapContainer").classList;
    if (window.innerHeight === window.screen.height) {
      clslist.add("fullscreen");
    } else {
      clslist.contains("fullscreen");
    }
  };

  mapClick = (e) => {
    const { poiControl } = this.props.mapReducer;
    // If poiControl -> Add Marker
    if (poiControl !== "") {
    } else {
      // this._mapAutoComplete.setLatLng(e.latlng);
    }
  };

  mapDoubleClick = (e) => {
    console.log("Map: ", this._mapGroup);
    this._mapAutoComplete.setLatLng(e.latlng);
  };

  showRoute = () => {};

  shareVehicleLocation = () => {};

  trackVehicle = () => {};

  printMap = () => {
    // this.printControl.printMap(CONFIG.MAP_PRINT_SIZE, "Map");
  };

  getTileUrl = (mapType) => {
    var url = CONFIG.OSM_TILE_URL;
    const { mapTraffic } = this.props.mapReducer;
    switch (mapType) {
      case 1:
        url = mapTraffic
          ? CONFIG.GOOGLE_STREETS_MAP_WITH_TRAFFIC_URL
          : CONFIG.GOOGLE_STREETS_MAP_URL;
        break;
      case 2:
        url = mapTraffic
          ? CONFIG.GOOGLE_SATELLITE_MAP_WITH_TRAFFIC_URL
          : CONFIG.GOOGLE_SATELLITE_MAP_URL;
        break;
      case 3:
        url = mapTraffic
          ? CONFIG.GOOGLE_HYBRID_MAP_WITH_TRAFFIC_URL
          : CONFIG.GOOGLE_HYBRID_MAP_URL;
        break;
      case 4:
        url = CONFIG.MAP_BOX_TILE_URL_STREETS;
        break;
      case 5:
        url = CONFIG.MAP_BOX_TILE_URL_SATELLITE;
        break;
      default:
        break;
    }
    return url;
  };

  onDragMap = (e) => {
    const center = e.target.getCenter();
    const zoom = e.target.getZoom();
    let mapLocalStorage = commonJs.getItemFromLocal(LOCAL_STORAGE.MAP);
    if (mapLocalStorage === null) {
      mapLocalStorage = {};
    }
    mapLocalStorage.initialLat = center.lat;
    mapLocalStorage.initialLng = center.lng;
    mapLocalStorage.zoom = zoom;
    commonJs.setItemToLocal(LOCAL_STORAGE.MAP, mapLocalStorage);
    if (this._zones !== undefined && this._zones !== null) {
      this._zones.updateZoom(zoom);
    }
    if (this._points !== undefined && this._points !== null) {
      this._points.updateZoom(zoom);
    }
  };

  updateRegion = (lat, lng, zoom) => {
    this.setState({
      mapPosition: { lat, lng },
      zoom: zoom,
    });
  };

  _onEditPath = () => {};

  _onCreate = () => {};

  _onDeleted = () => {};

  trackFitBounds = () => {
    this._map.leafletElement.fitBounds(
      this._mapTracklineGroup.leafletElement.getBounds()
    );
  };

  mapFitBounds = () => {
    this._map.leafletElement.fitBounds(
      this._mapGroup.leafletElement.getBounds()
    );
  };

  render() {
    const { mapReducer, showTrack } = this.props;

    const {
      mapType,
      mapLabels,
      mapClusters,
      scale,
      signalFilter,
      loadStatusFilter,
      mapVehicles,
    } = mapReducer;

    const fullScreen = commonJs.isFullScreen();

    const tileUrl = this.getTileUrl(mapType);

    const { mapPosition, zoom } = this.state;

    let mPos = {
      lat: Number(mapPosition.lat),
      lng: Number(mapPosition.lng),
    };

    return (
      <div
        id="mapContainer"
        className={
          "mapContainer" +
          (fullScreen ? " fullscreen" : "") +
          (mapLabels ? "" : " mapLabels")
        }
      >
        <AddressAutoComplete
          addIncidentLocation={this.addIncidentLocation}
          childRef={(ref) => (this._mapAutoComplete = ref)}
        />
        <Map
          center={mPos}
          zoom={zoom}
          maxZoom={DEFAULTS.MAX_ZOOM}
          minZoom={DEFAULTS.MIN_ZOOM}
          viewport={null}
          onClick={this.mapClick}
          className="fleetMap"
          onMoveend={this.onDragMap}
          ref={(ref) => {
            this._map = ref;
          }}
          ondblclick={this.mapDoubleClick}
        >
          {/* <PrintControl
            ref={(ref) => {
              this.printControl = ref;
            }}
            position="topright"
            sizeModes={["Current", "A4Portrait", "A4Landscape"]}
            hideControlContainer={false}
          /> */}

          <ZoomControl position="bottomright" className="mapZoomControl" />

          {scale && (
            <ScaleControl position="topright" className="mapScaleControl" />
          )}

          <TileLayer url={tileUrl} />

          <FeatureGroup
            ref={(ref) => {
              this._mapGroup = ref;
            }}
          >
            <FleetMapMarkers
              showClusters={mapClusters}
              signalFilter={signalFilter}
              loadStatusFilter={loadStatusFilter}
              mapVehicles={mapVehicles}
              showTrack={showTrack}
            />
          </FeatureGroup>

          <FeatureGroup
            ref={(ref) => {
              this._mapTracklineGroup = ref;
            }}
          >
            <Trackline />
            <DataPoints />
          </FeatureGroup>

          <FireAppMarkers />

          {/* Zones */}
          {/* {mapZones && (
            <Zones
              zoom={zoom}
              ref={ref => {
                this._zones = ref;
              }}
            />
          )} */}

          {/* Points */}
          {/* {mapPoints && (
            <Points
              zoom={zoom}
              ref={ref => {
                this._points = ref;
              }}
            />
          )} */}
          <MovingMarkerButton />
        </Map>

        <DialogVideoCall childRef={(ref) => (this._videoCallDialog = ref)} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMapPosition: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_MAP_POSITION,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FleetMap);
