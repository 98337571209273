import React, { Component } from "react";
import {
  SwipeableDrawer,
  ListSubheader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
} from "@material-ui/core";

// Icons
import {
  Description,
  Home as HomeIcon,
  Assignment as ReportIcon,
  Assessment as DashboardIcon,
} from "@material-ui/icons";

import { LABELS, DEFAULTS, REPORT_NAMES } from "../../common/consts";

class ReportLeftBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportsMenu: false,
      reportsSideBar: false,
      leftFixedMenu: true,
    };
  }

  onToggleMenu = (menu) => {
    const { reportsMenu, leftFixedMenu, reportsSideBar } = this.state;
    if (menu === "reportsMenu") {
      this.setState({
        reportsMenu: !reportsMenu,
      });
    } else if (menu === "fixedMenu") {
      this.setState({
        leftFixedMenu: !leftFixedMenu,
      });
    } else if (menu === "reportsSideBar") {
      this.setState({
        reportsSideBar: !reportsSideBar,
      });
    } else if (menu === "home") {
      window.open("/", "_blank");
    }
  };

  onClickMenu = (menu) => {
    window.open("/reports/" + menu, "_self");
  };

  render() {
    const { reportsSideBar } = this.state;

    let path = window.location.pathname;

    return (
      <div className="report-leftbar">
        <div className={"leftFixedMenu"}>
          <ul className="topmenu">
            <li onClick={() => this.onToggleMenu("home")}>
              <Tooltip title={LABELS.HOME} placement="right">
                <IconButton classes={{ root: "menucolor" }} className={""}>
                  <HomeIcon />
                </IconButton>
              </Tooltip>
            </li>
            <li onClick={() => this.onToggleMenu("reportsSideBar")}>
              <Tooltip title={LABELS.REPORTS} placement="right">
                <IconButton
                  classes={{ root: "menucolor" }}
                  className={reportsSideBar ? "active" : ""}
                >
                  <Description />
                </IconButton>
              </Tooltip>
            </li>
          </ul>
        </div>

        <SwipeableDrawer
          open={reportsSideBar}
          onClose={() => this.onMenuToggle("reportsSideBar", false)}
          onOpen={() => this.onMenuToggle("reportsSideBar", true)}
          variant={DEFAULTS.LEFT_BAR_VARIENT}
          className={"leftdrawer " + (reportsSideBar ? "open" : "")}
          classes={{ paper: "leftdrawer-menu", modal: "fleetmodal" }}
        >
          <div className="reportSideBarWrapper">
            <List
              component="nav"
              subheader={
                <ListSubheader component="div">{LABELS.REPORTS}</ListSubheader>
              }
              className="menu"
            >
              <ListItem
                button
                onClick={() => this.onClickMenu("dashboard")}
                className={
                  "report-menu " +
                  (path === "/reports/dashboard" || path === "/reports"
                    ? "active"
                    : "")
                }
              >
                <ListItemIcon className="menuicon">
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText inset primary="Dashboard" />
              </ListItem>
              <ListItem
                button
                onClick={() => this.onClickMenu("incident")}
                className={
                  "report-menu " +
                  (path === "/reports/incident" ? "active" : "")
                }
              >
                <ListItemIcon className="menuicon">
                  <ReportIcon />
                </ListItemIcon>
                <ListItemText inset primary={REPORT_NAMES.INCIDENT_REPORT} />
              </ListItem>
              <ListItem
                button
                onClick={() => this.onClickMenu("vehicles-information")}
                className={
                  "report-menu " +
                  (path === "/reports/vehicles-information" ? "active" : "")
                }
              >
                <ListItemIcon className="menuicon">
                  <ReportIcon />
                </ListItemIcon>
                <ListItemText
                  inset
                  primary={REPORT_NAMES.VEHICLE_INFO_REPORT}
                />
              </ListItem>
              <ListItem
                button
                onClick={() => this.onClickMenu("km-report")}
                className={
                  "report-menu " +
                  (path === "/reports/km-report" ? "active" : "")
                }
              >
                <ListItemIcon className="menuicon">
                  <ReportIcon />
                </ListItemIcon>
                <ListItemText inset primary={REPORT_NAMES.KM_REPORT} />
              </ListItem>
              <ListItem
                button
                onClick={() => this.onClickMenu("journey")}
                className={
                  "report-menu " + (path === "/reports/journey" ? "active" : "")
                }
              >
                <ListItemIcon className="menuicon">
                  <ReportIcon />
                </ListItemIcon>
                <ListItemText inset primary={REPORT_NAMES.JOURNEY_REPORT} />
              </ListItem>
              <ListItem
                button
                onClick={() => this.onClickMenu("")}
                className={"report-menu "}
              >
                <ListItemIcon className="menuicon">
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText inset primary="Home" />
              </ListItem>

              {/* <ListItem
                button
                onClick={() => this.onClickMenu("live")}
                className="report-menu"
              >
                <ListItemIcon className="menuicon">
                  <ReportIcon />
                </ListItemIcon>
                <ListItemText inset primary="Live Report" />
              </ListItem>
              <ListItem
                button
                onClick={() => this.onClickMenu("power-unplug")}
                className="report-menu"
              >
                <ListItemIcon className="menuicon">
                  <ReportIcon />
                </ListItemIcon>
                <ListItemText inset primary="Power Unplug Report" />
              </ListItem>
              <ListItem
                button
                onClick={() => this.onClickMenu("consolidated")}
                className="report-menu"
              >
                <ListItemIcon className="menuicon">
                  <ReportIcon />
                </ListItemIcon>
                <ListItemText inset primary="Consolidated Report" />
              </ListItem>
              <ListItem
                button
                onClick={() => this.onClickMenu("travel")}
                className="report-menu"
              >
                <ListItemIcon className="menuicon">
                  <ReportIcon />
                </ListItemIcon>
                <ListItemText inset primary="Travel Report" />
              </ListItem> */}
            </List>
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

export default ReportLeftBar;
