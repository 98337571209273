import React, { Component } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import "./checkbox.css";

class CheckBoxGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: this.props.list
    };
  }

  handleFilterChange = e => {
    let id = e.target.id.split("-")[1];
    let list = this.state.list;
    list[id].checked = !list[id].checked;
    this.setState({
      list: list
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="checkList scrollBar">
          {this.state.list.map((checkbox, index) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkbox.checked}
                    onChange={this.handleFilterChange}
                    value={checkbox.value}
                    name={checkbox.name}
                    id={this.props.name + "-" + index}
                  />
                }
                label={checkbox.name}
                key={"groupFilter-" + index}
              />
            );
          })}
        </div>
      </React.Fragment>
    );
  }
}

export default CheckBoxGroup;
