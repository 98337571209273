import { createStore, applyMiddleware } from "redux";

import rootReducer from "../reducers";

const fleetLogger = store => next => action => {
  console.log("FleetLogger: ", action);
  next(action);
};

const store = createStore(rootReducer, {}, applyMiddleware(fleetLogger));

export default store;
