import React, { Component } from "react";

import { ExpansionPanel, ExpansionPanelDetails } from "@material-ui/core";

import { connect } from "react-redux";

import { AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";

import "./vehiclelist.css";

import { NOTIFY, ALERTS } from "../../../../../common/consts";
import { MAP_ACTIONS } from "../../../../../../actions/MapActions";
import { PAGE_ACTIONS } from "../../../../../../actions/PageActions";
import { COMMON_ACTIONS } from "../../../../../../actions/CommonActions";
import { API_Map } from "../../../../../apihandler/Map";
import { commonJs } from "../../../../../common/common";
import VehicleRows from "./VehicleRows";
import VehicleSettings from "./settings/VehicleSettings";
import { TRACK_ACTIONS } from "../../../../../../actions/TrackActions";

class VehicleList extends Component {
  loadExpandIcon = () => {
    return <AddCircleOutline />;
  };

  loadMinimizeIcon = () => {
    return <RemoveCircleOutline />;
  };

  hideAllGroupVehicle = () => {
    // this.setState({
    //   visible: !this.state.visible
    // });
  };

  reloadVehicles = () => {
    // Reset All
    this.props.setLoading({
      loading: true,
    });
    this.props.setVehicles({
      vehicles: [],
    });
    this.props.resetTrackData({
      routeDetails: {},
      routePoints: [],
      routeSummary: [],
      trackBottomBar: false,
    });
    this.loadVehicles(true);
  };

  loadVehicles = async (reset = false) => {
    var self = this;
    const opts = {};
    opts.pageNo = 0;
    opts.pageLength = 100000;
    opts.orderBy = "ASC";
    opts.sortColumn = "";
    API_Map.getAllVehicles(opts)
      .then((res) => {
        self.props.setVehicles({
          vehicles: res,
        });
        if (reset) {
          // Reset temp data as well
          self.props.resetVehicles({
            vehicles: res,
          });
          self.props.setLoading({ loading: false });
        }
      })
      .catch((err) => {
        self.props.setLoading({ loading: false });
        err = err.toString();
        if (err.indexOf("Network Error") > -1) {
          commonJs.showNotify(self.props, NOTIFY.WARNING, ALERTS.NO_INTERNET);
        }
      });
  };

  onVehicleScrolling = () => {};

  render() {
    const {
      tmpVehicles,
      filteredVehicles,
      signalFilter,
      loadStatusFilter,
    } = this.props.mapReducer;

    const { vehicleEditPopup, loading } = this.props.pageReducer;

    var vlist = tmpVehicles;
    if (filteredVehicles !== undefined && filteredVehicles.length > 0) {
      vlist = filteredVehicles;
    }

    const vehicles = commonJs.getVehicleFilteredList(
      vlist,
      signalFilter,
      loadStatusFilter
    );

    var vcount = vehicles.length;

    const { updateMapRegion, switchTab } = this.props;

    return (
      <React.Fragment>
        <div className="vehiclelist-header">
          <div className="vehiclelist-title">Vehicles List ({vcount})</div>
        </div>

        {/* Vehicles List */}
        <div id="vehicleList">
          <ExpansionPanel
            expanded={true}
            classes={{
              root: "expansionPanel open",
            }}
          >
            <ExpansionPanelDetails className="expansionPanelDetails">
              <div
                style={{
                  height: "calc(100vh - 195px)",
                  width: "100%",
                }}
                className="rowlist"
              >
                {loading ? (
                  <div
                    style={{
                      paddingTop: 2,
                      paddingBottom: 2,
                      paddingLeft: 6,
                      paddingRight: 6,
                    }}
                  >
                    {/* Loading Gif */}
                    <div className="placeholder">
                      <div className="animated-background" />
                    </div>
                    <div className="placeholder">
                      <div className="animated-background" />
                    </div>
                    <div className="placeholder">
                      <div className="animated-background" />
                    </div>
                    <div className="placeholder">
                      <div className="animated-background" />
                    </div>
                  </div>
                ) : (
                  <VehicleRows
                    updateMapRegion={updateMapRegion}
                    switchTab={switchTab}
                  />
                )}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>

        {/* Vehicle Settings */}
        {vehicleEditPopup && (
          <VehicleSettings reloadVehicles={this.reloadVehicles} />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
    pageReducer: state.pageReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMapInit: (values) => {
      dispatch({
        type: MAP_ACTIONS.INITIALIZE_MAP,
        values,
      });
    },
    setSelectedVehicle: (values) => {
      dispatch({
        type: MAP_ACTIONS.SELECT_VEHICLE,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_MAP_LOADING,
        values,
      });
    },
    resetVehicles: (values) => {
      dispatch({
        type: MAP_ACTIONS.RESET_VEHICLES,
        values,
      });
    },
    setVehicles: (values) => {
      dispatch({
        type: COMMON_ACTIONS.SET_VEHICLES,
        values,
      });
    },
    resetTrackData: (values) => {
      dispatch({
        type: TRACK_ACTIONS.SET_TRACK_DATA,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleList);
