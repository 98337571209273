import React, { Component } from "react";
import FleetDialog from "../ui/dialogbox/FleetDialog";
import { connect } from "react-redux";
import { REPORT_ACTIONS } from "../../actions/ReportActions";
import { CircularProgress } from "@material-ui/core";

import jQuery from "jquery";
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.flash";
import "pdfmake";

import "./datatables.min.css";

var $ = jQuery;

class ReportPopupDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    let self = this;
    setTimeout(() => {
      self.loadReport();
    }, 1500);
  }

  loadReport = () => {
    const { reportType, vehiclesList } = this.props;
    let exportTitle = "All Vehicles";
    const columns = [
      {
        title: "Sl No.",
      },
      {
        title: "Vehicle Name",
      },
      {
        title: "Vehicle Type",
      },
      {
        title: "Vehicle Status",
      },
      {
        title: "Device Time",
      },
      {
        title: "Address",
      },
    ];

    let rows = [];

    if (reportType === "all") {
      columns.push({
        title: "Driver Name",
      });
      columns.push({
        title: "Driver No.",
      });
      for (let v = 0; v < vehiclesList.length; v++) {
        rows.push([
          v + 1,
          vehiclesList[v].name,
          vehiclesList[v].vin,
          vehiclesList[v].loadstatus || "",
          vehiclesList[v].objectlogtime,
          "<a target='_blank' class='hyperlink' href='https://www.google.com/maps?q=" +
            vehiclesList[v].latitude +
            "," +
            vehiclesList[v].longitude +
            "'>" +
            vehiclesList[v].address +
            "</a>",
          vehiclesList[v].drivername,
          vehiclesList[v].driverno,
        ]);
      }
    } else if (reportType === "running") {
      exportTitle = "Running Vehicles";
      let c = 1;
      for (let v = 0; v < vehiclesList.length; v++) {
        if (vehiclesList[v].status === 3) {
          rows.push([
            c,
            vehiclesList[v].name,
            vehiclesList[v].vin,
            vehiclesList[v].loadstatus || "",
            vehiclesList[v].objectlogtime,
            "<a target='_blank' class='hyperlink' href='https://www.google.com/maps?q=" +
              vehiclesList[v].latitude +
              "," +
              vehiclesList[v].longitude +
              "'>" +
              vehiclesList[v].address +
              "</a>",
          ]);
          c++;
        }
      }
    } else if (reportType === "idle") {
      exportTitle = "Idle Vehicles";
      let c = 1;
      columns.push({
        title: "Idle-Since",
      });
      for (let v = 0; v < vehiclesList.length; v++) {
        if (vehiclesList[v].status === 2) {
          rows.push([
            c,
            vehiclesList[v].name,
            vehiclesList[v].vin,
            vehiclesList[v].loadstatus || "",
            vehiclesList[v].objectlogtime,
            "<a target='_blank' class='hyperlink' href='https://www.google.com/maps?q=" +
              vehiclesList[v].latitude +
              "," +
              vehiclesList[v].longitude +
              "'>" +
              vehiclesList[v].address +
              "</a>",
            vehiclesList[v].idletime,
          ]);
          c++;
        }
      }
    } else if (reportType === "stopped") {
      exportTitle = "Stopped Vehicles";
      let c = 1;
      for (let v = 0; v < vehiclesList.length; v++) {
        if (vehiclesList[v].status === 1) {
          rows.push([
            c,
            vehiclesList[v].name,
            vehiclesList[v].vin,
            vehiclesList[v].loadstatus || "",
            vehiclesList[v].objectlogtime,
            "<a target='_blank' class='hyperlink' href='https://www.google.com/maps?q=" +
              vehiclesList[v].latitude +
              "," +
              vehiclesList[v].longitude +
              "'>" +
              vehiclesList[v].address +
              "</a>",
            vehiclesList[v].idletime,
          ]);
          c++;
        }
      }
    } else if (reportType === "nosignal") {
      exportTitle = "No Signal Vehicles";
      let c = 1;
      for (let v = 0; v < vehiclesList.length; v++) {
        if (vehiclesList[v].status === 0) {
          rows.push([
            c,
            vehiclesList[v].name,
            vehiclesList[v].vin,
            vehiclesList[v].loadstatus || "",
            vehiclesList[v].objectlogtime,
            "<a target='_blank' class='hyperlink' href='https://www.google.com/maps?q=" +
              vehiclesList[v].latitude +
              "," +
              vehiclesList[v].longitude +
              "'>" +
              vehiclesList[v].address +
              "</a>",
          ]);
          c++;
        }
      }
    } else if (reportType === "available") {
      exportTitle = "Available Vehicles";
      let c = 1;
      for (let v = 0; v < vehiclesList.length; v++) {
        if (vehiclesList[v].loadstatus === "free") {
          rows.push([
            c,
            vehiclesList[v].name,
            vehiclesList[v].vin,
            "Free",
            vehiclesList[v].objectlogtime,
            "<a target='_blank' class='hyperlink' href='https://www.google.com/maps?q=" +
              vehiclesList[v].latitude +
              "," +
              vehiclesList[v].longitude +
              "'>" +
              vehiclesList[v].address +
              "</a>",
            vehiclesList[v].idletime,
          ]);
          c++;
        }
      }
    } else if (reportType === "busy") {
      exportTitle = "Engaged Vehicles";
      let c = 1;
      for (let v = 0; v < vehiclesList.length; v++) {
        if (vehiclesList[v].loadstatus === "busy") {
          rows.push([
            c,
            vehiclesList[v].name,
            vehiclesList[v].vin,
            vehiclesList[v].loadstatus || "",
            vehiclesList[v].objectlogtime,
            "<a target='_blank' class='hyperlink' href='https://www.google.com/maps?q=" +
              vehiclesList[v].latitude +
              "," +
              vehiclesList[v].longitude +
              "'>" +
              vehiclesList[v].address +
              "</a>",
            vehiclesList[v].idletime,
          ]);
          c++;
        }
      }
    }

    if ($.fn.dataTable.isDataTable("#reportGrid")) {
      let table = $("#reportGrid").DataTable();
      table.destroy();
    }
    $("#reportGrid").empty();

    $("#reportGrid").DataTable({
      dom: "Bfrtip",
      buttons: [
        {
          extend: "copy",
          className: "reportBtn",
          title: exportTitle,
        },
        {
          extend: "pdf",
          className: "reportBtn",
          title: exportTitle,
        },
        {
          extend: "excel",
          className: "reportBtn",
          title: exportTitle,
        },
        {
          extend: "csv",
          className: "reportBtn",
          title: exportTitle,
          text: "CSV",
        },
        {
          extend: "print",
          className: "reportBtn",
          title: exportTitle,
          customize: function(win) {
            $(win.document.body)
              .find("h1")
              .remove();
            $(win.document.body)
              .find("table")
              .attr("cellpadding", "3");
            $(win.document.body)
              .find("table")
              .css("font-size", "10px");
            $(win.document.body)
              .find("table")
              .css(
                "cssText",
                "border-bottom: none;border-left: none;border-right: none;"
              );
            $(win.document.body)
              .find("td")
              .css("font-size", "8px");
            $(win.document.body)
              .find("th")
              .css("font-size", "8px");
            $(win.document.body)
              .find("tbody td")
              .css(
                "cssText",
                "border: 1px solid #000;border-top: 0;border-right: 0;"
              );
            $(win.document.body)
              .find("tbody td:last-child")
              .css("cssText", "border: 1px solid #000;border-top: 0;");
            $(win.document.body)
              .find("table th")
              .css("cssText", "border: 1px solid #000;border-right: 0;");
            $(win.document.body)
              .find("table th:last-child")
              .css("cssText", "border: 1px solid #000;");
          },
        },
      ],
      paging: false,
      scrollY: 400,
      columns: columns,
      data: rows,
    });

    this.setState({
      loading: false,
    });
  };

  toggleDialog = () => {
    this.props.toggleDialog({
      reportPopupDialog: false,
    });
  };

  render() {
    const { reportType, title, maxWidth } = this.props;
    const { loading } = this.state;

    return (
      <FleetDialog
        open={true}
        onCloseSettings={this.toggleDialog}
        title={title || "Report"}
        maxWidth={maxWidth || "sm"}
        fullWidth={true}
        saveButton={false}
        cancelButton={false}
        cancelAction={this.toggleDialog}
        classNameWrapper={"reportPopupWrapper " + reportType}
      >
        <div className="reportPopup-wrapper">
          {loading && (
            <div className="loaderWrapper">
              <CircularProgress className="primaryLoader" />
            </div>
          )}
          <table
            id="reportGrid"
            className="mdl-data-table width100"
            cellPadding="3"
            cellSpacing="0"
          />
        </div>
      </FleetDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDialog: (values) => {
      dispatch({
        type: REPORT_ACTIONS.TOGGLE_REPORT_POPUP,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportPopupDialog);
