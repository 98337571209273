import React, { Component } from "react";
import { connect } from "react-redux";

import FleetGrid from "../../../ui/grid/FleetGrid";
import { DEFAULTS } from "../../../common/consts";

class TrackMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      direction: "asc",
      rows: null,
      columns: [
        { name: "fixtime", title: "Device Time" },
        { name: "servertime", title: "Server Time" },
        { name: "latitude", title: "Latitude" },
        { name: "longitude", title: "Longitude" },
        { name: "angle", title: "Angle" },
        { name: "speed", title: "Speed" },
        { name: "params", title: "Parameters" },
      ],
    };

    this._tableColumnWidths = [
      { columnName: "fixtime", width: 150 },
      { columnName: "servertime", width: 150 },
      { columnName: "latitude", width: 100 },
      { columnName: "longitude", width: 100 },
      { columnName: "angle", width: 100 },
      { columnName: "speed", width: 100 },
      { columnName: "params", width: 450 },
    ];

    this._tableColumnExtensions = [
      { columnName: "fixtime", align: "left" },
      { columnName: "servertime", align: "left" },
      { columnName: "latitude", align: "left" },
      { columnName: "longitude", align: "left" },
      { columnName: "angle", align: "center" },
      { columnName: "speed", align: "center" },
      { columnName: "params", align: "left" },
    ];

    this._sortingDisabledColumns = [
      { columnName: "latitude", sortingEnabled: false },
      { columnName: "longitude", sortingEnabled: false },
      { columnName: "params", sortingEnabled: false },
    ];
  }

  componentDidMount() {
    const { routePoints } = this.props.trackReducer;

    let rows = [];
    for (let i in routePoints) {
      rows.push({
        id: "trkmsg_" + new Date(routePoints[i].time).getTime(),
        fixtime: routePoints[i].time,
        servertime: routePoints[i].serverTime || "",
        latitude: routePoints[i].latitude,
        longitude: routePoints[i].longitude,
        angle: Number(routePoints[i].angle),
        speed: Number(routePoints[i].speed),
        params: JSON.stringify(routePoints[i].params),
      });
    }

    this.setState({
      rows: rows,
    });
  }

  render() {
    const { rows, columns } = this.state;

    return (
      <div id="trackMessagesBox">
        <FleetGrid
          rows={rows}
          columns={columns}
          id="trackMessagesGrid"
          childRef={(ref) => (this._trackMsgGrid = ref)}
          height={DEFAULTS.TRACK_MESSAGES_HEIGHT}
          setFilteredGridData={this.setFilteredGridData}
          tableColumnWidths={this._tableColumnWidths}
          tableColumnExtensions={this._tableColumnExtensions}
          sortingDisabledColumns={this._sortingDisabledColumns}
          showSortingControls={false}
          sortColumn={"fixtime"}
          sortType={"desc"}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trackReducer: state.trackReducer,
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackMessages);
