import React, { Component } from "react";

import { IconButton } from "@material-ui/core";

import { MoreVertRounded as Menu } from "@material-ui/icons";

import Img from "../../../../../ui/image/Img";
import { DEFAULTS } from "../../../../../common/consts";
import { commonJs } from "../../../../../common/common";
import { dateFunction } from "../../../../../common/datefunctions";
import TrackMenu from "../../../../leftbar/maptabs/tracktab/trackmenu/TrackMenu";

import gprs from "../../images/wifi.svg";
import gps from "../../images/gps.svg";
import gprsoff from "../../images/wifi_off.svg";
import gpsoff from "../../images/gps_off.svg";
import battery from "../../images/battery.svg";
import batteryoff from "../../images/batteryoff.svg";
import ignition from "../../images/key.svg";
import ignitionoff from "../../images/keyoff.svg";
import tempIcon from "../../images/temp.svg";
import fuelIcon from "../../images/fuel.svg";
import humidityIcon from "../../images/humidity.svg";
import alcoholIcon from "../../images/alcohol.svg";

import freeVehicleIcon from "../../../../../../images/icons/fire-truck-free.png";
import busyVehicleIcon from "../../../../../../images/icons/fire-truck-busy.png";

class VehicleRow extends Component {
  showVehicleMenu = (imei) => {
    let prevEl = document.querySelectorAll(".menulist.active");
    if (prevEl.length > 0) {
      if (prevEl[0].id !== "voptions-" + imei) {
        prevEl[0].classList.remove("active");
      }
    }
    document.getElementById("voptions-" + imei).classList.toggle("active");
  };

  selectVehicle = (imei) => {
    let selectedRow = localStorage.selectedRow;
    if (selectedRow !== undefined && selectedRow !== "") {
      let targetDiv = document.getElementById("vehiclerow-" + selectedRow);
      if (targetDiv !== null) {
        targetDiv.classList.remove("selected");
      }
    }
    document.getElementById("vehiclerow-" + imei).classList.add("selected");
    localStorage.selectedRow = imei;
    const { latitude, longitude } = this.props.vehicle;
    this.props.updateMapRegion(latitude, longitude, DEFAULTS.VEHICLE_MAX_ZOOM);
  };

  render() {
    const {
      slno,
      imei,
      vehicle,
      closeTrackMenu,
      onLoad,
      divstyle,
      switchTab,
    } = this.props;

    const { vstatus, vstatusText } = commonJs.getVehicleStatus(vehicle);

    const vsensors = commonJs.getVehicleSensors(vehicle.sensors);

    const vehicleIgn = commonJs.getVehicleIgnition(vehicle);

    return (
      <div
        id={"vehiclerow-" + imei}
        data-vehicle={imei}
        className={"vehiclerow"}
        style={divstyle}
        onLoad={onLoad}
      >
        <div className="vehicleicon" onClick={() => this.selectVehicle(imei)}>
          <Img
            src={
              vehicle.loadstatus === "busy" ? busyVehicleIcon : freeVehicleIcon
            }
          />
        </div>
        <div className="vehicledata" onClick={() => this.selectVehicle(imei)}>
          <h4>
            <span className="vehiclename">{slno + ". " + vehicle.name}</span>
            <span className={"vehiclestatus " + vstatus}>{vstatusText}</span>
            {vehicle.groupname !== undefined && (
              <span className="vehiclegroup">{vehicle.groupname}</span>
            )}
          </h4>
          <p className="vtime">
            {dateFunction.getFormatedDate(vehicle.objectlogtime)}
          </p>
          <p className="vaddress">{vehicle.address}</p>
          <ul>
            {/* GPRS */}
            <li title={vehicle.gprs ? "GPRS: ON" : "GPRS: OFF"}>
              <Img src={vehicle.gprs ? gprs : gprsoff} className="smallicon" />
            </li>

            {/* GPS */}
            <li title={vehicle.gps ? "GPS: ON" : "GPS: OFF"}>
              <Img src={vehicle.gps ? gps : gpsoff} className="smallicon" />
            </li>

            {/* Battery */}
            <li title={vehicle.battery ? "Battery: ON" : "Battery: OFF"}>
              <Img
                src={vehicle.battery ? battery : batteryoff}
                className="smallicon"
              />
            </li>

            {/* Ignition */}
            {vehicleIgn !== null && (
              <li title={vehicleIgn ? "Ignition: ON" : "Ignition: OFF"}>
                <Img
                  src={vehicleIgn ? ignition : ignitionoff}
                  className="smallicon"
                />
              </li>
            )}

            {/* Load All Sensors Values */}
            {vsensors.map((sensor, indx) => {
              if (sensor.type === "temp") {
                return (
                  <li
                    key={"vehiclesensor-" + indx}
                    title={sensor.name + ": " + sensor.value}
                  >
                    <Img src={tempIcon} className="smallicon" />
                    <span className="text">{sensor.value}</span>
                  </li>
                );
              } else if (sensor.type === "fuel") {
                return (
                  <li
                    key={"vehiclesensor-" + indx}
                    title={sensor.name + ": " + sensor.value}
                  >
                    <Img src={fuelIcon} className="smallicon" />
                    <span className="text">{sensor.value}</span>
                  </li>
                );
              } else if (sensor.type === "humidity") {
                return (
                  <li
                    key={"vehiclesensor-" + indx}
                    title={sensor.name + ": " + sensor.value}
                  >
                    <Img src={humidityIcon} className="smallicon" />
                    <span className="text">{" " + sensor.value}</span>
                  </li>
                );
              } else if (sensor.type === "alcohol") {
                return (
                  <li
                    key={"vehiclesensor-" + indx}
                    title={sensor.name + ": " + sensor.value}
                  >
                    <Img src={alcoholIcon} className="smallicon" />
                    <span className="text">{" " + sensor.value}</span>
                  </li>
                );
              } else {
                // Other Sensors
                return (
                  <li key={"sensor-" + indx}>
                    <span className="text">
                      {sensor.name + ": " + sensor.value}
                    </span>
                  </li>
                );
              }
            })}
          </ul>
        </div>
        <div className="vehiclemenu">
          <IconButton onClick={() => this.showVehicleMenu(imei)} title="Menu">
            <Menu />
          </IconButton>
          <TrackMenu
            imei={imei}
            closeTrackMenu={closeTrackMenu}
            switchTab={switchTab}
          />
        </div>
      </div>
    );
  }
}

export default VehicleRow;
