import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

class Loading extends Component {
  render() {
    return (
      <div className={"loading"}>
        <CircularProgress className="loader" />
        <div className="loaderMsg">{this.props.children}</div>
      </div>
    );
  }
}

export default Loading;
