import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import "./videoroom.css";
import { CircularProgress, Divider, IconButton } from "@material-ui/core";
import {
  Mic as MicrophoneIcon,
  MicOff as MicrophoneOffIcon,
  VolumeUp as SpeakerOnIcon,
  VolumeOff as SpeakerOffIcon,
  SignalCellularConnectedNoInternet1Bar as SlowNetworkIcon,
  Videocam as VideoCamIcon,
  VideocamOff as VideoCamOffIcon,
  Rotate90DegreesCcw as RotateVideoIcon,
  Repeat as MirrorIcon,
  Close as CloseIcon,
} from "@material-ui/icons";

import Janus from "../videocall/janus";
import { API_FireApp } from "../apihandler/FireApp";
import { commonJs } from "../common/common";
import { LOCAL_STORAGE, LABELS, NOTIFY, DEFAULTS } from "../common/consts";

import { API_UserLogin } from "../apihandler/UserLogin";
import Btn from "../ui/button/Btn";
import Img from "../ui/image/Img";
import jQuery from "jquery";

import logo from "../../images/logo/logo.png";
import personIcon from "../../images/icons/person.png";

var bitrateTimer = [];
var $ = jQuery;

class VideoRoom extends Component {
  constructor(props) {
    super(props);
    let roomId = "";
    let confRoom = window.location.search.split("?room=");
    if (confRoom.length > 1) {
      roomId = confRoom[1];
    }
    this.state = {
      feeds: [],
      loading: true,
      roomLink: "",
      roomId: roomId === "" ? DEFAULTS.DEFAULT_ROOM_ID : roomId,
      joined: false,
      microphone: true,
      showVideo: true,
      slowNetwork: true,
      activeRooms: null,
    };
    document.getElementById("root").classList.remove("login");
  }

  componentDidMount() {
    document.getElementById("root").classList.add("scroll");
    var userDataJson = commonJs.getItemFromLocal(LOCAL_STORAGE.USER_DATA);
    API_UserLogin.setToken(userDataJson.token);
    this.getInitialData();

    if (this.state.roomId === DEFAULTS.DEFAULT_ROOM_ID) {
      this.loadActiveRooms();
    }
  }

  loadActiveRooms = () => {
    let self = this;
    API_FireApp.getIncidents({ status: "open" }).then((res) => {
      if (res.Result === 200) {
        let rooms = res.Response;
        self.setState({
          activeRooms: rooms,
        });
      }
    });
  };

  getInitialData = () => {
    let self = this;
    API_FireApp.getJanusToken()
      .then((res) => {
        if (res.Result === 200) {
          localStorage.roomToken = res.Response.Token;
          self.initJanus(
            res.Response.Token,
            res.Response.GatewayUrl,
            res.Response.StunUri
          );
        }
      })
      .catch((err) => {
        console.log("getJanusToken err:", err);
      });
  };

  initJanus = (token, gatewayUrl, stunUri) => {
    var self = this;
    Janus.init({
      debug: true,
      callback: function() {
        if (!Janus.isWebrtcSupported()) {
          commonJs.showNotify(
            self.props,
            NOTIFY.ERROR,
            "Browser not supported: Sorry, you can't join/create Conference Room..!",
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
          return;
        }
        // Create session
        self._janus = new Janus({
          server: gatewayUrl,
          token: token,
          iceServers: [{ urls: stunUri }],
          success: () => {
            self._janus.attach({
              plugin: "janus.plugin.videoroom",
              opaqueId: token,
              success: (pluginHandle) => {
                self._videoCall = pluginHandle;
                self.setState({
                  loading: false,
                });
                let confRoom = window.location.search.split("?room=");
                if (confRoom.length > 1) {
                  self.joinRoom(confRoom[1]);
                }
              },
              error: function(error) {
                console.log("Janus Error: ", error);
              },
              destroyed: function() {
                // window.location.reload();
              },
              webrtcState: function(on) {
                if (!on) return;
              },
              onmessage: function(msg, jsep) {
                console.log("Got a message -> ", msg);
                var event = msg["videoroom"];
                Janus.debug("Event: " + event);
                if (event !== undefined && event !== null) {
                  if (event === "created") {
                    const { roomId } = self.state;
                    self.joinRoom(roomId);
                  } else if (event === "joined") {
                    self.setState({
                      joined: true,
                      loading: false,
                    });
                    // Publisher/manager created, negotiate WebRTC and attach to existing feeds, if any
                    self.publishOwnFeed(true);
                    // Any new feed to attach to?
                    if (
                      msg["publishers"] !== undefined &&
                      msg["publishers"] !== null
                    ) {
                      const { userName } = self.props.loginReducer;
                      var list = msg["publishers"].map((p) => {
                        p.speaker = true;
                        return p;
                      });
                      console.log("Participants: ", list);
                      let inactiveList = list.filter((l) => {
                        if (l.display === userName) {
                          self.kickUser(l.id);
                          return l;
                        }
                        return;
                      });
                      list = list.filter((l) => {
                        console.log("list: ", l.display);
                        if (l.display !== userName) {
                          return l;
                        }
                        return;
                      });
                      if (inactiveList.length > 0) {
                        console.log("VC: Inactive Feeds: ", inactiveList);
                      }
                      console.log("VC: Active Feeds: ", list);

                      self.setState(
                        {
                          feeds: list,
                        },
                        () => {
                          for (var f in list) {
                            var id = list[f]["id"];
                            var display = list[f]["display"];
                            var audio = list[f]["audio_codec"];
                            var video = list[f]["video_codec"];
                            self.newRemoteFeed(id, video, false);
                            console.log(
                              "newRemoteFeed: ",
                              display,
                              video,
                              audio
                            );
                          }
                        }
                      );
                    }
                  } else if (event === "destroyed") {
                    // The room has been destroyed
                    window.location.reload();
                  } else if (event === "event") {
                    // Any new feed to attach to?
                    if (
                      msg["publishers"] !== undefined &&
                      msg["publishers"] !== null
                    ) {
                      let list = msg["publishers"];
                      list = msg["publishers"].map((p) => {
                        p.speaker = true;
                        return p;
                      });
                      let oldFeeds = list;
                      if (list.length > 0) {
                        oldFeeds = self.state.feeds;
                        oldFeeds.push(list[0]);
                      }
                      self.setState(
                        {
                          feeds: oldFeeds,
                        },
                        () => {
                          for (var f in list) {
                            var id = list[f]["id"];
                            var display = list[f]["display"];
                            var audio = list[f]["audio_codec"];
                            var video = list[f]["video_codec"];
                            self.newRemoteFeed(id, video, true);
                            console.log(
                              "newRemoteFeed: ",
                              display,
                              video,
                              audio
                            );
                          }
                        }
                      );
                    } else if (
                      msg["leaving"] !== undefined &&
                      msg["leaving"] !== null
                    ) {
                      // One of the publishers has gone away?
                      var leaving = msg["leaving"];
                      let leftUser = self.state.feeds.filter((l) => {
                        return l.id === leaving;
                      });
                      if (leftUser.length > 0) {
                        commonJs.showNotify(
                          self.props,
                          NOTIFY.WARNING,
                          "User has left: '" + leftUser[0].display + "'",
                          <button className="snackbar-btn">
                            {LABELS.DISMISS}
                          </button>
                        );
                      } else if (msg["reason"] === "kicked") {
                        window.open("/vc", "_self");
                      }
                      let filteredList = self.state.feeds.filter((l) => {
                        return l.id !== leaving;
                      });
                      self.setState({
                        feeds: filteredList,
                      });
                    } else if (
                      msg["unpublished"] !== undefined &&
                      msg["unpublished"] !== null
                    ) {
                      // One of the publishers has unpublished?
                      var unpublished = msg["unpublished"];
                      if (unpublished === "ok") {
                        // That's us
                        self._videoCall.hangup();
                        return;
                      }
                    } else if (
                      msg["error"] !== undefined &&
                      msg["error"] !== null
                    ) {
                      if (msg["error_code"] === 426) {
                        self.createRoom();
                        const { roomId } = self.state;
                        self.joinRoom(roomId);
                        commonJs.showNotify(
                          self.props,
                          NOTIFY.WARNING,
                          "Please wait..!",
                          <button className="snackbar-btn">
                            {LABELS.DISMISS}
                          </button>
                        );
                      } else if (msg["error_code"] === 425) {
                        commonJs.showNotify(
                          self.props,
                          NOTIFY.WARNING,
                          "Already exists, reconnecting server...",
                          <button className="snackbar-btn">
                            {LABELS.DISMISS}
                          </button>
                        );
                        self.stopCall();
                      }
                      console.log("error: ", msg);
                    } else if (
                      msg["kicked"] !== undefined &&
                      msg["kicked"] !== null
                    ) {
                      const { feeds } = self.state;
                      let filteredList = feeds.filter((l) => {
                        return l.id !== msg["kicked"];
                      });
                      self.setState({
                        feeds: filteredList,
                      });
                    }
                  }
                }
                if (jsep !== undefined && jsep !== null) {
                  self._videoCall.handleRemoteJsep({ jsep: jsep });
                  // Check if any of the media we wanted to publish has
                  // been rejected (e.g., wrong or unsupported codec)
                  var audio = msg["audio_codec"];
                  var video = msg["video_codec"];
                  if (
                    self._localStream &&
                    self._localStream.getAudioTracks() &&
                    self._localStream.getAudioTracks().length > 0 &&
                    !audio
                  ) {
                    // Audio has been rejected
                    console.error(
                      "Our audio stream has been rejected, viewers won't hear us"
                    );
                  }
                  if (
                    self._localStream &&
                    self._localStream.getVideoTracks() &&
                    self._localStream.getVideoTracks().length > 0 &&
                    !video
                  ) {
                    // Video has been rejected
                    console.error(
                      "Our video stream has been rejected, viewers won't see us"
                    );
                  }
                }
              },
              onlocalstream: function(stream) {
                console.log(" ::: Got a local stream :::");

                self._localStream = stream;

                if (!document.getElementById("video1")) {
                  return;
                }

                Janus.attachMediaStream(
                  document.getElementById("video1"),
                  stream
                );

                // Muting to clear the Noise
                document.getElementById("video1").muted = "muted";

                if (
                  self._videoCall.webrtcStuff.pc.iceConnectionState !==
                    "completed" &&
                  self._videoCall.webrtcStuff.pc.iceConnectionState !==
                    "connected"
                ) {
                  // console.log("Publishing...");
                }
                var videoTracks = stream.getVideoTracks();
                if (
                  videoTracks === null ||
                  videoTracks === undefined ||
                  videoTracks.length === 0
                ) {
                  console.log("No Webcam...");
                } else {
                }
              },
              onremotestream: function(stream) {
                console.log("on remote stream:");
                var videoTracks = stream.getVideoTracks();
                if (
                  videoTracks === null ||
                  videoTracks === undefined ||
                  videoTracks.length === 0
                ) {
                  // No remote video
                  console.log("No remote video");
                } else {
                  console.log("Show remote video");
                }
              },
              ondataopen: function(data) {
                Janus.log("The DataChannel is available!");
              },
              ondata: function(data) {
                Janus.debug("We got data from the DataChannel! " + data);
              },
              oncleanup: function() {
                Janus.log("=> Got a cleanup notification");
                // self.resetData();
              },
            });
          },
          error: function(error) {
            console.error(error);
            // window.location.reload();
          },
          destroyed: function() {
            // window.location.reload();
          },
        });
      },
    });
  };

  kickUser = (userId) => {
    if (this._videoCall) {
      const { roomId } = this.state;
      let kick = {
        request: "kick",
        room: Number(roomId),
        id: userId,
      };
      this._videoCall.send({
        message: kick,
      });
    }
  };

  createRoom = () => {
    let roomId = Number(this.state.roomId);
    let register = {
      request: "create",
      room: roomId,
      publishers: DEFAULTS.MAX_PARTICIPANTS,
    };
    this._videoCall.send({ message: register });
    console.log("createRoom: ", register);
  };

  destroyRoom = (roomId) => {
    let destroy = {
      request: "destroy",
      room: Number(roomId),
    };
    console.log("destroy:", destroy);
    this._videoCall.send({ message: destroy });
  };

  joinRoom = (roomId) => {
    const { joined } = this.state;
    if (joined) {
      return;
    }
    this.setState({
      loading: true,
    });

    var userData = commonJs.getItemFromLocal(LOCAL_STORAGE.USER_SETTINGS);

    let register = {
      request: "joinandconfigure",
      room: Number(roomId),
      ptype: "publisher",
      display: userData.userName,
    };

    if (Number(roomId) !== DEFAULTS.DEFAULT_ROOM_ID) {
      let self = this;
      // If Incident open ? -> Join the room
      API_FireApp.isIncidentOpen({ id: roomId })
        .then((res) => {
          self.setState({
            loading: false,
          });
          if (res.Result === 200) {
            if (res.Response === "open") {
              console.log("joinRoom:", register);
              self._videoCall.send({ message: register });
            } else {
              document.getElementById("videoRoomBody").innerHTML =
                '<h4 style="color:red;padding-left: 15px;">Sorry, the requested incident has been closed..!</h4>';
            }
          }
        })
        .catch((err) => {});
    } else {
      console.log("joinRoom:", register);
      this._videoCall.send({ message: register });
    }
  };

  publishOwnFeed = (sendAudio = true) => {
    // Publish our stream
    let self = this;
    this._videoCall.createOffer({
      // No media provided: by default, it's sendrecv for audio and video
      media: {
        audioRecv: false,
        videoRecv: false,
        audioSend: true,
        videoSend: true,
        data: true,
      }, // Let's negotiate data channels as well
      // If you want to test simulcasting (Chrome and Firefox only), then
      // pass a ?simulcast=true when opening this demo page: it will turn
      // the following 'simulcast' property to pass to janus.js to true
      simulcast: false,
      success: (jsep) => {
        var publish = { request: "publish", audio: true, video: true };
        publish["audiocodec"] = "opus";
        publish["videocodec"] = "vp8";

        // You can force a specific codec to use when publishing by using the
        // audiocodec and videocodec properties, for instance:
        // 		publish["audiocodec"] = "opus"
        // to force Opus as the audio codec to use, or:
        // publish["videocodec"] = "vp9";
        // to force VP9 as the videocodec to use. In both case, though, forcing
        // a codec will only work if: (1) the codec is actually in the SDP (and
        // so the browser supports it), and (2) the codec is in the list of
        // allowed codecs in a room. With respect to the point (2) above,
        // refer to the text in janus.plugin.videoroom.cfg for more details
        self._videoCall.send({ message: publish, jsep: jsep });
      },
      error: (error) => {
        console.error("WebRTC error:", error);
      },
    });
  };

  unpublishOwnFeed = () => {
    // Unpublish our stream
    var unpublish = { request: "unpublish" };
    this._videoCall.send({ message: unpublish });
  };

  stopCall = () => {
    window.open("/vc", "_self");
  };

  newRemoteFeed = (id, video, displayNotify) => {
    let self = this;
    const { roomId, feeds } = this.state;
    // A new feed has been published, create a new plugin handle and attach to it as a subscriber
    var remoteFeed = null;
    this._janus.attach({
      plugin: "janus.plugin.videoroom",
      success: function(pluginHandle) {
        remoteFeed = pluginHandle;
        remoteFeed.simulcastStarted = false;
        // We wait for the plugin to send us an offer
        var subscribe = {
          request: "join",
          room: Number(roomId),
          ptype: "subscriber",
          feed: id,
        };
        // In case you don't want to receive audio, video or data, even if the
        // publisher is sending them, set the 'offer_audio', 'offer_video' or
        // 'offer_data' properties to false (they're true by default), e.g.:
        // 		subscribe["offer_video"] = false;
        // For example, if the publisher is VP8 and this is Safari, let's avoid video
        if (
          Janus.webRTCAdapter.browserDetails.browser === "safari" &&
          (video === "vp9" || (video === "vp8" && !Janus.safariVp8))
        ) {
          if (video) video = video.toUpperCase();

          subscribe["offer_video"] = false;
        }
        remoteFeed.videoCodec = video;
        remoteFeed.send({ message: subscribe });
      },
      error: function(error) {
        console.error("Error attaching plugin: ", error);
      },
      onmessage: function(msg, jsep) {
        console.log("Got a message -> ", msg);
        var event = msg["videoroom"];
        if (msg["error"] !== undefined && msg["error"] !== null) {
          console.log(msg["error"]);
        } else if (event !== undefined && event !== null) {
          if (event === "attached") {
            // Subscriber created and attached
            for (let i = 0; i < feeds.length; i++) {
              if (feeds[i] === undefined || feeds[i] === null) {
                feeds[i] = remoteFeed;
                remoteFeed.rfindex = i;
                break;
              }
            }
            remoteFeed.rfid = msg["id"];
            remoteFeed.rfdisplay = msg["display"];
            if (displayNotify) {
              commonJs.showNotify(
                self.props,
                NOTIFY.SUCCESS,
                "New user has joined: '" + remoteFeed.rfdisplay + "'",
                <button className="snackbar-btn">{LABELS.DISMISS}</button>
              );
            }
          } else if (event === "event") {
            // Check if we got an event on a simulcast-related event from this publisher
            var substream = msg["substream"];
            var temporal = msg["temporal"];
            if (
              (substream !== null && substream !== undefined) ||
              (temporal !== null && temporal !== undefined)
            ) {
              if (!remoteFeed.simulcastStarted) {
                remoteFeed.simulcastStarted = true;
              }
            }
          } else {
            // What has just happened?
          }
        }
        if (jsep !== undefined && jsep !== null) {
          // Answer and attach
          remoteFeed.createAnswer({
            jsep: jsep,
            // Add data:true here if you want to subscribe to datachannels as well
            // (obviously only works if the publisher offered them in the first place)
            media: {
              audioSend: false,
              videoSend: false,
            }, // We want recvonly audio/video
            success: function(jsep) {
              var body = { request: "start", room: Number(roomId) };
              remoteFeed.send({ message: body, jsep: jsep });
            },
            error: function(error) {
              console.log.error("WebRTC error:", error);
            },
          });
        }
      },
      webrtcState: function(on) {
        console.log(
          "WebRTC PeerConnection (feed #" +
            remoteFeed.rfindex +
            ") is " +
            (on ? "up" : "down") +
            " now"
        );
      },
      onlocalstream: function(stream) {
        // The subscriber stream is recvonly, we don't expect anything here
      },
      onremotestream: function(stream) {
        if (document.getElementById("video" + remoteFeed.rfid)) {
          Janus.attachMediaStream(
            document.getElementById("video" + remoteFeed.rfid),
            stream
          );
        }

        try {
          var videoTracks = stream.getVideoTracks();
          if (
            videoTracks === null ||
            videoTracks === undefined ||
            videoTracks.length === 0
          ) {
            // No remote video
            console.log("No remote video");
          }
        } catch (error) {}

        if (
          Janus.webRTCAdapter.browserDetails.browser === "chrome" ||
          Janus.webRTCAdapter.browserDetails.browser === "firefox" ||
          Janus.webRTCAdapter.browserDetails.browser === "safari"
        ) {
          bitrateTimer[remoteFeed.rfindex] = setInterval(() => {
            if (!document.getElementById("video" + remoteFeed.rfid)) {
              clearInterval(bitrateTimer[remoteFeed.rfindex]);
              return;
            }
            // Display updated bitrate, if supported
            let bitrate = remoteFeed.getBitrate();
            // Check if the resolution changed too
            let width = document.getElementById("video" + remoteFeed.rfid)
              .videoWidth;
            let height = document.getElementById("video" + remoteFeed.rfid)
              .videoHeight;
            if (width > 0 && height > 0) {
              if (bitrate === "Invalid PeerConnection") {
                bitrate = "";
              }
              document.getElementById("bitrate").innerHTML = bitrate;
            } else {
              document.getElementById("bitrate").innerHTML = "";
            }
          }, 1000);
        }
      },
      oncleanup: function() {
        console.log("-> Got a cleanup notification (remote feed " + id + ")");
        if (
          bitrateTimer[remoteFeed.rfindex] !== null &&
          bitrateTimer[remoteFeed.rfindex] !== null
        ) {
          clearInterval(bitrateTimer[remoteFeed.rfindex]);
        }
        bitrateTimer[remoteFeed.rfindex] = null;
      },
    });
  };

  onToggleMicrophone = () => {
    let microphone = true;
    var muted = this._videoCall.isAudioMuted();
    if (muted) {
      this._videoCall.unmuteAudio();
    } else {
      this._videoCall.muteAudio();
      microphone = false;
    }
    this.setState({
      microphone: microphone,
    });
  };

  resetData = () => {
    this.setState({
      roomLink: "",
      roomId: "",
      joined: false,
      feeds: [],
      microphone: true,
    });
  };

  onToggleSpeaker = (indx) => {
    let remoteList = this.state.feeds;
    remoteList[indx].speaker = !remoteList[indx].speaker;
    this.setState({
      feeds: remoteList,
    });
  };

  onToggleVideo = () => {
    const { showVideo } = this.state;
    if (!showVideo) {
      //Publish Video
      this.publishOwnFeed();
    } else {
      //Unpublish Video
      this.unpublishOwnFeed();
    }
    this.setState({
      showVideo: !showVideo,
    });
  };

  onToggleRotateCam = (id) => {
    id = id.toString();
    console.log("onToggleRotateCam: ", id);
    let elem = document.querySelector(".videoRoomBox #video" + id);
    if (elem) {
      if (elem.classList.contains("rotated90")) {
        elem.classList.remove("rotated90");
        elem.classList.add("rotated270");
      } else if (elem.classList.contains("rotated270")) {
        this.removeAllRotates(id);
      } else {
        elem.classList.add("rotated90");
      }
    }
  };

  removeAllRotates = (id) => {
    let elem = document.querySelector(".videoRoomBox #video" + id);
    elem.classList.remove("rotated90");
    elem.classList.remove("rotated270");
  };

  onToggleMirrorCam = (id) => {
    id = id.toString();
    console.log("onToggleMirrorCam: ", id);
    let elem = document.querySelector(".videoRoomBox #video" + id);
    if (elem) {
      if (elem.classList.contains("mirror")) {
        elem.classList.remove("mirror");
      } else {
        elem.classList.add("mirror");
      }
    }
  };

  reloadRoom = () => {
    const { roomId } = this.state;
    let request = { request: "listparticipants", room: Number(roomId) };
    let self = this;
    console.log("reloadRoom: ", request);
    this._videoCall.send({
      message: request,
      success: (list) => {
        console.log("reloadRoom: ", list);
        const { participants } = list;
        const { userName } = self.props.loginReducer;
        // Skip my old sessions
        let finalList = participants.filter((l) => {
          l.speaker = true;
          return l.display !== userName;
        });
        self.setState({
          feeds: finalList,
        });
        // Kick my old sessions -> TODO: Check it includes my session or not ?
        // for (let i = 0; i < participants.length; i++) {
        //   if (participants[i].display === userName) {
        //     self.kickUser(participants[i].id);
        //   }
        // }
      },
    });
  };

  onKickUser = (userId) => {
    if (window.confirm("Are you sure want to remove..?")) {
      if (this._videoCall) {
        const { roomId } = this.state;
        let kick = {
          request: "kick",
          room: Number(roomId),
          id: userId,
        };
        this._videoCall.send({
          message: kick,
          success: (res) => {
            console.log("kicked: ", kick, res);
          },
        });
      }
    }
  };

  render() {
    const {
      loading,
      feeds,
      joined,
      microphone,
      showVideo,
      slowNetwork,
      activeRooms,
    } = this.state;
    const { userName } = this.props.loginReducer;

    console.log("joined: ", joined, loading);

    return (
      <div className="videoroom">
        {/* <TopProgressBar className={loading ? "showprogress" : "hideprogress"} /> */}
        <div id="videoRoomHeader">
          <div id="videoRoomTitle">
            <div id="topRightBox">
              <div id="reportLogo">
                <Img src={personIcon} width={30} height={30} />
              </div>
              <div id="reportUserInfo">
                <h4>{userName}</h4>
              </div>
            </div>
            <center>
              <Img src={logo} width="80" />
            </center>
            <h1>KSFES Video Conference Room</h1>
            {!loading && (
              <div id="videoRoomFilter">
                {/* {joined && (
                  <Btn className={"joinBtn"} onClick={this.reloadRoom}>
                    Reload List
                  </Btn>
                )} */}
                <Btn
                  className={"stopBtn " + (!joined ? "disabled" : "")}
                  onClick={this.stopCall}
                  disabled={!joined}
                >
                  Leave
                </Btn>
              </div>
            )}
          </div>
          {/* <div id="roomLink">
            {roomLink != "" && (
              <label>
                Conference Link: <span>{roomLink}</span>
              </label>
            )}
          </div> */}
          <Divider />
        </div>
        <div id="videoRoomBody">
          {loading ? (
            <div className="centerBox">
              <CircularProgress color="primary" className="purple" />
            </div>
          ) : !joined && activeRooms !== null ? (
            <div id="activeRooms">
              <p>Active Rooms:</p>
              <table width="100%" border="1" cellSpacing={0} cellPadding={3}>
                <tbody>
                  <tr>
                    <td align="center">{1}</td>
                    <td>{"KSFES Conference Room (default)"}</td>
                    <td align="center">
                      <a href={"/vc?room=" + DEFAULTS.DEFAULT_ROOM_ID}>Join</a>
                    </td>
                  </tr>
                  {activeRooms.map((room, indx) => {
                    return (
                      <tr key={room.incidentId}>
                        <td align="center">{indx + 2}</td>
                        <td>{room.incidentDetails}</td>
                        <td align="center">
                          <a href={"/vc?room=" + room.incidentId}>Join</a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            joined &&
            !loading && (
              <div className="videoRoomBox">
                <video id="video1" autoPlay={true} className="rotated180" />
                <p>{userName}</p>
                <IconButton
                  classes={{
                    root: "microphoneBtn " + (microphone ? "" : "disabled"),
                  }}
                  onClick={this.onToggleMicrophone}
                  title={microphone ? "Microphone On" : "Microphone Off"}
                >
                  {microphone ? <MicrophoneIcon /> : <MicrophoneOffIcon />}
                </IconButton>
                <IconButton
                  classes={{
                    root: "videoBtn " + (showVideo ? "" : "disabled"),
                  }}
                  onClick={this.onToggleVideo}
                  title={showVideo ? "Video On" : "Video Off"}
                >
                  {showVideo ? <VideoCamIcon /> : <VideoCamOffIcon />}
                </IconButton>
                {/* {slowNetwork && (
                  <IconButton
                    classes={{ root: "slowNetBtn" }}
                    onClick={this.onToggleVideo}
                    title="Slow Network"
                  >
                    <SlowNetworkIcon />
                  </IconButton>
                )} */}
              </div>
            )
          )}

          {/* Feeds */}
          {feeds.map((f, i) => {
            return (
              <div className="videoRoomBox" key={f.id}>
                <video id={"video" + f.id} autoPlay={true} muted={!f.speaker} />
                <p>{f.display}</p>
                <span id="bitrate"></span>
                <IconButton
                  classes={{
                    root: "vcCloseBtn",
                  }}
                  onClick={() => this.onKickUser(f.id)}
                >
                  <CloseIcon />
                </IconButton>
                <IconButton
                  classes={{
                    root: "microphoneBtn " + (f.speaker ? "" : "disabled"),
                  }}
                  onClick={() => this.onToggleSpeaker(i)}
                >
                  {f.speaker ? <SpeakerOnIcon /> : <SpeakerOffIcon />}
                </IconButton>
                <div className="vcOptions">
                  <IconButton
                    classes={{ root: "rotateCam" }}
                    onClick={() => this.onToggleRotateCam(f.id)}
                    title="Rotate Video"
                  >
                    <RotateVideoIcon />
                  </IconButton>
                  <IconButton
                    classes={{ root: "mirrorCam" }}
                    onClick={() => this.onToggleMirrorCam(f.id)}
                    title="Mirror Camera"
                  >
                    <MirrorIcon />
                  </IconButton>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setVehicles: (values) => {
    //   dispatch({
    //     type: COMMON_ACTIONS.SET_VEHICLES,
    //     values,
    //   });
    // }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(VideoRoom));
