import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
import { connect } from "react-redux";

// React Grid
import { SortingState, IntegratedSorting } from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";

import { API_FireApp } from "../../../apihandler/FireApp";
import { commonJs } from "../../../common/common";

const getRowId = (row) => row.id;

var timer = null;

class IncidentListGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      direction: "asc",
      recs: {
        rows: [],
        filteredRows: [],
        columns: [],
      },
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    this.loadRecords();
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  onSearch = (searchValue) => {
    const { recs } = this.state;
    const { rows, columns } = recs;
    clearTimeout(timer);
    var self = this;
    timer = setTimeout(() => {
      let qry = searchValue.toUpperCase();
      var list = rows.filter((row) => {
        return (
          row.details.toUpperCase().indexOf(qry) > -1 ||
          row.address.toUpperCase().indexOf(qry) > -1 ||
          row.vehicles.toUpperCase().indexOf(qry) > -1
        );
      });
      self.setState({
        recs: { rows: rows, filteredRows: list, columns: columns },
      });
    }, 500);
  };

  generateZoneOptions = (rec) => {
    const { incidentId, incidentDetails, imeis } = rec;
    const { tmpVehicles } = this.props.mapReducer;
    let vlist = [];
    for (var i = 0; i < imeis.length; i++) {
      let v = tmpVehicles.filter((v) => v.imei === imeis[i].imei);
      if (v.length > 0) {
        vlist.push(v[0]);
      }
    }

    return (
      <div className="options">
        <Tooltip
          title="Edit"
          placement="bottom"
          onClick={() => this.props.toggleDialog(rec)}
        >
          <IconButton className="smallIcon">
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Delete"
          placement="bottom"
          onClick={() => this.props.deleteRec(incidentId, incidentDetails)}
        >
          <IconButton className="smallIcon">
            <Delete />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  getStatusHtml = (str, id) => {
    if (str !== "closed") {
      return (
        <a
          href={"/vc?room=" + id}
          target="_blank"
          title="Click here to join video conference"
          rel="noopener noreferrer"
        >
          Open
        </a>
      );
    }
    return str;
  };

  onReload = () => {
    this.setState({
      recs: {
        loading: false,
        sorting: "asc",
        columns: [],
        rows: [],
        filteredRows: [],
      },
    });

    this.loadRecords();
  };

  loadRecords = () => {
    var self = this;
    API_FireApp.getIncidents({
      pageNo: 1,
      pageLength: 1000,
    }).then((res) => {
      if (res.Result === 200) {
        let cols = [
          { name: "slno", title: "Sl No." },
          { name: "details", title: "Details" },
          { name: "address", title: "Address" },
          { name: "vehicles", title: "Assigned Vehicles" },
          { name: "options", title: "Options" },
          { name: "status", title: "Status" },
        ];
        let rows = [];
        res = res.Response;
        const { tmpVehicles } = this.props.mapReducer;
        for (var i in res) {
          let imeis = res[i].imeis;
          let vehicles = [];
          for (var m = 0; m < imeis.length; m++) {
            let vehicle = commonJs.getVehicleFromArray(
              tmpVehicles,
              imeis[m].imei
            );
            if (vehicle.length > 0) {
              vehicles.push(vehicle[0].name + " (" + imeis[m].status + ")");
            } else {
              //TODO: If vehicle is not shared with the user
            }
          }
          rows.push({
            id: res[i].incidentId,
            slno: Number(i) + 1,
            details: res[i].incidentDetails,
            address: res[i].incidentAddress,
            vehicles: vehicles.toString(),
            options: this.generateZoneOptions(res[i]),
            status: this.getStatusHtml(res[i].status, res[i].incidentId),
          });
        }
        self.setState({
          recs: {
            loading: false,
            sorting: "asc",
            columns: cols,
            rows: rows,
            filteredRows: [],
          },
        });
      }
    });
  };

  render() {
    const { rows, columns, filteredRows } = this.state.recs;

    const tableColumnExtensions = [
      { columnName: "slno", width: 60, align: "center" },
      { columnName: "details", width: 250, align: "left" },
      { columnName: "address", width: 250, align: "left" },
      { columnName: "options", width: 80, align: "center" },
      { columnName: "status", width: 80, align: "center" },
    ];

    const tableColumnWidths = [
      { columnName: "slno", width: 60 },
      { columnName: "details", width: 250 },
      { columnName: "address", width: 250 },
      { columnName: "options", width: 80 },
      { columnName: "vehicles", width: 150 },
      { columnName: "status", width: 80 },
    ];

    let searchOn = false;
    if (document.getElementById("incidentSearch") != null) {
      searchOn =
        document.getElementById("incidentSearch").value.trim() === ""
          ? false
          : true;
    }

    return columns.length > 0 ? (
      <div id="incidentListGrid" className="dataRGrid">
        <Grid
          rows={searchOn ? filteredRows : rows}
          columns={columns}
          getRowId={getRowId}
        >
          <SortingState
            defaultSorting={[{ columnName: "name", direction: "asc" }]}
            columnSortingEnabled={true}
            //   onSortingChange={this.changeZoneSort}
            columnExtensions={[
              { columnName: "view", sortingEnabled: false },
              { columnName: "options", sortingEnabled: false },
            ]}
          />
          <IntegratedSorting />
          <VirtualTable
            columnExtensions={tableColumnExtensions}
            height={window.innerHeight - 180}
          />
          <TableColumnResizing defaultColumnWidths={tableColumnWidths} />
          <TableHeaderRow showSortingControls />
        </Grid>
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: 100,
          alignItems: "center",
        }}
      >
        <p style={{ color: "#a1a1a1", fontSize: 14 }}>Loading...</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentListGrid);
