import React, { Component } from "react";
import { Tooltip, Marker } from "react-leaflet";
import L from "leaflet";
import { connect } from "react-redux";

import MapPopup from "../MapPopup";

import { UNITS } from "../../../common/consts";
import { commonJs } from "../../../common/common";

import markerIcon from "../images/marker.png";

class MovingMarker extends Component {
  getMapMarker = (angle = 0) => {
    let markerUrl = markerIcon;
    let markerSize = 16;
    const html =
      "<div class='trackMarker' style='transform: rotate(" +
      angle.toString() +
      "deg);'><img src='" +
      markerUrl +
      "' alt='' width='" +
      markerSize +
      "px'/></div>";
    const customMarker = L.divIcon({
      html: html,
      iconSize: new L.Point(markerSize, markerSize),
      iconAnchor: null,
      popupAnchor: [0, -3],
      className: "trackMarker m-p",
    });
    return customMarker;
  };

  onMarkerClick = (indx) => {
    const { routePoints } = this.props.trackReducer;
    const { latitude, longitude } = routePoints[indx];
    commonJs.getAddress(Number(latitude), Number(longitude)).then((res) => {
      if (document.querySelector(".popupAddress")) {
        document.querySelector(".popupAddress").innerText = res.Response;
      } else {
        setTimeout(() => {
          if (document.querySelector(".popupAddress")) {
            document.querySelector(".popupAddress").innerText = res.Response;
          }
        }, 500);
      }
    });
  };

  render() {
    const { routePoints } = this.props.trackReducer;
    const { trackIndex } = this.props.trackAnimationReducer;
    const { vehicle } = this.props;
    return routePoints[trackIndex]
      ? routePoints.length > 0 && (
          <Marker
            position={[
              routePoints[trackIndex]["latitude"],
              routePoints[trackIndex]["longitude"],
            ]}
            icon={this.getMapMarker(routePoints[trackIndex]["angle"])}
            className="track-marker"
            onclick={() => this.onMarkerClick(trackIndex)}
          >
            <MapPopup
              vehicle={vehicle}
              data={routePoints[trackIndex]}
              datapoint={true}
              indx={trackIndex}
            />
            <Tooltip direction="right" offset={[10, -2]} permanent>
              <span>
                {routePoints[trackIndex]["speed"] + " " + UNITS.SPEED}
              </span>
            </Tooltip>
          </Marker>
        )
      : null;
  }
}

const mapStateToProps = (state) => {
  return {
    trackReducer: state.trackReducer,
    trackAnimationReducer: state.trackAnimationReducer,
  };
};

export default connect(mapStateToProps)(MovingMarker);
