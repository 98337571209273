import React, { Component } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import "./select.css";

class FleetSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.defaultValue,
      open: false
    };
  }

  handleChange = event => {
    const { value } = event.target;
    this.setState({ selected: value });
    this.props.onChange(value);
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  render() {
    const { emptySelect, items, idName } = this.props;
    return (
      <React.Fragment>
        <FormControl className="selectFormControl">
          <InputLabel htmlFor={idName} className="selectPlaceholder">
            {this.props.placeholder}
          </InputLabel>
          <Select
            open={this.state.open}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            value={this.state.selected}
            onChange={this.handleChange}
            inputProps={{
              id: { idName }
            }}
            classes={{
              root: "selectDropdown"
            }}
          >
            {emptySelect && (
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
            )}
            {items.map((item, index) => {
              return (
                <MenuItem value={item.value} key={index}>
                  {item.text}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </React.Fragment>
    );
  }
}

export default FleetSelect;
