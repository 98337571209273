import { PAGE_ACTIONS } from "../actions/PageActions";
import { LOCAL_STORAGE } from "../components/common/consts";
import { commonJs } from "../components/common/common";

let initialState = {
  loading: true,
  loadingPopover: false,
  fullScreen: false,
  leftbar: true,
  leftMapMenu: true,
  leftReportsMenu: false,
  settingsMenu: false,
  selectedVehicle: "",
  vehicleEditPopup: false,
  trackBottomBar: false,
  fireSettingsMenu: false,
  addIncidentDialog: false,
  assignVehicleDialog: false,
  videoCallDialog: "",
  broadcastDialog: false,
};

// Get Page Cache
var pageCache = commonJs.getItemFromLocal(LOCAL_STORAGE.PAGE_SETTINGS);
if (pageCache !== null) {
  if (pageCache.leftbar !== undefined) {
    initialState.leftbar = pageCache.leftbar;
  }
  if (pageCache.leftMapMenu !== undefined) {
    initialState.leftMapMenu = pageCache.leftMapMenu;
  }
  if (pageCache.leftReportsMenu !== undefined) {
    initialState.leftReportsMenu = pageCache.leftReportsMenu;
  }
}

const pageReducer = (state = initialState, action) => {
  const { type, values } = action;
  let pageLocalStorage = commonJs.getItemFromLocal(LOCAL_STORAGE.PAGE_SETTINGS);
  switch (type) {
    case PAGE_ACTIONS.TOGGLE_FULLSCREEN:
      return { ...state, fullScreen: values.fullScreen };

    case PAGE_ACTIONS.TOGGLE_TRACK_BOTTOM_BAR:
      return {
        ...state,
        trackBottomBar: values.trackBottomBar,
      };

    case PAGE_ACTIONS.TOGGLE_LEFTBAR:
      pageLocalStorage.leftbar = values.leftbar;
      commonJs.setItemToLocal(LOCAL_STORAGE.PAGE_SETTINGS, pageLocalStorage);
      return { ...state, leftbar: values.leftbar };

    case PAGE_ACTIONS.TOGGLE_MAP_MENU:
      pageLocalStorage.leftMapMenu = values.leftMapMenu;
      pageLocalStorage.leftReportsMenu = values.leftReportsMenu;
      commonJs.setItemToLocal(LOCAL_STORAGE.PAGE_SETTINGS, pageLocalStorage);
      return {
        ...state,
        leftMapMenu: values.leftMapMenu,
        leftReportsMenu: values.leftReportsMenu,
      };

    case PAGE_ACTIONS.TOGGLE_REPORTS_MENU:
      pageLocalStorage.leftReportsMenu = values.leftReportsMenu;
      commonJs.setItemToLocal(LOCAL_STORAGE.PAGE_SETTINGS, pageLocalStorage);
      return { ...state, leftReportsMenu: values.leftReportsMenu };

    case PAGE_ACTIONS.TOGGLE_SETTINGS_MENU:
      return { ...state, settingsMenu: values.settingsMenu };

    case PAGE_ACTIONS.TOGGLE_FIRESETTINGS_MENU:
      return { ...state, fireSettingsMenu: values.fireSettingsMenu };

    case PAGE_ACTIONS.TOGGLE_ADD_INCIDENT_DIALOG:
      return { ...state, addIncidentDialog: values.addIncidentDialog };

    case PAGE_ACTIONS.TOGGLE_BROADCAST_DIALOG:
      return { ...state, broadcastDialog: values.broadcastDialog };

    case PAGE_ACTIONS.TOGGLE_ASSIGN_VEHICLE_DIALOG:
      return { ...state, assignVehicleDialog: values.assignVehicleDialog };

    case PAGE_ACTIONS.TOGGLE_VIDEO_CALL_DIALOG:
      return { ...state, videoCallDialog: values.videoCallDialog };

    case PAGE_ACTIONS.TOGGLE_DIRECT_VIDEO_CALL_DIALOG:
      return { ...state, directVideoCallDialog: values.directVideoCallDialog };

    case PAGE_ACTIONS.TOGGLE_EDIT_VEHICLE_MENU:
      return {
        ...state,
        vehicleEditPopup: values.vehicleEditPopup,
        selectedVehicle: values.selectedVehicle,
      };

    case PAGE_ACTIONS.SET_MAP_LOADING:
      return { ...state, loading: values.loading };

    case PAGE_ACTIONS.SET_POPOVER_LOADING:
      return { ...state, loadingPopover: values.loadingPopover };
    default:
      break;
  }
  return state;
};

export default pageReducer;
