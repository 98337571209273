import React, { Component } from "react";
import { CONFIG } from "../../common/consts";

class ReportFooter extends Component {
  render() {
    return (
      <div id="reportFooter">
        <p>{CONFIG.SITE_FOOTER}</p>
      </div>
    );
  }
}

export default ReportFooter;
