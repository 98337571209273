export const MAP_ACTIONS = {
  SET_MAP_POSITION: "SET_MAP_POSITION",
  INITIALIZE_MAP: "MapInit",
  SET_TMP_VEHICLES: "SET_TMP_VEHICLES",
  LOAD_VEHICLE_DATA: "LoadVehicleData",
  SELECT_VEHICLE: "SelectVehicle",
  OPEN_LOGOUT_DIALOG: "OpenLogoutDialog",
  SET_FILTERED_VEHICLES: "SetFilteredVehicle",
  SET_MAP_TYPE: "SetMapType",
  SET_FULLSCREEN: "SetFullScreen",
  TOGGLE_FITSCREEN: "TOGGLE_FITSCREEN",
  TOGGLE_MAP_MARKER_DIALOG: "TOGGLE_MAP_MARKER_DIALOG",
  TOGGLE_MAP_SCALE: "TOGGLE_MAP_SCALE",
  TOGGLE_MAP_LABELS: "TOGGLE_MAP_LABELS",
  TOGGLE_MAP_VEHICLES: "TOGGLE_MAP_VEHICLES",
  TOGGLE_MAP_POINTS: "TOGGLE_MAP_POINTS",
  TOGGLE_MAP_ROUTES: "TOGGLE_MAP_ROUTES",
  TOGGLE_MAP_ZONES: "TOGGLE_MAP_ZONES",
  TOGGLE_MAP_CLUSTERS: "TOGGLE_MAP_CLUSTERS",
  TOGGLE_MAP_TRAFFIC: "TOGGLE_MAP_TRAFFIC",
  TOGGLE_MAP_DATA_POINTS: "TOGGLE_MAP_DATA_POINTS",
  SET_SELECTED_VEHICLE: "SET_SELECTED_VEHICLE",
  SET_SIGNALFILTER_STATUS: "SET_SIGNALFILTER_STATUS",
  SET_LOADFILTER_STATUS: "SET_LOADFILTER_STATUS",
  RESET_VEHICLES: "RESET_VEHICLES",
  TOGGLE_POI_CONTROL: "TOGGLE_POI_CONTROL",
  TOGGLE_MARKER_ANIMATION: "TOGGLE_MARKER_ANIMATION",
};
