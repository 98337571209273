import React, { Component } from "react";
import { InputBase, IconButton, Tooltip } from "@material-ui/core";

import Img from "../image/Img";
import SearchIcon from "./images/search.svg";

import "./style.css";

class SearchButton extends Component {
  render() {
    const {
      id,
      inputClass,
      placeholder,
      onChange,
      buttonClass,
      iconwidth,
      iconheight
    } = this.props;

    return (
      <React.Fragment>
        <InputBase
          id={id}
          classes={{ root: "width100", input: inputClass }}
          placeholder={placeholder}
          onChange={onChange}
          autoComplete="off"
          autoFocus={true}
          defaultValue={""}
          onMouseDown={e => {
            e.stopPropagation();
          }}
        />
        <Tooltip title="Search">
          <IconButton
            className={buttonClass}
            aria-label="Search"
            onClick={onChange}
          >
            <Img src={SearchIcon} width={iconwidth} height={iconheight} />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default SearchButton;
