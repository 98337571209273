import { reactLocalStorage } from "reactjs-localstorage";
import { SdkClient } from "fgxsdk";
import jQuery from "jquery";

import { NOTIFY, UNITS, CONFIG, LOCAL_STORAGE, DEFAULTS } from "./consts";
import { API_UserLogin } from "../apihandler/UserLogin";

SdkClient.setUrl(CONFIG.BASE_URL);

var $ = jQuery;

export const commonJs = {
  turnOffLocalCamera: () => {
    let videoElem = document.getElementById("video1");
    if (videoElem) {
      const stream = videoElem.srcObject;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      }
      videoElem.srcObject = null;
    }
  },

  callSomeone: (props) => {},

  getConvertedVertices: (vertices) => {
    let conVertices = [];
    vertices.map((v, indx) => {
      conVertices.push([v.h, v.v]);
      return true;
    });
    return conVertices;
  },

  getVehicleList: (vlist, filteredList) => {
    var vehicleList = vlist;
    if (filteredList !== undefined) {
      if (filteredList.length > 0) {
        vehicleList = [];
        filteredList.map((v2) => {
          let flist = vlist.filter((v1) => {
            return v2.imei === v1.imei;
          });
          if (flist.length > 0) {
            vehicleList.push(flist[0]);
          }
          return true;
        });
      }
    }
    return vehicleList;
  },

  getVehicleCount: (vlist) => {
    var vcount = vlist.length;
    if (vcount > 0) {
      if (vlist[0].status === undefined) {
        vcount = 0;
      }
    }
    return vcount;
  },

  getVehicleFilteredList: (vlist, signalFilter, loadStatusFilter) => {
    let vehicles = [];
    var vcount = vlist.length;
    if (vcount > 0) {
      if (vlist[0].status === undefined) {
        vcount = 0;
      }
    }
    if (vcount > 0) {
      for (var v = 0; v < vcount; v++) {
        let vehicle = vlist[v];
        let showVehicle = false;
        if (signalFilter === 0 && loadStatusFilter === 0) {
          // Show All
          showVehicle = true;
        } else if (signalFilter !== 0 && loadStatusFilter !== 0) {
          // Both has filters
          if (signalFilter === vehicle.status + 1) {
            if (
              DEFAULTS.DEFAULT_VEHICLE_LOAD_STATUS_LIST[loadStatusFilter] ===
              DEFAULTS.DEFAULT_VEHICLE_LOAD_STATUS_LIST[1]
            ) {
              if (vehicle.loadstatus === "free") {
                showVehicle = true;
              }
            } else if (
              DEFAULTS.DEFAULT_VEHICLE_LOAD_STATUS_LIST[loadStatusFilter] ===
              DEFAULTS.DEFAULT_VEHICLE_LOAD_STATUS_LIST[2]
            ) {
              if (vehicle.loadstatus === "busy") {
                showVehicle = true;
              }
            }
          }
        } else {
          if (signalFilter === vehicle.status + 1) {
            showVehicle = true;
          } else if (
            DEFAULTS.DEFAULT_VEHICLE_LOAD_STATUS_LIST[loadStatusFilter] ===
            DEFAULTS.DEFAULT_VEHICLE_LOAD_STATUS_LIST[1]
          ) {
            if (vehicle.loadstatus === "free") {
              showVehicle = true;
            }
          } else if (
            DEFAULTS.DEFAULT_VEHICLE_LOAD_STATUS_LIST[loadStatusFilter] ===
            DEFAULTS.DEFAULT_VEHICLE_LOAD_STATUS_LIST[2]
          ) {
            if (vehicle.loadstatus === "busy") {
              showVehicle = true;
            }
          }
        }
        if (showVehicle) {
          vehicles.push(vehicle);
        }
      }
    }
    return vehicles;
  },

  getVehicleStatus: (vehicle) => {
    let vstatus = "nosignal";
    let vstatusText = "NO DATA";
    if (vehicle.status === 3) {
      vstatus = "running";
      vstatusText = vehicle.speed.toFixed(1) + " " + UNITS.SPEED;
    } else if (vehicle.status === 2) {
      vstatus = "idle";
      vstatusText = "IDLE";
    } else if (vehicle.status === 1) {
      vstatus = "stopped";
      vstatusText = "STOP";
    }
    return { vstatus, vstatusText };
  },

  getVehicleIgnition: (vehicle, params = undefined) => {
    let vehicleIgn = null;
    if (params !== undefined) {
      if (params.power !== undefined) {
        vehicleIgn = params.power;
      } else if (params.ignition !== undefined) {
        vehicleIgn = params.ignition;
      }
    } else if (vehicle.gps !== 0 || vehicle.gprs !== 0) {
      if (vehicle.power !== undefined) {
        if (vehicle.power <= 1) {
          vehicleIgn = vehicle.power;
        } else if (vehicle.ignition !== undefined) {
          vehicleIgn = vehicle.ignition;
        }
      } else if (vehicle.ignition !== undefined) {
        vehicleIgn = vehicle.ignition;
      }
    }
    return vehicleIgn;
  },

  getVehicleSensors: (sensors, params = undefined) => {
    let sensorsList = [];
    if (sensors.length > 0) {
      for (var i in sensors) {
        if (params !== undefined) {
          if (params[sensors[i].param]) {
            sensors[i].value = params[sensors[i].param];
          }
        }
        let temps = sensors[i].sensorType.split("temp");
        if (temps.length > 1) {
          let units = sensors[i].units;
          if (units === "" || units === "C") {
            units = UNITS.TEMP;
          }
          let tmp = {
            type: "temp",
            name: sensors[i].name,
            value: sensors[i].value.toFixed(1) + " " + units,
          };
          sensorsList.push(tmp);
        } else if (sensors[i].sensorType === "fuel") {
          let calibration = sensors[i].calibration;
          let fc = sensors[i].fuelcapacity;
          let fv = sensors[i].fuelvoltage;
          let fuelval = commonJs.getFuelValue({
            value: sensors[i].value,
            calibration: calibration,
            fuelcapacity: fc,
            fuelvoltage: fv,
          });
          let units = sensors[i].units;
          if (units === "") {
            units = UNITS.FUEL;
          }
          let tmp = {
            type: "fuel",
            name: sensors[i].name,
            value: fuelval + " " + units,
          };
          sensorsList.push(tmp);
        } else if (sensors[i].sensorType === "humidity") {
          let tmp = {
            type: "humidity",
            name: sensors[i].name,
            value: sensors[i].value + " " + sensors[i].units,
          };
          sensorsList.push(tmp);
        } else if (sensors[i].sensorType === "alcohol") {
          let tmp = {
            type: "alcohol",
            name: sensors[i].name,
            value: sensors[i].value + " " + sensors[i].units,
          };
          sensorsList.push(tmp);
        } else {
          let tmp = {
            type: "other",
            name: sensors[i].name,
            value: Number(sensors[i].value).toFixed(1) + " " + sensors[i].units,
          };
          sensorsList.push(tmp);
        }
      }
    }
    return sensorsList;
  },

  getVehicleData: (vehicles, imei) => {
    const vehicle = vehicles.filter((v) => {
      return v.imei === imei;
    });
    return vehicle;
  },

  openFullScreen: () => {
    var elem = document.getElementById("root");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  },

  exitFullScreen: () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  },

  isFullScreen: () => {
    if (!(window.innerHeight !== window.screen.height)) {
      return true;
    } else {
      return false;
    }
  },

  logout: () => {
    API_UserLogin.logout()
      .then((res) => {
        //TODO: Clear cache && reset login
        commonJs.setItemToLocal(LOCAL_STORAGE.MAP, null);
        window.location = "/";
      })
      .catch((err) => {
        window.location = "/";
      });
  },

  showNotify: function(props, variant, text, action, autoHideDuration) {
    props.enqueueSnackbar(text, {
      variant: variant,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      action: action,
      autoHideDuration: autoHideDuration || NOTIFY.AUTO_HIDE_DURATION,
    });
  },

  getItemFromLocal: (itemname) => {
    try {
      const item = reactLocalStorage.getObject(itemname);
      return item;
    } catch (err) {
      return;
    }
  },

  setItemToLocal: (itemname, value) => {
    reactLocalStorage.setObject(itemname, value);
  },

  getFuelValue: (sensordata) => {
    let actualValue = sensordata.value;
    let calibration = JSON.parse(sensordata.calibration);
    let fuelcapacity = sensordata.fuelcapacity;
    let fuelvoltage = sensordata.fuelvoltage;
    if (!isNaN(actualValue)) {
      actualValue = Number(actualValue);
      var fvalue = actualValue / 1000;
      if (calibration.length > 0) {
        let prevX = 0;
        let prevY = 0;
        let cX = 0;
        let cY = 0;
        for (var i in calibration) {
          cX = Number(calibration[i].x);
          cY = Number(calibration[i].y);
          if (fvalue < cY) {
            break;
          }
          prevX = cX;
          prevY = cY;
        }
        var prevSum = 0;
        if (prevX > 0 || prevY > 0) {
          prevSum = prevX / prevY;
        }
        var sum = 0;
        if (cX > 0 || cY > 0) {
          sum = cX / cY;
        }
        fvalue = (fvalue * (prevSum + sum)) / 2;
        fvalue = commonJs.getTwoDecimalValue(fvalue);
      } else if (fuelcapacity > 0 && fuelvoltage > 0) {
        var fc = Number(fuelcapacity);
        var fv = Number(fuelvoltage);
        if (!isNaN(fvalue) && !isNaN(fc) && !isNaN(fv)) {
          if (fvalue !== 0.174) {
            // Ignition Off
            fvalue = fvalue / 1000;
            fvalue = (fv - fvalue) / fv;
            fvalue = (fvalue * fc).toFixed(2);
            fvalue = commonJs.getTwoDecimalValue(fvalue);
          } else {
            fvalue = "N/A";
          }
        } else {
          fvalue = "N/A";
        }
      } else {
        fvalue = Number(actualValue);
      }
    } else {
      fvalue = "N/A";
    }
    return fvalue;
  },

  isTempVehicle: (sensors) => {
    if (sensors.length > 0) {
      let isTemp = sensors.filter((val) => {
        return val.sensorType.indexOf("temp") > -1;
      });
      if (isTemp.length > 0) {
        return true;
      }
    }
    return false;
  },

  isFuelVehicle: (sensors) => {
    if (sensors.length > 0) {
      let isFuel = sensors.filter((val) => {
        return val.sensorType.indexOf("fuel") > -1;
      });
      if (isFuel.length > 0) {
        return true;
      }
    }
    return false;
  },

  getAddress: async (lat, lng) => {
    return SdkClient.getAddress({ lat: lat, lng: lng })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  /* Finds the distance between two map points, returns as string */
  getDistance: function(p1, p2) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = this.toRad(p2.lat - p1.lat);
    var dLng = this.toRad(p2.lng - p1.lng);
    var lat1 = this.toRad(p1.lat);
    var lat2 = this.toRad(p2.lat);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var dist = (R * c) / 1000; // In Kms
    return this.getTwoDecimalValue(dist);
  },

  /* Finds the midpoint of the Polygon, returns as boolean*/
  getMidPointofPolygon: function(vertices) {
    var sumX = 0;
    var sumY = 0;
    var count = 0;
    for (var i = 0; i < vertices.length; i++) {
      sumX += vertices[i].latitude;
      sumY += vertices[i].longitude;
      count++;
    }
    var pos = {
      latitude: sumX / count,
      longitude: sumY / count,
    };
    return pos;
  },

  /* Converts "degree" to "radians", returns as number */
  toRad: function(degree) {
    return (degree * Math.PI) / 180;
  },

  /* Converts "radians" to "degree", returns as number */
  toDeg: function(radian) {
    return (radian * 180) / Math.PI;
  },

  /* Converts "number" to two decimal points, returns as string */
  getTwoDecimalValue: (val) => {
    var intval = Math.floor(val);
    var deci = val - Math.floor(val);
    if (deci === 0) {
      return intval.toString() + ".00";
    } else {
      deci = deci.toFixed(2).toString();
      deci = deci.substr(1, deci.length);
      return intval + deci;
    }
  },

  /* Checks the IMEI valid or not, returns as boolean */
  isIMEIValid: function(imei) {
    var RegExp = /^[a-zA-Z0-9]{1,15}$/;
    if (imei.match(RegExp)) {
      return true;
    }
    return false;
  },

  /* Checks the "point" is in the Polygon, returns as boolean */
  isPointInPolygon: function(poly, pt) {
    for (var c = false, i = -1, l = poly.length, j = l - 1; ++i < l; j = i)
      ((poly[i].y <= pt.y && pt.y < poly[j].y) ||
        (poly[j].y <= pt.y && pt.y < poly[i].y)) &&
        pt.x <
          ((poly[j].x - poly[i].x) * (pt.y - poly[i].y)) /
            (poly[j].y - poly[i].y) +
            poly[i].x &&
        (c = !c);
    return c;
  },

  /* Checks the "number" valid or not, returns as boolean */
  isNumberValid: function(value) {
    var er = /^\d+(\.\d+)?$/;
    return er.test(value) ? true : false;
  },

  /* Checks the "email" valid or not, returns boolean */
  isEmailValid: function(email) {
    var RegExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (email.match(RegExp)) {
      return true;
    }
    return false;
  },

  getRandomInt: (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  },

  getVehicleParamUnits: (sensors, param) => {
    let units = "";
    if (param === "temp") {
      units = UNITS.TEMP;
      return units;
    }
    let sensor = sensors.filter((s) => s.param === param);
    if (sensor.length > 0) {
      units = sensor[0]["units"];
    }
    return units;
  },

  // Formula Calculations
  calcString: (str) => {
    var result = 0;
    try {
      str = str.trim();
      str = str.replace(/[^-()\d/*+.]/g, "");
      result += str;
      return result;
    } catch (err) {
      return result;
    }
  },

  sortNumber: (a, b) => {
    return a - b;
  },

  getSensorValue: (sensor = {}, actualValue = 0) => {
    let rslt = actualValue;
    if (sensor.result_type === "logic") {
      rslt = actualValue;
    } else if (sensor.result_type === "value") {
      rslt = parseFloat(actualValue);
      if (sensor.param === "adc1" && sensor.sensorType === "fuel") {
        // Only for fuel
        let sensordata = sensor;
        sensordata.value = rslt;
        rslt = commonJs.getFuelValue(sensordata);
        return rslt;
      }
      if (sensor.formula !== "") {
        var formula = sensor.formula.toLowerCase();
        formula = formula.replace("x", actualValue);
        actualValue = commonJs.calcString(formula);
      }
      // calibration
      var out_of_cal = true;
      var calibration = sensor.calibration;
      // function to get calibration Y value
      var calGetY = function(x) {
        var result = 0;
        for (var j = 0; j < calibration.length; j++) {
          if (calibration[j]["x"] === x) {
            result = parseFloat(calibration[j]["y"]);
          }
        }
        return result;
      };
      if (calibration.length >= 2) {
        // put all X values to separate array
        var x_arr = [];
        for (let i = 0; i < calibration.length; i++) {
          x_arr.push(parseFloat(calibration[i]["x"]));
        }
        x_arr.sort(commonJs.sortNumber);
        // loop and check if in cal
        for (let i = 0; i < x_arr.length; i++) {
          let x_low = x_arr[i];
          let x_high = x_arr[i + 1];
          if (actualValue >= x_low && actualValue <= x_high) {
            // get Y low and high
            var y_low = calGetY(x_low);
            var y_high = calGetY(x_high);
            // get coeficient
            let a = actualValue - x_low;
            let b = x_high - x_low;
            var coef = a / b;
            var c = y_high - y_low;
            coef = c * coef;
            actualValue = y_low + coef;
            out_of_cal = false;
            break;
          }
        }
        if (out_of_cal) {
          // check if lower than cal
          let x_low = x_arr[0];
          if (actualValue < x_low) {
            actualValue = calGetY(x_low);
          }
          // check if higher than cal
          let x_high = x_arr.slice(-1)[0];
          if (actualValue > x_high) {
            actualValue = calGetY(x_high);
          }
        }
      }
      rslt = Math.round(rslt * 100) / 100;
    } else if (sensor.result_type === "string") {
      rslt = actualValue;
    } else if (sensor.result_type === "percentage") {
      actualValue = parseFloat(actualValue);
      sensor.lv = parseFloat(sensor.lv);
      sensor.hv = parseFloat(sensor.hv);
      if (actualValue > sensor.lv && actualValue < sensor.hv) {
        let a = actualValue - sensor.lv;
        let b = sensor.hv - sensor.lv;
        rslt = Math.round((a / b) * 100);
      } else if (actualValue <= sensor.lv) {
        rslt = 0;
      } else if (actualValue >= sensor.hv) {
        rslt = 100;
      }
    }
    return rslt;
  },

  getVehicleFromArray: (vehicles, selectedImei) => {
    return vehicles.filter((v) => v.imei === selectedImei);
  },

  getDefaultSensorUnit: (sensorType) => {
    let sensorUnits = "";
    if (sensorType === "temp") {
      sensorUnits = UNITS.TEMP;
    } else if (sensorType === "fuel") {
      sensorUnits = UNITS.FUEL;
    }
    return sensorUnits;
  },

  getUniqueArray: (arr) => {
    let list = [];
    $.each(arr, function(i, el) {
      if ($.inArray(el, list) === -1) list.push(el);
    });
    return list;
  },
};
