import React, { Component } from "react";
import { connect } from "react-redux";
import { UNITS } from "../../../common/consts";

class TrackInfo extends Component {
  render() {
    const { trackIndex } = this.props.trackAnimationReducer;
    const { routePoints } = this.props.trackReducer;
    return routePoints[trackIndex]
      ? routePoints.length > 0 && (
          <div className="trackInfo">
            <p>
              {routePoints[trackIndex]["time"] +
                " | " +
                routePoints[trackIndex]["speed"] +
                " " +
                UNITS.SPEED}
            </p>
          </div>
        )
      : null;
  }
}

const mapStateToProps = (state) => {
  return {
    trackAnimationReducer: state.trackAnimationReducer,
    trackReducer: state.trackReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackInfo);
