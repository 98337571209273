export const PAGE_ACTIONS = {
  TOGGLE_FULLSCREEN: "TOGGLE_FULLSCREEN",
  TOGGLE_LEFTBAR: "TOGGLE_LEFTBAR",
  TOGGLE_MAP_MENU: "TOGGLE_MAP_MENU",
  TOGGLE_REPORTS_MENU: "TOGGLE_REPORTS_MENU",
  TOGGLE_SETTINGS_MENU: "TOGGLE_SETTINGS_MENU",
  TOGGLE_FIRESETTINGS_MENU: "TOGGLE_FIRESETTINGS_MENU",
  TOGGLE_EDIT_VEHICLE_MENU: "TOGGLE_EDIT_VEHICLE_MENU",
  SET_MAP_LOADING: "SET_MAP_LOADING",
  SET_POPOVER_LOADING: "SET_POPOVER_LOADING",
  TOGGLE_TRACK_BOTTOM_BAR: "TOGGLE_TRACK_BOTTOM_BAR",
  TOGGLE_BROADCAST_DIALOG: "TOGGLE_BROADCAST_DIALOG",
  TOGGLE_ADD_INCIDENT_DIALOG: "TOGGLE_ADD_INCIDENT_DIALOG",
  TOGGLE_ASSIGN_VEHICLE_DIALOG: "TOGGLE_ASSIGN_VEHICLE_DIALOG",
  TOGGLE_VIDEO_CALL_DIALOG: "TOGGLE_VIDEO_CALL_DIALOG",
  TOGGLE_DIRECT_VIDEO_CALL_DIALOG: "TOGGLE_DIRECT_VIDEO_CALL_DIALOG"
};
