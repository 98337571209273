import React, { Component } from "react";
import { connect } from "react-redux";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

// Custom CSS
import "./styles/fleetmap.css";
import { DEFAULTS } from "../../common/consts";
import { FIREAPP_ACTIONS } from "../../../actions/FireAppActions";
import { commonJs } from "../../common/common";

class AddressAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    this.setState({
      address: address,
    });
    var self = this;
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        self.props.addIncidentLocation(
          latLng.lat,
          latLng.lng,
          DEFAULTS.GOOGLE_SEARCH_MAX_ZOOM
        );
        self.props.setIncidentLocation({ latLng, address });
      })
      .catch((error) => console.error("Error", error));
  };

  setLatLng = (latLng) => {
    var self = this;
    this.props.setIncidentLocation({
      latLng: { lat: latLng.lat, lng: latLng.lng },
      address: "",
    });
    commonJs.getAddress(latLng.lat, latLng.lng).then((res) => {
      let incidentRec = {
        latLng: { lat: latLng.lat, lng: latLng.lng },
        incidentAddress: res.Response,
      };
      self.setState({
        address: res.Response,
      });
      self.props.setIncidentAddress({
        address: res.Response,
        incidentRec: incidentRec,
      });
      if (document.getElementById("incidentAddress")) {
        document.getElementById("incidentAddress").value = res.Response;
      }
    });
  };

  render() {
    return (
      <div id="addressAutoComplete">
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          searchOptions={{ componentRestrictions: { country: ["in"] } }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: "Search Places ...",
                  className: "location-search-input",
                })}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div style={{ fontSize: 12 }}>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fireReducer: state.fireReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIncidentLocation: (values) => {
      dispatch({
        type: FIREAPP_ACTIONS.SET_INCIDENT_LOCATION,
        values,
      });
    },
    setIncidentLatLng: (values) => {
      dispatch({
        type: FIREAPP_ACTIONS.SET_INCIDENT_LATLNG,
        values,
      });
    },
    setIncidentAddress: (values) => {
      dispatch({
        type: FIREAPP_ACTIONS.SET_INCIDENT_ADDRESS,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressAutoComplete);
