import React, { Component } from "react";
import { IconButton } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

// React Grid
import { SortingState, IntegratedSorting } from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

import { API_FireApp } from "../../../../apihandler/FireApp";
import { PAGE_ACTIONS } from "../../../../../actions/PageActions";
import { commonJs } from "../../../../common/common";
import { LOCAL_STORAGE } from "../../../../common/consts";

const getRowId = (row) => row.id;

var timer = null;

class UsersGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      direction: "asc",
      records: {
        rows: [],
        filteredRows: [],
        columns: [],
      },
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    this.loadRecords();
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  onSearch = (searchValue) => {
    const { records } = this.state;
    const { rows, columns } = records;
    clearTimeout(timer);
    var self = this;
    timer = setTimeout(() => {
      let qry = searchValue.toUpperCase();
      var list = rows.filter((row) => {
        return row.name.toUpperCase().indexOf(qry) > -1;
      });
      self.setState({
        records: { rows: rows, filteredRows: list, columns: columns },
      });
    }, 500);
  };

  getZoneOnFilter = (id) => {
    const { records } = this.state;
    const { rows } = records;
    return rows.filter((r) => r.id === id);
  };

  initiateCall = (username) => {
    commonJs.setItemToLocal(LOCAL_STORAGE.CALL_USER, username);
    this.props.toggleDialog({
      videoCallDialog: "md",
    });
  };

  generateOptions = (rec) => {
    const { username } = rec;
    return (
      <div className="options userOptions">
        <IconButton
          className="smallIcon callBtn"
          onClick={() => this.initiateCall(username)}
        >
          <h4>Call</h4>
        </IconButton>
      </div>
    );
  };

  loadReloadRecords = () => {
    this.setState({
      records: {
        loading: false,
        sorting: "asc",
        columns: [],
        rows: [],
        filteredRows: [],
      },
    });

    this.loadRecords();
  };

  loadRecords = () => {
    var self = this;
    API_FireApp.getUsers().then((res) => {
      if (res.Result === 200) {
        let cols = [
          { name: "name", title: "Name" },
          // { name: "status", title: "Status" },
          { name: "options", title: "Options" },
        ];
        let rows = [];
        res = res.Response;
        for (var i in res) {
          rows.push({
            id: res[i].username,
            name: res[i].username,
            // status: "offline",
            options: this.generateOptions(res[i]),
          });
        }

        // If there is any search string
        let qry = "";
        if (document.getElementById("searchUser")) {
          qry = document.getElementById("searchUser").value.toUpperCase();
        }
        var list = rows.filter((row) => {
          return row.name.toUpperCase().indexOf(qry) > -1;
        });

        self.setState({
          records: {
            loading: false,
            sorting: "asc",
            columns: cols,
            rows: rows,
            filteredRows: list,
          },
        });
      }
    });
  };

  render() {
    const { rows, columns, filteredRows } = this.state.records;

    const tableColumnExtensions = [
      { columnName: "name", width: 180, align: "left" },
      // { columnName: "status", width: 80, align: "center" },
      { columnName: "options", width: 120, align: "center" },
    ];

    let searchOn = false;
    if (document.getElementById("searchUser") != null) {
      searchOn =
        document.getElementById("searchUser").value.trim() === ""
          ? false
          : true;
    }

    return columns.length > 0 ? (
      <div id="fireUsersGrid" className="dataRGrid">
        <Grid
          rows={searchOn ? filteredRows : rows}
          columns={columns}
          getRowId={getRowId}
        >
          <SortingState
            defaultSorting={[{ columnName: "name", direction: "asc" }]}
            columnSortingEnabled={true}
            //   onSortingChange={this.changeZoneSort}
            columnExtensions={[
              { columnName: "view", sortingEnabled: false },
              { columnName: "options", sortingEnabled: false },
            ]}
          />
          <IntegratedSorting />
          <VirtualTable
            columnExtensions={tableColumnExtensions}
            height={window.innerHeight - 180}
          />
          <TableHeaderRow showSortingControls />
        </Grid>
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: 100,
          alignItems: "center",
        }}
      >
        <p style={{ color: "#a1a1a1", fontSize: 14 }}>Loading...</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageReducer: state.pageReducer,
    fireReducer: state.fireReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDialog: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_VIDEO_CALL_DIALOG,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(UsersGrid));
