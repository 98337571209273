import React, { Component } from "react";
import { connect } from "react-redux";

import TopProgressBar from "../ui/progressbar/TopProgressBar";
import Loading from "../ui/loading/Loading";

class LoadingDiv extends Component {
  render() {
    const { loading, loadingPopover } = this.props.pageReducer;
    return (
      <React.Fragment>
        <TopProgressBar className={loading ? "showprogress" : "hideprogress"} />
        {(loading || loadingPopover) && <Loading>Please wait...</Loading>}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    pageReducer: state.pageReducer
  };
};

export default connect(mapStateToProps)(LoadingDiv);
