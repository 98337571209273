import React, { Component } from "react";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";

import FleetChip from "../../../../ui/chips/FleetChip";
import FleetSelect from "../../../../ui/select/FleetSelect";
import Btn from "../../../../ui/button/Btn";
import TrackTimeline from "./tracktimeline/TrackTimeline";

import { ArrowForwardIos as ArrowForward } from "@material-ui/icons";

import { dateFunction } from "../../../../common/datefunctions";

import "./track.css";

import AutoComplete from "../../../../ui/autocomplete/AutoComplete";
import UiDatePicker from "../../../../ui/uidatepicker/UiDatePicker";
import { TRACK_ACTIONS } from "../../../../../actions/TrackActions";
import { DEFAULTS, FORMATS, NOTIFY, ALERTS } from "../../../../common/consts";
import { MAP_ACTIONS } from "../../../../../actions/MapActions";
import { API_Map } from "../../../../apihandler/Map";
import { commonJs } from "../../../../common/common";
import { PAGE_ACTIONS } from "../../../../../actions/PageActions";

const DefaultDismissBtn = () => {
  return <button className="snackbar-btn">{"Dismiss"}</button>;
};

class TrackTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRecord: false,
      showKm: false,
      activeChip: 7,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  onFilterTrackDuration = (type) => {
    const { frmval, toval } = dateFunction.getFromToVal(type, FORMATS.SQL_DATE);
    this.props.setTrackDate({
      fdt: frmval,
      tdt: toval,
    });
    this.props.setSelectedTrackDuration({ selectedTrackDuration: type });
  };

  showTrack = () => {
    const { fdt, tdt, stopTime } = this.props.trackReducer;
    const { selectedVehicle } = this.props.mapReducer;
    const args = {
      imei: selectedVehicle,
      fromdate: fdt,
      todate: tdt,
      stoptime: stopTime,
    };
    if (args.imei === "") {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        ALERTS.PLEASE_SELECT_VEHICLE,
        <DefaultDismissBtn />
      );
      return;
    }
    this.props.setLoading({
      loadingPopover: true,
    });
    var self = this;
    API_Map.getVehicleTrack(args)
      .then((res) => {
        const { Result, Response } = res;
        self.props.setLoading({
          loadingPopover: false,
        });
        if (Result === 200 && Response) {
          const { route_details, route_points, route_summary } = Response;
          self.props.setTrackData({
            routeDetails: route_details,
            routePoints: route_points,
            routeSummary: route_summary,
          });
          if (route_points.length > 0) {
            // self.props.updateMapRegion(
            //   route_points[0].latitude,
            //   route_points[0].longitude,
            //   DEFAULTS.TRACK_ZOOM
            // );
            if (!self.props.pageReducer.trackBottomBar) {
              self.props.toggleBottomBar({ trackBottomBar: true });
            }
            // Fit Bounds
            self.props.trackFitBounds();
          } else {
            self.props.setLoading({
              loadingPopover: false,
            });
            commonJs.showNotify(
              self.props,
              NOTIFY.WARNING,
              ALERTS.NO_RECORDS,
              <DefaultDismissBtn />
            );
          }
        } else {
          self.props.setLoading({
            loadingPopover: false,
          });
          commonJs.showNotify(
            self.props,
            NOTIFY.WARNING,
            res.Reason,
            <DefaultDismissBtn />
          );
        }
      })
      .catch((err) => {
        console.log("track: ", err);
        self.props.setLoading({
          loadingPopover: false,
        });
        commonJs.showNotify(
          self.props,
          NOTIFY.WARNING,
          ALERTS.INTERNAL_SERVER_ERROR,
          <DefaultDismissBtn />
        );
      });
  };

  showKmTravelled = () => {
    const { fdt, tdt, stopTime } = this.props.trackReducer;
    const { selectedVehicle } = this.props.mapReducer;
    const { uid } = this.props.loginReducer;
    const args = {
      imei: selectedVehicle,
      uid,
      fdt,
      tdt,
      stopTime,
    };

    if (args.imei === "") {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        ALERTS.PLEASE_SELECT_VEHICLE,
        <DefaultDismissBtn />
      );
      return;
    }

    localStorage.filter = JSON.stringify(args);
    window.open("/reports/kmtravelled", "_blank");
  };

  onChangeVehicle = (value) => {
    if (value !== null) {
      this.props.setTrackData({
        routeDetails: {},
        routePoints: [],
        routeSummary: [],
      });
      this.props.setSelectedVehicle({
        selectedVehicle: value.id,
      });
    }
  };

  handleDateChange = (type, dt) => {
    if (type === "from") {
      this.props.setTrackFromDate({
        dt: dt,
      });
    } else {
      this.props.setTrackToDate({
        dt: dt,
      });
    }
  };

  onChangeStoptime = (value) => {
    this.props.setTrackStoptime({
      stopTime: value,
    });
  };

  render() {
    const {
      selectedTrackDuration,
      fdt,
      tdt,
      routeSummary,
    } = this.props.trackReducer;

    const { tmpVehicles, selectedVehicle } = this.props.mapReducer;

    const { trackBottomBar } = this.props.pageReducer;

    let vehicles = tmpVehicles.map((v) => {
      return { id: v.imei, name: v.name };
    });

    const chips = DEFAULTS.TRACK_DURATIONS;

    var vehicle = tmpVehicles.filter((v) => {
      return v.imei === selectedVehicle;
    });

    if (vehicle.length > 0) {
      vehicle = {
        id: vehicle[0].imei,
        name: vehicle[0].name,
      };
    }

    return (
      <div className="trackVehicle">
        <AutoComplete
          label={"Select Vehicle"}
          list={vehicles}
          id="trackVehicles"
          style={{ marginBottom: 15, marginTop: 15 }}
          onChange={this.onChangeVehicle}
          defaultValue={vehicle}
          multiple={false}
          size="small"
        />
        <div className="uidatepicker">
          <UiDatePicker
            label="From Date :"
            dt={fdt}
            type="from"
            disablePast={false}
            disableFuture={true}
            disabled={false}
            datepicker={false}
            // maxDate={dateFunction.getDate("thismonth", "to")}
            handleDateChange={this.handleDateChange}
          />
          <div
            style={{
              width: "15%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ArrowForward />
          </div>
          <UiDatePicker
            label="To Date :"
            dt={tdt}
            type="to"
            disablePast={false}
            disableFuture={true}
            disabled={false}
            // maxDate={dateFunction.getDate("thismonth", "to")}
            handleDateChange={this.handleDateChange}
          />
        </div>

        <p className="label bold mB-10">Select track duration: </p>
        {chips.map((chip, index) => {
          return (
            <FleetChip
              label={chip.title}
              className={
                "chip" + (selectedTrackDuration === chip.value ? " active" : "")
              }
              onClick={() => this.onFilterTrackDuration(chip.value)}
              key={"chip-" + index}
            />
          );
        })}

        <div id="stopsDiv">
          <div>
            <p className="label stopsLabel bold">Stops: </p>
            <FleetSelect
              idName="trackStops"
              emptySelect={false}
              defaultValue={DEFAULTS.DEFAULT_TRACK_STOPTIME}
              items={DEFAULTS.TRACK_STOPTIMES}
              onChange={this.onChangeStoptime}
            />
          </div>
          <div className="trackBtn">
            <Btn
              onClick={this.showTrack}
              className={this.state.showRecord ? "active" : ""}
            >
              Show Track
            </Btn>
            <Btn
              onClick={() =>
                this.props.toggleBottomBar({
                  trackBottomBar: !trackBottomBar,
                })
              }
              className={this.state.showKm ? "active" : ""}
            >
              {trackBottomBar ? "Hide " : "Show "} Graph
            </Btn>
            {/* <Btn
              onClick={this.showKmTravelled}
              className={this.state.showKm ? "active" : ""}
            >
              KMs Travelled
            </Btn> */}
          </div>
        </div>

        <div id="trackTimeline">
          <TrackTimeline routeSummary={routeSummary} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trackReducer: state.trackReducer,
    mapReducer: state.mapReducer,
    loginReducer: state.loginReducer,
    pageReducer: state.pageReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleBottomBar: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_TRACK_BOTTOM_BAR,
        values,
      });
    },
    setSelectedTrackDuration: (values) => {
      dispatch({
        type: TRACK_ACTIONS.SET_SELECTED_TRACK_DURATION,
        values,
      });
    },
    setTrackDate: (values) => {
      dispatch({
        type: TRACK_ACTIONS.SET_TRACK_DATE,
        values,
      });
    },
    setTrackFromDate: (values) => {
      dispatch({
        type: TRACK_ACTIONS.SET_TRACK_FROM_DATE,
        values,
      });
    },
    setTrackToDate: (values) => {
      dispatch({
        type: TRACK_ACTIONS.SET_TRACK_TO_DATE,
        values,
      });
    },
    setTrackStoptime: (values) => {
      dispatch({
        type: TRACK_ACTIONS.SET_TRACK_STOP_TIME,
        values,
      });
    },
    setSelectedVehicle: (values) => {
      dispatch({
        type: MAP_ACTIONS.SELECT_VEHICLE,
        values,
      });
    },
    setTrackData: (values) => {
      dispatch({
        type: TRACK_ACTIONS.SET_TRACK_DATA,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_POPOVER_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(TrackTab));
