import React, { Component } from "react";
import {
  List,
  ListItem,
  IconButton,
  Tooltip,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ClickAwayListener,
} from "@material-ui/core";
import { PlaylistAdd, MailOutline as SendMsgIcon } from "@material-ui/icons";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";

import FleetDialog from "../../ui/dialogbox/FleetDialog";
import Img from "../../ui/image/Img";
import Settings from "../../settings/Settings";
import DialogIncident from "../../settings/FireApp/IncidentList/DialogIncident";
import BroadcastMsg from "../../settings/FireApp/Broadcast/BroadcastMsg";

import { LABELS, CONFIG, NOTIFY, ALERTS, CONFIRMS } from "../../common/consts";
import { MAP_ACTIONS } from "../../../actions/MapActions";

// Left Bar Icons
import FitMapIcon from "./Images/LeftMenu/fitmap.svg";
import FitMapExitIcon from "./Images/LeftMenu/fullscreen-exit.svg";
import ScaleIcon from "./Images/LeftMenu/scale.svg";

// Right Bar Icons
import LabelIcon from "./Images/RightMenu/label.svg";
import VehicleIcon from "./Images/RightMenu/vehicle.svg";
import ClusterIcon from "./Images/RightMenu/clusters.svg";
import TrafficIcon from "./Images/RightMenu/traffic.svg";
import DataPointsIcon from "./Images/RightMenu/datapoints.svg";

import "./topbar.css";
import fleetLogo from "../../../images/logo/logo.png";
import { commonJs } from "../../common/common";
import { PAGE_ACTIONS } from "../../../actions/PageActions";

class FixedTopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openProfileMenu: false,
      logoutDialog: false,
      fullScreen: commonJs.isFullScreen(),
    };
    this.anchorEl = null;
  }

  componentDidMount() {
    document.addEventListener(
      "fullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "mozfullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "MSFullscreenChange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "webkitfullscreenchange",
      this.onFullScreenChange,
      false
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      "fullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.removeEventListener(
      "mozfullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.removeEventListener(
      "MSFullscreenChange",
      this.onFullScreenChange,
      false
    );
    document.removeEventListener(
      "webkitfullscreenchange",
      this.onFullScreenChange,
      false
    );
  }

  onFullScreenChange = (e) => {
    if (window.innerHeight !== window.screen.height) {
      this.setState({
        fullScreen: false,
      });
    } else {
      this.setState({
        fullScreen: true,
      });
    }
  };

  topmenuHandler = (menu) => {
    const {
      fitmap,
      locateMarkerDialog,
      scale,
      mapLabels,
      mapVehicles,
      mapPoints,
      mapRoutes,
      mapZones,
      mapClusters,
      mapTraffic,
      dataPoints,
    } = this.props.mapReducer;

    let self = this;

    switch (menu) {
      case "toggleBroadcast":
        this.props.toggleBroadcastDialog({
          broadcastDialog: true,
        });
        break;
      case "addincident":
        if (this.props.fireReducer.latLng.lat) {
          this.props.toggleAddIncidentDialog({
            addIncidentDialog: true,
          });
        } else {
          commonJs.showNotify(
            self.props,
            NOTIFY.WARNING,
            "Please select the incident location in map..!"
          );
        }
        break;
      case "locate":
        this.props.setLocateMarker({
          locateMarkerDialog: !locateMarkerDialog,
        });
        break;
      case "fitmap":
        if (!fitmap) {
          this.props.setFitScreenStatus({
            fitmap: !fitmap,
          });
          // Fit Map -> Fit To Bounds
          this.props.mapFitBounds();
        } else {
          this.props.setFitScreenStatus({
            fitmap: !fitmap,
          });
        }
        break;
      case "printMap":
        // TODO: Print Map
        break;
      case "scale":
        this.props.setScaleStatus({
          scale: !scale,
        });
        break;
      case "mapLabels":
        this.props.setMapLabelStatus({
          mapLabels: !mapLabels,
        });
        break;
      case "mapVehicles":
        this.props.setMapVehiclesStatus({
          mapVehicles: !mapVehicles,
        });
        break;
      case "mapPoints":
        this.props.setMapPointsStatus({
          mapPoints: !mapPoints,
        });
        break;
      case "mapRoutes":
        this.props.setMapRoutesStatus({
          mapRoutes: !mapRoutes,
        });
        break;
      case "mapZones":
        this.props.setMapZonesStatus({
          mapZones: !mapZones,
        });
        break;
      case "mapClusters":
        this.props.setMapClustersStatus({
          mapClusters: !mapClusters,
        });
        break;
      case "mapTraffic":
        this.props.setMapTrafficStatus({
          mapTraffic: !mapTraffic,
        });
        break;
      case "dataPoints":
        this.props.toggleDataPoints({
          dataPoints: !dataPoints,
        });
        break;
      case "myProfile":
        const { openProfileMenu } = this.state;
        this.setState({
          openProfileMenu: !openProfileMenu,
        });
        break;
      default:
        break;
    }
  };

  toggleProfileMenu = () => {
    const { settingsMenu } = this.props.pageReducer;
    this.props.toggleSettingsMenu({
      settingsMenu: !settingsMenu,
    });
    this._settingsDialog.handleChange(null, 0);
  };

  toggleLogoutDialog = () => {
    const { logoutDialog } = this.state;
    this.setState({
      logoutDialog: !logoutDialog,
    });
  };

  logout = () => {
    this.setState({
      logoutDialog: false,
    });
    commonJs.showNotify(
      this.props,
      NOTIFY.SUCCESS,
      ALERTS.LOGOUT_SUCESSSFUL,
      <button className="snackbar-btn">{LABELS.DISMISS}</button>
    );
    commonJs.logout();
  };

  handleListKeyDown = () => {};

  closeProfileMenu = () => {
    this.setState({
      openProfileMenu: false,
    });
  };

  render() {
    const { loginReducer, mapReducer, onPrintMap } = this.props;

    const { logo, userName } = loginReducer;

    const {
      fitmap,
      scale,
      mapLabels,
      mapVehicles,
      mapClusters,
      mapTraffic,
      dataPoints,
      mapType,
    } = mapReducer;

    const { openProfileMenu, logoutDialog, fullScreen } = this.state;

    const {
      leftMapMenu,
      addIncidentDialog,
      broadcastDialog,
    } = this.props.pageReducer;

    return (
      <div
        className={
          "topMapBar" + (fitmap ? " open" : "") + (fullScreen ? " hide" : "")
        }
      >
        <List
          component="nav"
          className={"topLeftMenu" + (leftMapMenu ? " leftbar" : "")}
        >
          <ListItem onClick={() => this.topmenuHandler("fitmap")}>
            <Tooltip
              className="tooltip"
              title={fitmap ? LABELS.EXIT_FIT_SCREEN : LABELS.FIT_SCREEN}
            >
              <IconButton className="topicon-pad">
                <Img src={fitmap ? FitMapExitIcon : FitMapIcon} />
              </IconButton>
            </Tooltip>
          </ListItem>
          <ListItem
            disabled={!scale}
            onClick={() => this.topmenuHandler("scale")}
          >
            <Tooltip title={LABELS.SCALE}>
              <IconButton className="topicon-pad rotateScale">
                <Img src={ScaleIcon} />
              </IconButton>
            </Tooltip>
          </ListItem>
          {/* <ListItem onClick={onPrintMap}>
            <Tooltip title={LABELS.PRINT_MAP}>
              <IconButton className="topicon-pad">
                <Img src={PrintIcon} />
              </IconButton>
            </Tooltip>
          </ListItem> */}
          <ListItem onClick={() => this.topmenuHandler("addincident")}>
            <Tooltip title={LABELS.ADD_INCIDENT}>
              <IconButton className="topicon-pad addincident-icon">
                <PlaylistAdd />
              </IconButton>
            </Tooltip>
          </ListItem>
          <ListItem onClick={() => this.topmenuHandler("toggleBroadcast")}>
            <Tooltip title={LABELS.BROADCAST_MSG}>
              <IconButton className="topicon-pad broadcast-icon">
                <SendMsgIcon />
              </IconButton>
            </Tooltip>
          </ListItem>
        </List>

        {/* TODO: !disabled */}
        <List component="nav" className="topRightMenu">
          <ListItem
            disabled={!mapLabels}
            onClick={() => this.topmenuHandler("mapLabels")}
          >
            <Tooltip title={LABELS.HIDE_UNHIDE_LABELS}>
              <IconButton className="topicon-pad">
                <Img src={LabelIcon} />
              </IconButton>
            </Tooltip>
          </ListItem>

          <ListItem
            disabled={!mapVehicles}
            onClick={() => this.topmenuHandler("mapVehicles")}
          >
            <Tooltip title={LABELS.HIDE_UNHIDE_VEHICLES}>
              <IconButton className="topicon-pad">
                <Img src={VehicleIcon} />
              </IconButton>
            </Tooltip>
          </ListItem>

          {/* <ListItem
            disabled={!mapPoints}
            onClick={() => this.topmenuHandler("mapPoints")}
          >
            <Tooltip title={LABELS.HIDE_UNHIDE_POINTS}>
              <IconButton className="topicon-pad">
                <Img src={PointIcon} />
              </IconButton>
            </Tooltip>
          </ListItem>

          <ListItem
            disabled={!mapRoutes}
            onClick={() => this.topmenuHandler("mapRoutes")}
          >
            <Tooltip title={LABELS.HIDE_UNHIDE_ROUTES}>
              <IconButton className="topicon-pad">
                <Img src={RouteIcon} />
              </IconButton>
            </Tooltip>
          </ListItem>

          <ListItem
            disabled={!mapZones}
            onClick={() => this.topmenuHandler("mapZones")}
          >
            <Tooltip title={LABELS.HIDE_UNHIDE_ZONES}>
              <IconButton className="topicon-pad">
                <Img src={ZoneIcon} />
              </IconButton>
            </Tooltip>
          </ListItem> */}

          <ListItem
            disabled={!mapClusters}
            onClick={() => this.topmenuHandler("mapClusters")}
          >
            <Tooltip
              title={LABELS.HIDE_UNHIDE_CLUSTERS}
              className="fleetTooltip"
            >
              <IconButton className="topicon-pad">
                <Img src={ClusterIcon} />
              </IconButton>
            </Tooltip>
          </ListItem>

          {/* Show Traffic only if Google Map */}
          {mapType > 0 && mapType < 4 && (
            <ListItem
              disabled={!mapTraffic}
              onClick={() => this.topmenuHandler("mapTraffic")}
            >
              <Tooltip title={LABELS.SHOW_TRAFFIC}>
                <IconButton className="topicon-pad">
                  <Img src={TrafficIcon} />
                </IconButton>
              </Tooltip>
            </ListItem>
          )}

          <ListItem
            disabled={!dataPoints}
            onClick={() => this.topmenuHandler("dataPoints")}
          >
            <Tooltip title={LABELS.SHOW_DATA_POINTS}>
              <IconButton className="topicon-pad">
                <Img src={DataPointsIcon} />
              </IconButton>
            </Tooltip>
          </ListItem>

          {/* My Profile */}
          <ListItem
            id="myProfileBtn"
            onClick={() => this.topmenuHandler("myProfile")}
          >
            <Tooltip title={userName}>
              <IconButton className="topicon-pad avatar-wrapper">
                <Img
                  src={CONFIG.BASE_URL + "/" + (logo === "" ? fleetLogo : logo)}
                />
              </IconButton>
            </Tooltip>
          </ListItem>
          <Popper
            id="profileMenu"
            open={openProfileMenu}
            ref={(ref) => (this.anchorEl = ref)}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <ClickAwayListener onClickAway={this.closeProfileMenu}>
                  <Paper>
                    <MenuList
                      autofocusitem={openProfileMenu.toString()}
                      id="menu-list-grow"
                      onKeyDown={this.handleListKeyDown}
                    >
                      <MenuItem onClick={this.toggleProfileMenu}>
                        My account
                      </MenuItem>
                      <MenuItem onClick={this.toggleLogoutDialog}>
                        Logout
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </ClickAwayListener>
              </Grow>
            )}
          </Popper>
        </List>

        <FleetDialog
          open={logoutDialog}
          onCloseSettings={this.toggleLogoutDialog}
          title={LABELS.LOGOUT}
          maxWidth="sm"
          fullWidth={true}
          saveButton={true}
          cancelButton={true}
          cancelAction={this.toggleLogoutDialog}
          saveAction={this.logout}
          saveBtnLabel="OK"
          classNameWrapper="logoutWrapper"
        >
          <p>{CONFIRMS.CONFIRM_LOGOUT}</p>
        </FleetDialog>

        <Settings childRef={(ref) => (this._settingsDialog = ref)} />

        {addIncidentDialog && (
          <DialogIncident
            childRef={(ref) => (this._addIncidentDialog = ref)}
            popup={true}
          />
        )}

        {broadcastDialog && (
          <BroadcastMsg
            childRef={(ref) => (this._msgBroadcastDialog = ref)}
            popup={true}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
    mapReducer: state.mapReducer,
    pageReducer: state.pageReducer,
    fireReducer: state.fireReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleBroadcastDialog: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_BROADCAST_DIALOG,
        values,
      });
    },
    toggleAddIncidentDialog: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_ADD_INCIDENT_DIALOG,
        values,
      });
    },
    toggleSettingsMenu: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_SETTINGS_MENU,
        values,
      });
    },
    setFitScreenStatus: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_FITSCREEN,
        values,
      });
    },
    setLocateMarker: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_MAP_MARKER_DIALOG,
        values,
      });
    },
    setScaleStatus: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_MAP_SCALE,
        values,
      });
    },
    setMapLabelStatus: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_MAP_LABELS,
        values,
      });
    },
    setMapVehiclesStatus: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_MAP_VEHICLES,
        values,
      });
    },
    setMapPointsStatus: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_MAP_POINTS,
        values,
      });
    },
    setMapRoutesStatus: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_MAP_ROUTES,
        values,
      });
    },
    setMapZonesStatus: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_MAP_ZONES,
        values,
      });
    },
    setMapClustersStatus: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_MAP_CLUSTERS,
        values,
      });
    },
    setMapTrafficStatus: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_MAP_TRAFFIC,
        values,
      });
    },
    toggleDataPoints: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_MAP_DATA_POINTS,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(FixedTopBar));
