import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import {
  KeyboardArrowDown as ArrowDown,
  KeyboardArrowUp as ArrowUp,
  CloseRounded as CloseIcon,
  AddCircleOutline as AddIcon,
  RemoveCircleOutline as RemoveIcon,
  PlayCircleOutline as PlayIcon,
  PauseCircleOutline as PauseIcon,
  SkipNext as SkipStopsIcon,
} from "@material-ui/icons";
import { connect } from "react-redux";

import { DEFAULTS, LABELS } from "../../../common/consts";
import { TRACK_ANIMATION_ACTIONS } from "../../../../actions/TrackAnimationActions";
import { TRACK_ACTIONS } from "../../../../actions/TrackActions";

class TrackControls extends Component {
  constructor(props) {
    super(props);
    this._timer = null;
  }

  changePlaySpeed = (op = "+") => {
    let speed = this.props.trackAnimationReducer.trackSpeed;
    if (op === "+") {
      speed++;
    } else {
      speed--;
    }
    if (speed < 1) {
      speed = 1;
    }
    if (speed > 4) {
      speed = 4;
    }
    this.props.setTrackSpeed({
      trackSpeed: speed,
    });
  };

  togglePlayback = () => {
    const { trackAnimation, trackSpeed } = this.props.trackAnimationReducer;
    if (!trackAnimation) {
      let speed = 1000;
      switch (trackSpeed) {
        case 2:
          speed = 750;
          break;
        case 3:
          speed = 500;
          break;
        case 4:
          speed = 250;
          break;
        default:
          speed = 1000;
          break;
      }
      let self = this;
      this._timer = setInterval(() => {
        const { trackIndex } = self.props.trackAnimationReducer;
        const { routePoints } = self.props.trackReducer;
        if (trackIndex === routePoints.length - 1) {
          self.clearTimer();
        } else {
          self.props.setTrackIndex({
            trackIndex: trackIndex + 1,
          });
          // Show in map
          self.props.updateMapRegion(
            routePoints[trackIndex].latitude,
            routePoints[trackIndex].longitude,
            JSON.parse(localStorage.map).zoom || DEFAULTS.MAX_ZOOM
          );
        }
      }, speed);
    } else {
      this.clearTimer();
    }
    this.props.togglePlayback({
      trackAnimation: !trackAnimation,
    });
  };

  componentWillUnmount() {
    this.clearTimer();
  }

  clearTimer = () => {
    this.props.togglePlayback({
      trackAnimation: false,
    });
    clearInterval(this._timer);
    this._timer = null;
  };

  toggleSkipStops = () => {
    const { skipStops } = this.props.trackAnimationReducer;
    const { routePoints, routeTmpPoints } = this.props.trackReducer;

    if (!skipStops) {
      let routeSkipPoints = [];
      for (let i = 0; i < routePoints.length; i++) {
        if (i > 0) {
          if (
            routePoints[i].latitude != routePoints[i - 1].latitude ||
            routePoints[i].longitude != routePoints[i - 1].longitude
          ) {
            routeSkipPoints.push(routePoints[i]);
          }
        } else {
          routeSkipPoints.push(routePoints[i]);
        }
      }
      this.props.toggleSkipRecords({
        routeTmpPoints: routePoints,
        routePoints: routeSkipPoints,
      });
    } else {
      this.props.toggleSkipRecords({
        routeTmpPoints: [],
        routePoints: routeTmpPoints, // Original List
      });
    }

    this.props.toggleSkipStops({
      skipStops: !skipStops,
      trackIndex: 0,
    });
  };

  render() {
    const {
      trackAnimation,
      trackSpeed,
      skipStops,
    } = this.props.trackAnimationReducer;
    return (
      <React.Fragment>
        <Tooltip title={LABELS.SPEED_MINUS} placement="bottom">
          {trackSpeed === 1 || trackAnimation ? (
            <RemoveIcon />
          ) : (
            <IconButton
              onClick={() => {
                this.changePlaySpeed("-");
              }}
            >
              <RemoveIcon />
            </IconButton>
          )}
        </Tooltip>
        <div>{trackSpeed + "x"}</div>
        <Tooltip title={LABELS.SPEED_PLUS} placement="bottom">
          {trackSpeed === 4 || trackAnimation ? (
            <AddIcon />
          ) : (
            <IconButton
              onClick={() => {
                this.changePlaySpeed("+");
              }}
            >
              <AddIcon />
            </IconButton>
          )}
        </Tooltip>
        <Tooltip title={LABELS.PLAY_PLAYBACK} placement="bottom">
          {trackAnimation ? (
            <PlayIcon />
          ) : (
            <IconButton onClick={this.togglePlayback}>
              <PlayIcon />
            </IconButton>
          )}
        </Tooltip>
        <Tooltip title={LABELS.STOP_PLAYBACK} placement="bottom">
          {!trackAnimation ? (
            <PauseIcon />
          ) : (
            <IconButton onClick={this.togglePlayback}>
              <PauseIcon />
            </IconButton>
          )}
        </Tooltip>
        <Tooltip title={LABELS.SKIP_STOPS} placement="bottom">
          {!trackAnimation ? (
            <IconButton
              onClick={this.toggleSkipStops}
              className={skipStops ? "green" : ""}
            >
              <SkipStopsIcon />
            </IconButton>
          ) : (
            <SkipStopsIcon className={skipStops ? "green" : ""} />
          )}
        </Tooltip>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trackAnimationReducer: state.trackAnimationReducer,
    trackReducer: state.trackReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    togglePlayback: (values) => {
      dispatch({
        type: TRACK_ANIMATION_ACTIONS.TOGGLE_ANIMATION,
        values,
      });
    },
    setTrackIndex: (values) => {
      dispatch({
        type: TRACK_ANIMATION_ACTIONS.SET_TRACK_INDEX,
        values,
      });
    },
    setTrackSpeed: (values) => {
      dispatch({
        type: TRACK_ANIMATION_ACTIONS.SET_TRACK_SPEED,
        values,
      });
    },
    toggleSkipStops: (values) => {
      dispatch({
        type: TRACK_ANIMATION_ACTIONS.TOGGLE_SKIP_STOPS,
        values,
      });
    },
    toggleSkipRecords: (values) => {
      dispatch({
        type: TRACK_ACTIONS.SET_SKIP_STOPS,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackControls);
