import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";

import "typeface-roboto";

import store from "./store";

ReactDOM.render(
  <SnackbarProvider maxSnack={4}>
    <Provider store={store}>
      <App />
    </Provider>
  </SnackbarProvider>,
  document.getElementById("root")
);
