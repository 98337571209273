import React, { Component } from "react";

// React Grid
import { SortingState, IntegratedSorting } from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow
} from "@devexpress/dx-react-grid-material-ui";

import "./grid.css";

const getRowId = row => row.id;

class FleetGrid extends Component {
  render() {
    const { rows, columns } = this.props.vehicles;
    return (
      <div id={this.props.id} className="fleetGrid">
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <SortingState
            sorting={[
              { columnName: "name", direction: this.props.pointSorting }
            ]}
            onSortingChange={this.props.changePointSort}
          />
          <IntegratedSorting />
          <VirtualTable />
          <TableHeaderRow showSortingControls />
        </Grid>
      </div>
    );
  }
}

export default FleetGrid;
