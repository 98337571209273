import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Paper,
  Button,
  ClickAwayListener,
  Grow,
  Popper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
} from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

import TrackBottomBar from "./trackbar/TrackBottomBar";

import Img from "../../ui/image/Img";
import { MAP_ACTIONS } from "../../../actions/MapActions";
import { DEFAULTS } from "../../common/consts";

import gmap from "./images/gmap.png";
import "./bottombar.css";

class BottomBar extends Component {
  constructor(props) {
    super(props);
    const { signalFilter, loadStatusFilter, mapType } = this.props.mapReducer;
    this.state = {
      vehicleStatusOpen: false,
      LoadStatusOpen: false,
      mapTypeOpen: false,
      signalFilter,
      loadStatusFilter,
      mapType,
    };
  }

  onToggleLoadStatus = () => {
    const { LoadStatusOpen } = this.state;
    if (!LoadStatusOpen) {
      this.setState({
        LoadStatusOpen: !LoadStatusOpen,
        vehicleStatusOpen: false,
        mapTypeOpen: false,
      });
    } else {
      this.setState({ LoadStatusOpen: !LoadStatusOpen });
    }
  };

  onToggleVehicleStatus = () => {
    const { vehicleStatusOpen } = this.state;
    if (!vehicleStatusOpen) {
      this.setState({
        vehicleStatusOpen: !vehicleStatusOpen,
        LoadStatusOpen: false,
        mapTypeOpen: false,
      });
    } else {
      this.setState({ vehicleStatusOpen: !vehicleStatusOpen });
    }
  };

  onMapTypeToggle = () => {
    const { mapTypeOpen } = this.state;
    if (!mapTypeOpen) {
      this.setState({
        mapTypeOpen: !mapTypeOpen,
        LoadStatusOpen: false,
        vehicleStatusOpen: false,
      });
    } else {
      this.setState({ mapTypeOpen: !mapTypeOpen });
    }
  };

  handleCloseLoadStatus = (event) => {
    if (this.anchorEl2.contains(event.target)) {
      return;
    }
    this.setState({ LoadStatusOpen: false });
  };

  handleCloseVehicleStatus = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ vehicleStatusOpen: false });
  };

  onMapTypeClose = (event) => {
    if (this.anchorEl3.contains(event.target)) {
      return;
    }
    this.setState({ mapTypeOpen: false });
  };

  setSignalFilter = (indx) => {
    this.setState({
      signalFilter: indx,
    });
    var self = this;
    setTimeout(() => {
      self.props.setSignalFilterStatus({
        signalFilter: indx,
      });
    }, 1000);
  };

  setLoadStatusFilter = (indx) => {
    this.setState({
      loadStatusFilter: indx,
    });
    var self = this;
    setTimeout(() => {
      self.props.setLoadStatusFilter({
        loadStatusFilter: indx,
      });
    }, 1000);
  };

  setMapTypeFilter = (indx) => {
    this.setState({
      mapType: indx,
    });
    var self = this;
    setTimeout(() => {
      self.props.setMapType({
        mapType: indx,
      });
    }, 1000);
  };

  render() {
    const {
      vehicleStatusOpen,
      LoadStatusOpen,
      mapTypeOpen,
      signalFilter,
      loadStatusFilter,
      mapType,
    } = this.state;

    const { trackBottomBar } = this.props.pageReducer;

    const signalStatus = DEFAULTS.DEFAULT_VEHICLE_STATUS_LIST;
    const loadStatus = DEFAULTS.DEFAULT_VEHICLE_LOAD_STATUS_LIST;
    const mapTypes = DEFAULTS.DEFAULT_MAPTYPES_LIST;

    return (
      <div id="bottomBar">
        {/* Signal Status Filter */}
        <Button
          buttonRef={(node) => {
            this.anchorEl = node;
          }}
          aria-owns={vehicleStatusOpen ? "showVehiclesList" : undefined}
          aria-haspopup="true"
          onClick={this.onToggleVehicleStatus}
          className={signalFilter === 0 ? "" : " purple"}
        >
          {"Signal Status (" + signalStatus[signalFilter] + ")"}
          <span className="showVehiclesIcon">
            {vehicleStatusOpen ? <ArrowDropDown /> : <ArrowDropUp />}
          </span>
        </Button>
        <ClickAwayListener onClickAway={this.handleCloseVehicleStatus}>
          <Popper
            open={vehicleStatusOpen}
            anchorEl={this.anchorEl}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="showVehiclesList"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <List className="showVehiclesList">
                    {signalStatus.map((value, index) => {
                      let checked = false;
                      if (signalFilter === index) {
                        checked = true;
                      }
                      return (
                        <ListItem
                          key={"signalstatus-" + index}
                          role={undefined}
                          dense
                          button
                          onClick={() => this.setSignalFilter(index)}
                          className="showVehiclesItem"
                        >
                          <ListItemText primary={value} />
                          <ListItemSecondaryAction className="showVehiclesItemAction">
                            <Checkbox
                              onClick={() => this.setSignalFilter(index)}
                              checked={checked}
                              tabIndex={-1}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                </Paper>
              </Grow>
            )}
          </Popper>
        </ClickAwayListener>

        {/* Vehicle Status Status */}
        <Button
          buttonRef={(node) => {
            this.anchorEl2 = node;
          }}
          aria-owns={LoadStatusOpen ? "showVehiclesList" : undefined}
          aria-haspopup="true"
          onClick={this.onToggleLoadStatus}
          className={loadStatusFilter === 0 ? "" : " purple"}
        >
          {"Vehicle Status (" + loadStatus[loadStatusFilter] + ")"}
          <span className="showVehiclesIcon">
            {LoadStatusOpen ? <ArrowDropDown /> : <ArrowDropUp />}
          </span>
        </Button>
        <ClickAwayListener onClickAway={this.handleCloseLoadStatus}>
          <Popper
            open={LoadStatusOpen}
            anchorEl={this.anchorEl2}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="showVehicleStatusList"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <List className="showVehiclesList">
                    {loadStatus.map((value, index) => {
                      let checked = false;
                      if (loadStatusFilter === index) {
                        checked = true;
                      }
                      return (
                        <ListItem
                          key={"loadstatus-" + index}
                          role={undefined}
                          dense
                          button
                          onClick={() => this.setLoadStatusFilter(index)}
                          className="showVehiclesItem"
                        >
                          <ListItemText
                            primary={value}
                            className="showVehiclesItemText"
                          />
                          <ListItemSecondaryAction className="showVehiclesItemAction">
                            <Checkbox
                              onClick={() => this.setLoadStatusFilter(index)}
                              checked={checked}
                              tabIndex={-1}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                </Paper>
              </Grow>
            )}
          </Popper>
        </ClickAwayListener>

        {/* Map Type Filter */}
        <Button
          buttonRef={(node) => {
            this.anchorEl3 = node;
          }}
          aria-owns={mapTypeOpen ? "mapTypeList" : undefined}
          aria-haspopup="true"
          onClick={this.onMapTypeToggle}
        >
          Map Type
          <span className="mapTypeIcon">
            {mapTypeOpen ? <ArrowDropDown /> : <ArrowDropUp />}
          </span>
        </Button>
        <ClickAwayListener onClickAway={this.onMapTypeClose}>
          <Popper
            open={mapTypeOpen}
            anchorEl={this.anchorEl3}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="mapTypeList"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <List className="mapTypeList">
                    {mapTypes.map((value, index) => {
                      let checked = false;
                      if (mapType === index) {
                        checked = true;
                      }
                      return (
                        <ListItem
                          key={"maptype-" + index}
                          role={undefined}
                          dense
                          button
                          onClick={() => this.setMapTypeFilter(index)}
                          className="mapTypeItem"
                        >
                          <ListItemAvatar>
                            <Img src={gmap} width="15" />
                          </ListItemAvatar>
                          <ListItemText
                            primary={value}
                            className="mapTypeLabel"
                          />
                          <ListItemSecondaryAction className="mapTypeItemAction">
                            <Checkbox
                              onClick={() => this.setMapTypeFilter(index)}
                              checked={checked}
                              tabIndex={-1}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                </Paper>
              </Grow>
            )}
          </Popper>
        </ClickAwayListener>

        <Drawer
          anchor="bottom"
          open={trackBottomBar}
          classes={{ paper: "bottomdrawer-menu", root: "bottomdrawer" }}
          BackdropProps={{
            invisible: true,
          }}
          disableBackdropClick={true}
          hideBackdrop={true}
        >
          <TrackBottomBar updateMapRegion={this.props.updateMapRegion} />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
    pageReducer: state.pageReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMapType: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_MAP_TYPE,
        values,
      });
    },
    setSignalFilterStatus: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_SIGNALFILTER_STATUS,
        values,
      });
    },
    setLoadStatusFilter: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_LOADFILTER_STATUS,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BottomBar);
