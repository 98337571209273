import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

//Leaflet
import { Marker } from "react-leaflet";
import L from "leaflet";

import "react-leaflet-markercluster/dist/styles.min.css";
import "leaflet/dist/leaflet.css";

// Images
import markerDataPoint from "./images/markers/datapoints.png";

// Custom CSS
import "./styles/fleetmap.css";

import MapPopup from "./MapPopup";
import { commonJs } from "../../common/common";

class DataPoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  getMapMarker = (speed) => {
    var markerUrl = markerDataPoint;
    if (speed > 0) {
      markerUrl = markerDataPoint;
    }
    const customMarker = L.icon({
      iconUrl: markerUrl,
      iconRetinaUrl: markerUrl,
      iconAnchor: null,
      popupAnchor: [0, -3],
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(16, 16),
      className: "datapoint",
    });
    return customMarker;
  };

  mapClick = (e) => {};

  markerClick = (indx) => {};

  dataPointClick = (indx) => {
    const { routePoints } = this.props.trackReducer;
    const { latitude, longitude } = routePoints[indx];
    commonJs.getAddress(Number(latitude), Number(longitude)).then((res) => {
      if (document.querySelector(".popupAddress")) {
        document.querySelector(".popupAddress").innerText = res.Response;
      } else {
        setTimeout(() => {
          document.querySelector(".popupAddress").innerText = res.Response;
        }, 500);
      }
    });
  };

  componentDidMount = () => {};

  componentWillUnmount() {}

  loadMarkers = () => {
    const { routePoints } = this.props.trackReducer;
    const { selectedVehicle, tmpVehicles } = this.props.mapReducer;
    let vehicle = commonJs.getVehicleData(tmpVehicles, selectedVehicle);
    if (vehicle.length > 0) {
      vehicle = vehicle[0];
    }
    return routePoints.map((datapoint, indx) => {
      let pos = [Number(datapoint.latitude), Number(datapoint.longitude)];

      return (
        <Marker
          position={pos}
          icon={this.getMapMarker(Number(datapoint.speed))}
          key={"dataPoint-" + indx}
          ref={indx}
          onclick={() => this.dataPointClick(indx)}
          className="dataPoint"
        >
          <MapPopup
            vehicle={vehicle}
            data={datapoint}
            datapoint={true}
            indx={indx}
          />
        </Marker>
      );
    });
  };

  render() {
    const { routePoints } = this.props.trackReducer;
    const { dataPoints } = this.props.mapReducer;
    return routePoints.length > 0 && dataPoints ? this.loadMarkers() : null;
  }
}

const mapStateToProps = (state) => {
  return {
    trackReducer: state.trackReducer,
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DataPoints));
