import React, { Component } from "react";
import {
  SwipeableDrawer,
  ListSubheader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";

// Icons
import {
  Description,
  Map,
  PowerSettingsNew,
  ZoomOutMap,
  FullscreenExit,
  SettingsOutlined,
  SupervisorAccountRounded as Supervisor,
  ExpandLess,
  ExpandMore,
  Send as SendIcon,
  VideoLabel as VideoRoomIcon,
} from "@material-ui/icons";

// Custom UI
import MapPageTabs from "./maptabs/";
import FleetDialog from "../../ui/dialogbox/FleetDialog";

// Custom CSS
import "./leftbar.css";
import { LABELS, CONFIRMS, NOTIFY, ALERTS, CONFIG } from "../../common/consts";
import { PAGE_ACTIONS } from "../../../actions/PageActions";
import { commonJs } from "../../common/common";
import { API_FireApp } from "../../apihandler/FireApp";

class LeftBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoutDialog: false,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);

    document.addEventListener(
      "fullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "mozfullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "MSFullscreenChange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "webkitfullscreenchange",
      this.onFullScreenChange,
      false
    );
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);

    document.removeEventListener(
      "fullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.removeEventListener(
      "mozfullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.removeEventListener(
      "MSFullscreenChange",
      this.onFullScreenChange,
      false
    );
    document.removeEventListener(
      "webkitfullscreenchange",
      this.onFullScreenChange,
      false
    );
  }

  onFullScreenChange = (e) => {
    const { fullScreen } = this.props.pageReducer;
    if (fullScreen && window.innerHeight !== window.screen.height) {
      this.props.toggleFullScreen({
        fullScreen: false,
      });
    } else {
      this.props.toggleFullScreen({
        fullScreen: true,
      });
    }
  };

  onMenuToggle = (menu) => {
    const { leftMapMenu, leftReportsMenu } = this.props.pageReducer;
    var self = this;
    if (menu === "leftMapMenu") {
      if (leftMapMenu && !leftReportsMenu) {
        self.props.setLeftMapMenu({ leftMapMenu: false, leftReportsMenu });
      } else {
        self.props.setLeftMapMenu({
          leftMapMenu: true,
          leftReportsMenu: false,
        });
      }
    } else if (menu === "leftReportsMenu") {
      window.open(CONFIG.REPORT_URL, "_blank");
    } else if (menu === "videoRoom") {
      window.open(CONFIG.VIDEO_ROOM_URL, "_blank");
    }
  };

  openSettings = () => {
    const { settingsMenu } = this.props.pageReducer;
    this.props.toggleSettingsMenu({
      settingsMenu: !settingsMenu,
    });
  };

  toggleFullscreen = () => {
    const { fullScreen } = this.props.pageReducer;
    if (!fullScreen) {
      commonJs.openFullScreen();
    } else {
      commonJs.exitFullScreen();
    }
    // "onFullScreenChange" handles the "fullScreen" state
  };

  toggleLogoutDialog = () => {
    const { logoutDialog } = this.state;
    this.setState({
      logoutDialog: !logoutDialog,
    });
  };

  logout = () => {
    this.props.setMapLoading({ loading: true });
    this.setState({
      logoutDialog: false,
    });
    commonJs.showNotify(
      this.props,
      NOTIFY.SUCCESS,
      ALERTS.LOGOUT_SUCESSSFUL,
      <button className="snackbar-btn">{LABELS.DISMISS}</button>
    );
    API_FireApp.revokeJanus()
      .then((res) => {
        commonJs.logout();
      })
      .catch((err) => {
        commonJs.logout();
      });
  };

  render() {
    const {
      varient,
      loginReducer,
      mapReducer,
      pageReducer,
      updateMapRegion,
      trackFitBounds,
    } = this.props;
    const { fitmap } = mapReducer;
    const { fullScreen, leftMapMenu, leftReportsMenu } = pageReducer;
    const { role } = loginReducer;
    const { logoutDialog } = this.state;

    return (
      <React.Fragment>
        <div
          className={"leftFixedMenu" + (fitmap || fullScreen ? " hide" : "")}
        >
          <ul className="topmenu">
            <li onClick={() => this.onMenuToggle("leftMapMenu")}>
              <Tooltip title={LABELS.MAP} placement="right">
                <IconButton
                  classes={{ root: "menucolor" }}
                  className={leftMapMenu ? "active" : ""}
                >
                  <Map />
                </IconButton>
              </Tooltip>
            </li>
            <li onClick={() => this.onMenuToggle("leftReportsMenu")}>
              <Tooltip title={LABELS.REPORTS} placement="right">
                <IconButton
                  classes={{ root: "menucolor" }}
                  className={this.leftReportsMenu ? "active" : ""}
                >
                  <Description />
                </IconButton>
              </Tooltip>
            </li>
            <li onClick={() => this.onMenuToggle("videoRoom")}>
              <Tooltip title={LABELS.CONFERENCE_ROOM} placement="right">
                <IconButton classes={{ root: "menucolor" }}>
                  <VideoRoomIcon />
                </IconButton>
              </Tooltip>
            </li>
          </ul>
          <ul className="bottommenu">
            {(role === "admin" || role === "superadmin") && (
              <li>
                <Tooltip title={LABELS.CPANEL} placement="right">
                  <IconButton classes={{ root: "menucolor" }}>
                    <Supervisor />
                  </IconButton>
                </Tooltip>
              </li>
            )}

            <li>
              <Tooltip title={LABELS.SETTINGS} placement="right">
                <IconButton
                  classes={{ root: "menucolor" }}
                  onClick={this.openSettings}
                >
                  <SettingsOutlined />
                </IconButton>
              </Tooltip>
            </li>

            <li>
              <Tooltip title={LABELS.FULL_SCREEN} placement="right">
                <IconButton
                  classes={{ root: "menucolor" }}
                  onClick={this.toggleFullscreen}
                >
                  {fullScreen ? <FullscreenExit /> : <ZoomOutMap />}
                </IconButton>
              </Tooltip>
            </li>
            <li>
              <Tooltip title={LABELS.LOGOUT} placement="right">
                <IconButton
                  classes={{ root: "menucolor" }}
                  onClick={this.toggleLogoutDialog}
                >
                  <PowerSettingsNew />
                </IconButton>
              </Tooltip>
            </li>
          </ul>
        </div>
        <SwipeableDrawer
          open={fitmap || fullScreen ? false : leftMapMenu}
          onClose={() => this.onMenuToggle("leftMapMenu", false)}
          onOpen={() => this.onMenuToggle("leftMapMenu", true)}
          variant={varient}
          className={"leftdrawer" + (this.leftMapMenu ? " open" : "")}
          classes={{ paper: "leftdrawer-menu" }}
          BackdropProps={{
            invisible: true,
          }}
          //   disableBackdropClick={true}
          hideBackdrop={true}
          tabIndex={9}
        >
          <MapPageTabs
            updateMapRegion={updateMapRegion}
            trackFitBounds={trackFitBounds}
            childRef={(ref) => (this._mapTabs = ref)}
          />
        </SwipeableDrawer>
        <SwipeableDrawer
          open={leftReportsMenu}
          onClose={() => this.onMenuToggle("leftReportsMenu", false)}
          onOpen={() => this.onMenuToggle("leftReportsMenu", true)}
          variant={varient}
          className={"leftdrawer" + (leftReportsMenu ? " open" : "")}
          classes={{ paper: "leftdrawer-menu", modal: "fleetmodal" }}
        >
          <List
            component="nav"
            subheader={
              <ListSubheader component="div">{LABELS.REPORTS}</ListSubheader>
            }
            className="menu"
          >
            <ListItem button>
              <ListItemIcon className="menuicon">
                <SendIcon />
              </ListItemIcon>
              <ListItemText inset primary="Dashboard" />
            </ListItem>
            <ListItem button>
              <ListItemIcon className="menuicon">
                <SendIcon />
              </ListItemIcon>
              <ListItemText inset primary="Live Report" />
            </ListItem>
            <ListItem
              button
              onClick={() => this.onMenuToggle("leftReportsMenu")}
            >
              <ListItemIcon className="menuicon">
                <SendIcon />
              </ListItemIcon>
              <ListItemText inset primary="Reports" />
              {leftReportsMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={leftReportsMenu} timeout="auto" unmountOnExit>
              <List component="div">
                <ListItem button className="submenu">
                  <ListItemIcon className="menuicon">
                    <SendIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="Km Report" />
                </ListItem>
                <ListItem button className="submenu">
                  <ListItemIcon className="menuicon">
                    <SendIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="Km Report" />
                </ListItem>
                <ListItem button className="submenu">
                  <ListItemIcon className="menuicon">
                    <SendIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="Km Report" />
                </ListItem>
                <ListItem button className="submenu">
                  <ListItemIcon className="menuicon">
                    <SendIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="Km Report" />
                </ListItem>
              </List>
            </Collapse>
          </List>
        </SwipeableDrawer>
        <FleetDialog
          open={logoutDialog}
          onCloseSettings={this.toggleLogoutDialog}
          title={LABELS.LOGOUT}
          maxWidth="sm"
          fullWidth={true}
          saveButton={true}
          cancelButton={true}
          cancelAction={this.toggleLogoutDialog}
          saveAction={this.logout}
          saveBtnLabel="OK"
          classNameWrapper="logoutWrapper"
        >
          <p>{CONFIRMS.CONFIRM_LOGOUT}</p>
        </FleetDialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
    pageReducer: state.pageReducer,
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSettingsMenu: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_SETTINGS_MENU,
        values,
      });
    },
    setLeftBar: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_LEFTBAR,
        values,
      });
    },
    setLeftMapMenu: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_MAP_MENU,
        values,
      });
    },
    toggleFullScreen: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_FULLSCREEN,
        values,
      });
    },
    setMapLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_MAP_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(LeftBar));
