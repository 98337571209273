import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { Navigation as AnimationIcon } from "@material-ui/icons";
import { connect } from "react-redux";

import { COMMON_ACTIONS } from "../../../actions/CommonActions";

import { API_Map } from "../../apihandler/Map";
import { MAP_ACTIONS } from "../../../actions/MapActions";

class MovingMarkerButton extends Component {
  toggleBtn = () => {
    const status = document
      .querySelector("div#animationBtnWrapper button")
      .classList.contains("active");
    document
      .querySelector("div#animationBtnWrapper button")
      .classList.toggle("active");

    this.props.toggleMarkerAnimation({
      markerAnimation: !status,
    });

    if (!status) {
      let self = this;
      setTimeout(() => {
        self.loadVehicles();
      }, 2 * 1000);
    }
  };

  loadVehicles = async () => {
    var self = this;
    const opts = {};
    opts.pageNo = 0;
    opts.pageLength = 100000;
    opts.orderBy = "ASC";
    opts.sortColumn = "";
    API_Map.getAllVehicles(opts)
      .then((res) => {
        self.props.setVehicles({
          vehicles: res,
        });
      })
      .catch((err) => {});
  };

  render() {
    return (
      <div id="animationBtnWrapper">
        <Tooltip title={"Vehicle Animation"} placement="right">
          <IconButton
            classes={{ root: "" }}
            className={""}
            onClick={this.toggleBtn}
          >
            <AnimationIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleMarkerAnimation: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_MARKER_ANIMATION,
        values,
      });
    },
    setVehicles: (values) => {
      dispatch({
        type: COMMON_ACTIONS.SET_VEHICLES,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MovingMarkerButton);
