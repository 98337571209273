import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { CONFIG } from "../common/consts";
import { withSnackbar } from "notistack";

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmPassword: false,
    };
  }

  toggleChangePasswordDialog = () => {};

  saveAccount = () => {};

  toggleNewPassword = () => {
    const { confirmPassword } = this.state;
    this.setState({
      confirmPassword: !confirmPassword,
    });
  };

  render() {
    const { loginReducer } = this.props;
    const { logo, userName } = loginReducer;

    return (
      <div id="myAccount" className="grid">
        <div className="row">
          <img
            src={CONFIG.BASE_URL + logo}
            width="150"
            id="myAccountProfile"
            alt=""
          />
        </div>
        <div className="row">
          <div className="w50" style={{ paddingRight: 10 }}>
            <TextField
              id="userName"
              placeholder="Name"
              className="txt"
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={userName}
            />
          </div>
          <div className="w50">
            <TextField
              id="companyName"
              placeholder="Company Name"
              className="txt"
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={""}
            />
          </div>
        </div>
        <div className="row">
          <div className="w50" style={{ paddingRight: 10 }}>
            <TextField
              id="companyPostalCode"
              placeholder="Postal Code"
              className="txt"
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={""}
            />
          </div>
          <div className="w50">
            <TextField
              id="companyCity"
              placeholder="City"
              className="txt"
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={""}
            />
          </div>
        </div>
        <div className="row">
          <div className="w50" style={{ paddingRight: 10 }}>
            <TextField
              id="companyState"
              placeholder="State"
              className="txt"
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={""}
            />
          </div>
          <div className="w50">
            <TextField
              id="companyPhone"
              placeholder="Phone Number"
              className="txt"
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={""}
            />
          </div>
        </div>
        <div className="row">
          <div className="w50" style={{ width: "49%" }}>
            <TextField
              id="companyEmail"
              placeholder="E-mail"
              className="txt"
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={""}
            />
          </div>
        </div>
        <div className="row">
          <TextField
            id="companyAddress"
            placeholder="Address"
            className="txt"
            variant="outlined"
            size="small"
            multiline
            rows={3}
            onMouseDown={(e) => e.stopPropagation()}
            defaultValue={""}
          />
        </div>

        {/* <div className="row">
          <Button
            variant="outlined"
            className="font-12 settingsBtn"
            color="primary"
            onClick={this.saveAccount}
          >
            Save
          </Button>
        </div>
        <div className="row">
          <label
            style={{
              fontSize: 14,
              fontWeight: 500,
              marginTop: 10,
              color: "#800000",
              display: "flex",
              flex: 1,
              paddingTop: 8,
              paddingBottom: 8
            }}
          >
            Change Password:
          </label>
        </div>
        <div className="row">
          <div
            className="w50"
            style={{ width: "49%", marginTop: 5, marginBottom: 5 }}
          >
            <TextField
              id="currentPassword"
              placeholder="Current Password"
              className="txt"
              variant="outlined"
              size="small"
              onMouseDown={e => e.stopPropagation()}
              defaultValue={""}
              type="password"
            />
          </div>
        </div>
        <div className="row">
          <div className="w50" style={{ width: "49%" }}>
            <TextField
              id="newPassword"
              placeholder="New Password"
              className="txt"
              variant="outlined"
              size="small"
              onMouseDown={e => e.stopPropagation()}
              defaultValue={""}
              type={confirmPassword ? "text" : "password"}
            />
          </div>
        </div>
        <div className="row">
          <FormControlLabel
            control={
              <Checkbox
                checked={confirmPassword}
                onChange={this.toggleNewPassword}
                value={false}
                color="primary"
              />
            }
            label="Confirm"
          />
        </div>
        <div className="row">
          <Button
            variant="outlined"
            className="font-12 settingsBtn"
            color="primary"
            onClick={this.saveAccount}
          >
            Save
          </Button>
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(MyAccount));
