import { SdkClient } from "fgxsdk";
import { CONFIG } from "../common/consts";
import axios from "axios";

SdkClient.setUrl(CONFIG.BASE_URL);

export const API_FireApp = {
  missedCall: async (args) => {
    return SdkClient.callAlerts(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getJanusToken: async () => {
    return SdkClient.webrtcToken()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getUsers: async (args) => {
    return SdkClient.users()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  saveIncident: async (args) => {
    return axios
      .post(CONFIG.BASE_URL + "/api/v2/incident/add", args, {
        withCredentials: true,
        headers: {
          "fgx-api-session": localStorage.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getIncidents: async (args) => {
    return axios
      .post(CONFIG.BASE_URL + "/api/v2/incident/list", args, {
        withCredentials: true,
        headers: {
          "fgx-api-session": localStorage.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  deleteIncident: async (args) => {
    return axios
      .post(CONFIG.BASE_URL + "/api/v2/incident/delete", args, {
        withCredentials: true,
        headers: {
          "fgx-api-session": localStorage.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  sendBroadcastMessage: async (args) => {
    return axios
      .post(CONFIG.BASE_URL + "/api/v2/incident/broadcast", args, {
        withCredentials: true,
        headers: {
          "fgx-api-session": localStorage.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  setAction: async (args) => {
    return axios
      .post(CONFIG.BASE_URL + "/api/v2/incident/action", args, {
        withCredentials: true,
        headers: {
          "fgx-api-session": localStorage.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  isIncidentOpen: async (args) => {
    return SdkClient.isIncidentOpen(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
};
