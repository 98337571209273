import React, { Component } from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { dateFunction } from "../common/datefunctions";
import { API_Reports } from "../apihandler/Reports";
import { API_UserLogin } from "../apihandler/UserLogin";
import { FORMATS } from "../common/consts";
import { commonJs } from "../common/common";

class KmGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphData: [],
      loading: true,
      totalKms: 0,
      fdt: dateFunction.getDate("thismonth", "from", FORMATS.SQL_DATE),
      tdt: dateFunction.getDate("thismonth", "to", FORMATS.SQL_DATE),
    };
  }

  componentDidMount() {
    API_UserLogin.setToken(localStorage.token);
    this.getKmData();
  }

  getKmData = () => {
    const { fdt, tdt } = this.state;
    let args = {
      fromDate: fdt,
      toDate: tdt,
    };
    let self = this;
    API_Reports.getKmGraph(args)
      .then((res) => {
        if (res.Result === 200) {
          let rows = res.Response.rows;
          let sum = 0;
          rows.map((row) => {
            if (row.length > 1) {
              row[1] = Number(row[1]);
              sum += row[1];
            }
            return row;
          });
          self.setState({
            graphData: rows,
            loading: false,
            totalKms: sum,
          });
        }
      })
      .catch((err) => {
        self.setState({
          loading: false,
        });
      });
  };

  render() {
    const { graphData, totalKms } = this.state;
    const title =
      totalKms === 0
        ? ""
        : "Total: " + commonJs.getTwoDecimalValue(totalKms) + " Kms";
    const options = {
      chart: {
        animation: Highcharts.svg,
        marginRight: 10,
        marginLeft: 40,
        zoomType: "x",
        type: "column",
      },
      credits: false,
      title: {
        text: title,
        margin: 15,
        style: {
          fontSize: "16px",
          fontWeight: 500,
        },
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        type: "category",
        labels: {
          rotation: -45,
          style: {
            fontSize: "12px",
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
          style: {
            fontSize: "12px",
          },
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        pointFormat: "Distance: <b>{point.y:.2f} Kms</b>",
      },
      exporting: {
        enabled: false,
      },
      series: [
        {
          name: "Kms",
          data: graphData,
          color: "#6b6bdec2",
          dataLabels: {
            enabled: true,
            rotation: -90,
            color: "#2d2d2d",
            align: "center",
            format: "{point.y:.2f}", // two decimal
            y: -15, // -30 pixels down from the top
            style: {
              fontSize: "12px",
            },
          },
        },
      ],
    };
    return (
      <div style={{ width: "100%" }}>
        <h4 className="subtitle">KM Graph Report</h4>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          style={{ height: "100%" }}
          id="trackChart"
        />
      </div>
    );
  }
}

export default KmGraph;
