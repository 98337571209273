import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// import Divider from "@material-ui/core/Divider";
import { connect } from "react-redux";

// Custom Components
import VehiclesTab from "./vehicles/";

import UsersTab from "./userstab/";

import TrackTab from "./tracktab/TrackTab";

import "./maptab.css";
import { MAP_ACTIONS } from "../../../../actions/MapActions";
import { LOCAL_STORAGE, CONFIG } from "../../../common/consts";
import { PAGE_ACTIONS } from "../../../../actions/PageActions";

import fleetLogo from "../../../../images/logo/logo.png";

var timer = null;
// const getChildGroups = groups =>
//   groups.map(group => ({ key: group.key, childRows: group.items }));

class MapPageTabs extends Component {
  constructor(props) {
    super(props);
    var activeTab = 0;
    let tabCache = localStorage.getItem(LOCAL_STORAGE.MAP_TAB);
    if (tabCache != null) {
      tabCache = JSON.parse(tabCache);
      if (tabCache.activeTab) {
        activeTab = tabCache.activeTab;
      }
    }
    this.state = {
      activeTab: activeTab,
      cityToggle: false,
      searchVehicle: "",
      vehicleList: this.props.vehicleList,
      sortby: "vehicle asc",
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  handleSortChange = (event) => {
    const type = event.target.value.split(" ");
    if (type[0] === "vehicle") {
      var vehicles = this.props.mapReducer.tmpVehicles;
      if (this.props.mapReducer.filteredVehicles.length > 0) {
        vehicles = this.props.mapReducer.filteredVehicles;
      }
      vehicles.sort(function(a, b) {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (type[1] === "asc") {
          if (nameA < nameB)
            //sort string ascending
            return -1;
          if (nameA > nameB) return 1;
        } else {
          if (nameA > nameB)
            //sort string descending
            return -1;
          if (nameA < nameB) return 1;
        }
        return 0; //default return value (no sorting)
      });
      this.props.setFilteredVehicles({
        filteredVehicles: vehicles,
      });
    }
    this.setState({ sortby: event.target.value });
  };

  handleChange = (event, value) => {
    let tabJson = localStorage[LOCAL_STORAGE.MAP_TAB];
    if (tabJson == null) {
      tabJson = {
        activeTab: value,
      };
    } else {
      tabJson = JSON.parse(tabJson);
      tabJson.activeTab = value;
    }
    localStorage[LOCAL_STORAGE.MAP_TAB] = JSON.stringify(tabJson);
    this.setState({ activeTab: value });

    if (value !== 3) {
      //TODO: Not Track Page, -> Hide Bottom Bar (ZIndex Issue)
      this.props.toggleBottomBar({
        trackBottomBar: false,
      });
    }
  };

  toggleCityPanel = () => {
    this.setState({
      cityToggle: !this.state.cityToggle,
    });
  };

  onVehicleSearch = (e) => {
    clearTimeout(timer);
    var self = this;
    const searchValue = e.target.value.trim();
    timer = setTimeout(() => {
      let qry = searchValue.toUpperCase();
      var list = self.props.mapReducer.tmpVehicles.filter((arr) => {
        return arr.name.toUpperCase().indexOf(qry) > -1;
      });
      if (list.length === 0 && qry !== "") {
        list.push(0);
      }
      self.props.setFilteredVehicles({
        filteredVehicles: list,
      });
    }, 500);
  };

  switchTab = (tabIndex, page = "") => {
    this.setState({
      activeTab: tabIndex,
    });
    if (page === "track") {
      var self = this;
      setTimeout(() => {
        self._trackTab.showTrack();
      }, 500);
    }
  };

  render() {
    const { activeTab } = this.state;
    return (
      <div className="mapTabWrapper">
        <h4 className="mapTabTitle">
          <span style={{ marginRight: 5 }}>
            <img src={fleetLogo} width={50} alt="" />
          </span>
          <span>
            {CONFIG.SITE_NAME}
            <small>{" v" + CONFIG.VERSION}</small>
          </span>
        </h4>
        <Paper square className="mapTabContainer">
          <Tabs
            value={activeTab}
            classes={{
              root: "mapTabs",
              indicator: "fleettabindicator",
            }}
            scrollButtons="off"
            centered={false}
            onChange={this.handleChange}
          >
            <Tab
              label="Vehicles"
              classes={{
                root: "maptab",
                // label: "mapTabLabel"
              }}
              className={activeTab === 0 ? "activeTab" : ""}
              key={"maintab-" + 0}
              disabled={false}
            />
            <Tab
              label="Users"
              classes={{
                root: "maptab",
                // label: "mapTabLabel"
              }}
              className={activeTab === 1 ? "activeTab" : ""}
              key={"maintab-" + 3}
              disabled={false}
            />
            {/* <Tab
              label="POI"
              classes={{
                root: "maptab"
                // label: "mapTabLabel"
              }}
              className={activeTab === 1 ? "activeTab" : ""}
              key={"maintab-" + 1}
              disabled={false}
            /> */}
            <Tab
              label="Track Records"
              classes={{
                root: "maptab",
                // label: "mapTabLabel"
              }}
              className={activeTab === 2 ? "activeTab" : ""}
              key={"maintab-" + 2}
              disabled={false}
            />
          </Tabs>

          {activeTab === 0 && (
            <VehiclesTab
              updateMapRegion={this.props.updateMapRegion}
              switchTab={this.switchTab}
            />
          )}

          {/* {activeTab === 1 && (
            <div className="tabContent">
              <PoiTab />
            </div>
          )} */}

          {activeTab === 2 && (
            <div className="tabContent">
              <TrackTab
                childRef={(ref) => (this._trackTab = ref)}
                updateMapRegion={this.props.updateMapRegion}
                trackFitBounds={this.props.trackFitBounds}
              />
            </div>
          )}

          {activeTab === 1 && (
            <div className="tabContent">
              <UsersTab />
            </div>
          )}
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFilteredVehicles: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_FILTERED_VEHICLES,
        values,
      });
    },
    toggleBottomBar: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_TRACK_BOTTOM_BAR,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapPageTabs);
