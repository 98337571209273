import React, { Component } from "react";
import { Button, Tooltip, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import SortIcon from "@material-ui/icons/Sort";

// Custom Components
import Img from "../../../../ui/image/Img";
import SearchButton from "../../../../ui/searchbutton/SearchButton";
import FilterGroup from "./FilterGroup";

// Icons
import FilterByIcon from "../images/filter.svg";

import {
  AddCircleOutline,
  RemoveCircleOutline,
  Visibility,
  Replay,
} from "@material-ui/icons";

import "../../maptabs/maptab.css";
import { MAP_ACTIONS } from "../../../../../actions/MapActions";
import { commonJs } from "../../../../common/common";
import { API_Map } from "../../../../apihandler/Map";
import { NOTIFY, ALERTS } from "../../../../common/consts";
import { PAGE_ACTIONS } from "../../../../../actions/PageActions";
import { COMMON_ACTIONS } from "../../../../../actions/CommonActions";
import { TRACK_ACTIONS } from "../../../../../actions/TrackActions";

var timer = null;

class VehiclesFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleSort: false,
      vehicleFilter: false,
    };
  }

  loadExpandIcon = () => {
    return <AddCircleOutline />;
  };

  loadLeftIcon = () => {
    return <Visibility />;
  };

  loadMinimizeIcon = () => {
    return <RemoveCircleOutline />;
  };

  toggleVehicleFilter = () => {
    const { vehicleFilter } = this.state;
    this.setState({
      vehicleFilter: !vehicleFilter,
    });
  };

  onVehicleSearch = () => {
    clearTimeout(timer);
    var self = this;
    const searchValue = document.getElementById("vehicleSearch").value.trim();
    timer = setTimeout(() => {
      let qry = searchValue.toUpperCase();
      var list = self.props.mapReducer.tmpVehicles.filter((arr) => {
        return arr.name.toUpperCase().indexOf(qry) > -1;
      });
      if (list.length === 0 && qry !== "") {
        list.push(0);
      }
      self.props.setFilteredVehicles({
        filteredVehicles: list,
      });
    }, 500);
  };

  toggleVehicleSort = () => {
    const { vehicleSort } = this.state;
    var self = this;
    this.setState(
      {
        vehicleSort: !vehicleSort,
      },
      () => {
        setTimeout(() => {
          self.doSort(self.state.vehicleSort);
        }, 1000);
      }
    );
  };

  doSort = (type) => {
    const { mapReducer } = this.props;
    const { signalFilter, loadStatusFilter, tmpVehicles } = mapReducer;
    let vehicles = commonJs.getVehicleFilteredList(
      tmpVehicles,
      signalFilter,
      loadStatusFilter
    );
    vehicles.sort(function(a, b) {
      var nameA = a.name.toLowerCase();
      var nameB = b.name.toLowerCase();
      if (!type) {
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
      } else {
        if (nameA > nameB)
          //sort string descending
          return -1;
        if (nameA < nameB) return 1;
      }
      return 0; //Equal: default return value (no sorting)
    });
    this.props.setFilteredVehicles({
      filteredVehicles: vehicles,
    });
  };

  closeSortFilter = () => {
    this.setState({
      vehicleSort: false,
    });
  };

  closeGroupsFilter = () => {
    this.setState({
      vehicleFilter: false,
    });
  };

  loadReloadIcon = () => {
    return <Replay />;
  };

  reloadVehicles = () => {
    // Reset All
    this.props.setLoading({
      loading: true,
    });
    this.props.setVehicles({
      vehicles: [],
    });
    this.props.resetTrackData({
      routeDetails: {},
      routePoints: [],
      routeSummary: [],
      trackBottomBar: false,
    });
    this.loadVehicles(true);
  };

  loadVehicles = async (reset = false) => {
    var self = this;
    const opts = {};
    opts.pageNo = 0;
    opts.pageLength = 100000;
    opts.orderBy = "ASC";
    opts.sortColumn = "";
    API_Map.getAllVehicles(opts)
      .then((res) => {
        self.props.setVehicles({
          vehicles: res,
        });
        if (reset) {
          // Reset temp data as well
          self.props.resetVehicles({
            vehicles: res,
          });
          self.props.setLoading({ loading: false });
        }
      })
      .catch((err) => {
        self.props.setLoading({ loading: false });
        err = err.toString();
        if (err.indexOf("Network Error") > -1) {
          commonJs.showNotify(self.props, NOTIFY.WARNING, ALERTS.NO_INTERNET);
        }
      });
  };

  render() {
    const { vehicleSort, vehicleFilter } = this.state;

    return (
      <React.Fragment>
        <div id="vehicleSearchBox">
          <SearchButton
            id="vehicleSearch"
            ref={(input) => {
              this.vehicleSearch = input;
            }}
            placeholder="Search By Vehicle Name"
            buttonClass="searchBtn"
            inputClass="SearchInput"
            iconwidth="18"
            iconheight="18"
            onChange={this.onVehicleSearch}
          />
          <div className="reload-vehicles">
            <Tooltip title="Reload">
              <IconButton onClick={this.reloadVehicles}>
                {this.loadReloadIcon()}
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <ul className="vehicleFilters">
          <li>
            <Button
              className={"pad5" + (vehicleFilter ? " active" : "")}
              onClick={this.toggleVehicleFilter}
              style={{ display: "none" }}
            >
              Filters
              <Img
                src={FilterByIcon}
                width="18"
                height="18"
                className="filterIcon"
              />
            </Button>
            <div
              id="vehicleFilterBox"
              className={vehicleFilter ? " active" : ""}
            >
              <FilterGroup />
            </div>
          </li>
          <li>
            <Button className={"pad5 sortBy"} onClick={this.toggleVehicleSort}>
              Sort By
              <SortIcon className={vehicleSort ? "desc" : "asc"} />
            </Button>
          </li>
        </ul>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFilteredVehicles: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_FILTERED_VEHICLES,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_MAP_LOADING,
        values,
      });
    },
    resetVehicles: (values) => {
      dispatch({
        type: MAP_ACTIONS.RESET_VEHICLES,
        values,
      });
    },
    setVehicles: (values) => {
      dispatch({
        type: COMMON_ACTIONS.SET_VEHICLES,
        values,
      });
    },
    resetTrackData: (values) => {
      dispatch({
        type: TRACK_ACTIONS.SET_TRACK_DATA,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehiclesFilter);
