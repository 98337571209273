import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import {
  CircularProgress,
  Link,
  Breadcrumbs,
  Backdrop,
} from "@material-ui/core";

import jQuery from "jquery";
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.flash";
import "pdfmake/build/vfs_fonts";
import "pdfmake/build/pdfmake";

import ReportTopbar from "../layouts/ReportTopbar";
import ReportLeftBar from "../layouts/ReportLeftBar";
import ReportFooter from "../layouts/ReportFooter";

import "../reports.css";
import "../datatables.min.css";

import AutoComplete from "../../ui/autocomplete/AutoComplete";
import Btn from "../../ui/button/Btn";
import { API_Map } from "../../apihandler/Map";
import { API_UserLogin } from "../../apihandler/UserLogin";
import { COMMON_ACTIONS } from "../../../actions/CommonActions";
import { REPORT_ACTIONS } from "../../../actions/ReportActions";
import { commonJs } from "../../common/common";
import {
  NOTIFY,
  ALERTS,
  REPORT_DESCRIPTION,
  REPORT_NAMES,
} from "../../common/consts";

const DefaultDismissBtn = () => {
  return <button className="snackbar-btn">{"Dismiss"}</button>;
};

var $ = jQuery;

class VehiclesInfoReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicles: [],
      selectedVehicle: [{ id: "", name: "All Vehicles" }],
    };
  }

  componentDidMount() {
    document.getElementById("root").classList.add("scroll");
    this.getInitialData();
  }

  getInitialData = () => {
    const args = {};
    let self = this;
    args.pageNo = 0;
    args.pageLength = 100000;
    args.orderBy = "ASC";
    args.sortColumn = "";
    API_UserLogin.setToken(localStorage.token);
    API_Map.getAllVehicles(args)
      .then((res) => {
        self.props.setVehicles({
          vehicles: res,
        });
        self.props.setLoading({
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onChangeVehicle = (vehicle) => {
    this.setState({
      selectedVehicle: vehicle,
    });
  };

  loadReport = () => {
    const { selectedVehicle } = this.state;

    let imeis = [];

    for (var i = 0; i < selectedVehicle.length; i++) {
      if (selectedVehicle[i].id === "") {
        const { vehiclesList } = this.props.commonReducer;
        for (var v = 0; v < vehiclesList.length; v++) {
          imeis.push(vehiclesList[v].imei);
        }
      } else {
        imeis.push(selectedVehicle[i].id);
      }
    }

    if (imeis.length === 0) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        ALERTS.PLEASE_SELECT_VEHICLE,
        <DefaultDismissBtn />
      );
      return;
    }

    var uniqueImeis = commonJs.getUniqueArray(imeis);

    const { vehiclesList } = this.props.commonReducer;
    let rows = [];
    let columns = [
      {
        title: "Sl No.",
      },
      {
        title: "Vehicle No.",
      },
      {
        title: "IMEI",
      },
      {
        title: "SIM Number",
      },
      {
        title: "Vehicle Type",
      },
      {
        title: "Plate Number",
      },
      {
        title: "Driver Name",
      },
      {
        title: "Driver Number",
      },
    ];

    for (let i = 0; i < uniqueImeis.length; i++) {
      let rslt = commonJs.getVehicleFromArray(vehiclesList, uniqueImeis[i]);
      if (rslt.length > 0) {
        rows.push([
          i + 1,
          rslt[0].name,
          rslt[0].imei,
          rslt[0].simno,
          rslt[0].vin,
          rslt[0].plateno,
          rslt[0].drivername,
          rslt[0].driverno,
        ]);
      }
    }

    if ($.fn.dataTable.isDataTable("#reportGrid")) {
      let table = $("#reportGrid").DataTable();
      table.destroy();
    }
    $("#reportGrid").empty();

    let title = "Vehicle Info Report";

    $("#reportGrid").DataTable({
      dom: "Bfrtip",
      buttons: [
        {
          extend: "copy",
          className: "reportBtn",
          title: title,
        },
        {
          extend: "pdf",
          className: "reportBtn",
          title: title,
        },
        {
          extend: "excel",
          className: "reportBtn",
          title: title,
        },
        {
          extend: "csv",
          className: "reportBtn",
          title: title,
          text: "CSV",
        },
        {
          extend: "print",
          className: "reportBtn",
          title: title,
          customize: function(win) {
            $(win.document.body)
              .find("h1")
              .remove();
            $(win.document.body)
              .find("table")
              .attr("cellpadding", "3");
            $(win.document.body)
              .find("table")
              .css("font-size", "10px");
            $(win.document.body)
              .find("table")
              .css(
                "cssText",
                "border-bottom: none;border-left: none;border-right: none;"
              );
            $(win.document.body)
              .find("td")
              .css("font-size", "8px");
            $(win.document.body)
              .find("th")
              .css("font-size", "8px");
            $(win.document.body)
              .find("tbody td")
              .css(
                "cssText",
                "border: 1px solid #000;border-top: 0;border-right: 0;"
              );
            $(win.document.body)
              .find("tbody td:last-child")
              .css("cssText", "border: 1px solid #000;border-top: 0;");
            $(win.document.body)
              .find("table th")
              .css("cssText", "border: 1px solid #000;border-right: 0;");
            $(win.document.body)
              .find("table th:last-child")
              .css("cssText", "border: 1px solid #000;");
          },
        },
      ],
      paging: false,
      scrollY: 400,
      columns: columns,
      data: rows,
    });
  };

  render() {
    const { vehiclesList } = this.props.commonReducer;

    const { loading } = this.props.reportReducer;

    const { selectedVehicle } = this.state;

    let vehicles = [];
    vehicles.push({ id: "", name: "All Vehicles" });
    for (var i = 0; i < vehiclesList.length; i++) {
      vehicles.push({ id: vehiclesList[i].imei, name: vehiclesList[i].name });
    }

    return (
      <div className="reports">
        <ReportTopbar />
        <ReportLeftBar />
        <div className="reportPageWrapper height100">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Link color="inherit" href="/">
              Home
            </Link>
            <p>{REPORT_NAMES.VEHICLE_INFO_REPORT}</p>
          </Breadcrumbs>
          <p className="reportDescription">
            {REPORT_DESCRIPTION.VEHICLE_INFO_REPORT}
          </p>
          <div className="reportFilters">
            <div className="width50">
              <AutoComplete
                label={"Select Vehicle"}
                list={vehicles}
                id="trackVehicles"
                onChange={this.onChangeVehicle}
                defaultValue={selectedVehicle}
                multiple={true}
                size="small"
              />
            </div>
            <div className="width10 padLeft-10">
              <Btn onClick={this.loadReport} className="primaryBtn">
                Submit
              </Btn>
            </div>
          </div>
          <div className="reportGridWrapper">
            <table
              id="reportGrid"
              className="mdl-data-table width100"
              cellSpacing="0"
              cellPadding="3"
            />
          </div>
        </div>

        <ReportFooter />

        <Backdrop className={"backdrop"} open={loading}>
          <CircularProgress className="whiteLoader" />
        </Backdrop>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commonReducer: state.commonReducer,
    reportReducer: state.reportReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setVehicles: (values) => {
      dispatch({
        type: COMMON_ACTIONS.SET_VEHICLES,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: REPORT_ACTIONS.SET_LOADING,
        values,
      });
    },
    toggleReportPopup: (values) => {
      dispatch({
        type: REPORT_ACTIONS.TOGGLE_REPORT_POPUP,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(VehiclesInfoReport));
