// Reports
import { SdkClient } from "fgxsdk";
import { CONFIG } from "../common/consts";
import axios from "axios";

SdkClient.setUrl(CONFIG.BASE_URL);

export const API_Reports = {
  getKmGraph: async (args) => {
    return SdkClient.getReport("kmgraph", args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw new Error(err);
      });
  },
  getDailyTravel: async (args) => {
    return SdkClient.getReport("kmgraph", args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw new Error(err);
      });
  },
  getJourneyReport: async (args) => {
    return SdkClient.getReport("journey", args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw new Error(err);
      });
  },
  getIncidentReport: async (args) => {
    return axios
      .post(CONFIG.BASE_URL + "/api/v2/reports/incident", args, {
        withCredentials: true,
        headers: {
          "fgx-api-session": localStorage.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err);
      });
  },
  getKmGraphReport: async (args) => {
    return axios
      .post(CONFIG.BASE_URL + "/api/v2/reports/currentmonth", args, {
        withCredentials: true,
        headers: {
          "fgx-api-session": localStorage.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err);
      });
  },
};
