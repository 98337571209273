import React, { memo } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const Loading = props => {
  return (
    <React.Fragment>
      {/* <TopProgressBar className={"showprogress"} /> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          height: "100%",
          backgroundColor: "#FFF",
          zIndex: 99999999
        }}
      >
        <CircularProgress className={"primary"} />

        {/* {props.children || <p>Loading...</p>} */}
      </div>
    </React.Fragment>
  );
};

export default memo(Loading);
