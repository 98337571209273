import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import {
  CircularProgress,
  Link,
  Breadcrumbs,
  Backdrop,
} from "@material-ui/core";

import jQuery from "jquery";

import ReportTopbar from "../layouts/ReportTopbar";
import ReportLeftBar from "../layouts/ReportLeftBar";
import ReportFooter from "../layouts/ReportFooter";

import "../reports.css";
// import "../datatables.min.css";

import AutoComplete from "../../ui/autocomplete/AutoComplete";
import Btn from "../../ui/button/Btn";

import { API_Map } from "../../apihandler/Map";
import { API_UserLogin } from "../../apihandler/UserLogin";
import { COMMON_ACTIONS } from "../../../actions/CommonActions";
import { REPORT_ACTIONS } from "../../../actions/ReportActions";
import { NOTIFY, ALERTS } from "../../common/consts";
import { commonJs } from "../../common/common";

const DefaultDismissBtn = () => {
  return <button className="snackbar-btn">{"Dismiss"}</button>;
};

var $ = jQuery;

class VehiclesInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicles: [],
      reportType: "",
      selectedVehicle: [{ id: "", name: "All Vehicles" }],
    };
  }

  onMenuClick = (menu) => {};

  showDetails = (type) => {
    this.setState({
      reportType: type,
    });
  };

  componentDidMount() {
    document.getElementById("root").classList.add("scroll");
    this.getDashboardData();
  }

  getDashboardData = () => {
    const args = {};
    let self = this;
    args.pageNo = 0;
    args.pageLength = 100000;
    args.orderBy = "ASC";
    args.sortColumn = "";
    API_UserLogin.setToken(localStorage.token);
    API_Map.getAllVehicles(args)
      .then((res) => {
        self.props.setVehicles({
          vehicles: res,
        });
        self.props.setLoading({
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onChangeVehicle = (vehicle) => {
    this.setState({
      selectedVehicle: vehicle,
    });
  };

  loadReport = () => {
    const { selectedVehicle } = this.state;
    let imeis = [];
    for (var i = 0; i < selectedVehicle.length; i++) {
      if (selectedVehicle[i].id === "") {
        const { vehiclesList } = this.props.commonReducer;
        for (var v = 0; v < vehiclesList.length; v++) {
          imeis.push(vehiclesList[v].imei);
        }
      } else {
        imeis.push(selectedVehicle[i].id);
      }
    }
    if (imeis.length === 0) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        ALERTS.PLEASE_SELECT_VEHICLE,
        <DefaultDismissBtn />
      );
      return;
    }

    // this.props.setLoading({
    //   loading: true,
    // });

    if ($.fn.dataTable.isDataTable("#reportGrid")) {
      var table = $("#reportGrid").DataTable();
      table.destroy();
    }

    $("#reportGrid").empty();

    $("#reportGrid").dataTable({
      dom: "Bfrtip",
      pageLength: 100,
      columns: [
        {
          title: "Sl No.",
        },
      ],
      data: [],
    });
  };

  render() {
    const { vehiclesList } = this.props.commonReducer;

    const { loading } = this.props.reportReducer;

    const { selectedVehicle } = this.state;

    let vehicles = [];
    vehicles.push({ id: "", name: "All Vehicles" });
    for (var i = 0; i < vehiclesList.length; i++) {
      vehicles.push({ id: vehiclesList[i].imei, name: vehiclesList[i].name });
    }

    return (
      <div className="reports">
        <ReportTopbar />
        <ReportLeftBar />
        <div className="reportPageWrapper height100">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Link
              color="inherit"
              href="/"
              onClick={() => this.onMenuClick("home")}
            >
              Home
            </Link>
            <p>Consolidated Report</p>
          </Breadcrumbs>
          <p className="reportDescription">This report will show</p>
          <div className="reportFilters">
            <div className="width25">
              <AutoComplete
                label={"Select Vehicle"}
                list={vehicles}
                id="trackVehicles"
                style={{ marginBottom: 15, marginTop: 15 }}
                onChange={this.onChangeVehicle}
                defaultValue={selectedVehicle}
                multiple={true}
                size="small"
              />
            </div>
            <div className="width10 padLeft-10">
              <Btn onClick={this.loadReport} className="primaryBtn">
                Submit
              </Btn>
            </div>
          </div>
          <div class="reportGridWrapper">
            <table id="reportGrid" class="mdl-data-table width100"></table>
          </div>
        </div>

        <ReportFooter />

        <Backdrop className={"backdrop"} open={loading}>
          <CircularProgress className="whiteLoader" />
        </Backdrop>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commonReducer: state.commonReducer,
    reportReducer: state.reportReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setVehicles: (values) => {
      dispatch({
        type: COMMON_ACTIONS.SET_VEHICLES,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: REPORT_ACTIONS.SET_LOADING,
        values,
      });
    },
    toggleReportPopup: (values) => {
      dispatch({
        type: REPORT_ACTIONS.TOGGLE_REPORT_POPUP,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(VehiclesInfo));
