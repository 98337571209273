import { SdkClient } from "fgxsdk";
import { CONFIG } from "../common/consts";

SdkClient.setUrl(CONFIG.BASE_URL);

export const API_UserLogin = {
  setToken: async function(token) {
    if(!token)
      return;
      
    localStorage.setItem('token', token);
    return SdkClient.setToken(token);
  },

  getToken: async function() {
    let token = SdkClient.getToken();
    if (!token) {
      token = localStorage.getItem('token');
      if(!token)
        return null;
      SdkClient.setToken(token);
    }

    return token;
  },

  login: async function(uname, pwd) {
    return SdkClient.login(uname, pwd)
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log(err);
        throw new Error(err);
      });
  },

  checkLogin: async function() {
    return SdkClient.checkSessionStatus()
      .then(res => {
        return res;
      })
      .catch(err => {
        throw new Error(err);
      });
  },

  logout: async function() {
    return SdkClient.logout()
      .then(res => {
        return res;
      })
      .catch(err => {
        throw new Error(err);
      });
  }
};
