import { SdkClient } from "fgxsdk";
import { CONFIG } from "../common/consts";
import { commonJs } from "../common/common";
import axios from "axios";

SdkClient.setUrl(CONFIG.BASE_URL);

export const API_Map = {
  getVehicleData: async (args) => {
    return SdkClient.getObject(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  getAllVehicles: async function(opts) {
    return SdkClient.getObjects(opts)
      .then((res) => {
        var vlist = [];
        if (res.Result === 200) {
          var arr = res.Response;
          for (var i in arr) {
            arr[i].visible = true;
            let ign = commonJs.getVehicleIgnition(arr[i]);
            //Stop -> Ignition off
            //Idle -> Ignition On & Speed 0
            if (arr[i].status > 0) {
              if (!ign) {
                arr[i].status = 1;
              } else if (ign && arr[i].speed === 0) {
                arr[i].status = 2;
              }
            }
            vlist.push(arr[i]);
          }
        } else {
          vlist = res.Reason;
        }
        return vlist;
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  getAllZones: async () => {
    return SdkClient.getZones()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("getZones: ", err);
        throw new Error(err);
      });
  },

  getAddress: async (lat, lng) => {
    return SdkClient.getAddress({ lat: lat, lng: lng })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("getAddress: ", err);
        throw new Error(err);
      });
  },

  getVehicleTrack: async (args) => {
    return SdkClient.getObjectTrack(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("track: ", err);
        throw new Error(err);
      });
  },

  getVehicleTrack2: async (args) => {
    return axios
      .post("https://avgit.vaschan.com/api/v1/getvehicletrack", args, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err);
      });
  },
};
