import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { Replay as Reload } from "@material-ui/icons";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import SearchButton from "../../../ui/searchbutton/SearchButton";
import IncidentListGrid from "./IncidentListGrid";
import DialogConfirm from "./DialogConfirm";

import { FIREAPP_ACTIONS } from "../../../../actions/FireAppActions";
import { PAGE_ACTIONS } from "../../../../actions/PageActions";
import { API_FireApp } from "../../../apihandler/FireApp";
import { commonJs } from "../../../common/common";
import { NOTIFY } from "../../../common/consts";

class IncidentListTab extends Component {
  onSearch = (e) => {
    this._dataGrid.onSearch(e.target.value.trim());
  };

  routeReload = () => {};

  reload = () => {
    this._dataGrid.onReload();
  };

  openIncidentPopup = (rec) => {
    this.props.setIncidentData({
      incidentRec: rec,
    });
    this.props.togglePopupDialog({
      addIncidentDialog: true,
    });
  };

  deleteRec = (id, name) => {
    this._dialogConfirm.toggleConfirmDialog(id, name);
  };

  onDelete = (id) => {
    let self = this;
    API_FireApp.deleteIncident({ incidentId: id })
      .then((res) => {
        self._dialogConfirm.toggleConfirmDialog("");
        if (res.Result === 200) {
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            "Record deleted successfully..!"
          );
          self._dataGrid.loadRecords();
        } else {
          commonJs.showNotify(
            self.props,
            NOTIFY.ERROR,
            "Record not deleted: " + res.Reason
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <div className="zonesTab" id="incidentSettings">
        <ul>
          <li>
            <SearchButton
              placeholder="Search By Incident Details"
              buttonClass="pad5 iconButton searchBtn"
              inputClass="SearchInput"
              iconwidth="18"
              iconheight="18"
              onChange={this.onSearch}
              id="incidentSearch"
            />
          </li>
          <li className="poiBtn">
            <Tooltip title="Reload" placement="bottom">
              <IconButton onClick={this.reload}>
                <Reload />
              </IconButton>
            </Tooltip>
          </li>
          {/* <li className="poiBtn">
            <Tooltip title="Delete All Incidents" placement="bottom">
              <IconButton
                onClick={() => this._dialogConfirm.toggleConfirmDialog("")}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </li> */}
        </ul>

        <IncidentListGrid
          childRef={(ref) => (this._dataGrid = ref)}
          toggleDialog={(rec) => this.openIncidentPopup(rec)}
          deleteRec={this.deleteRec}
        />

        <DialogConfirm
          childRef={(ref) => (this._dialogConfirm = ref)}
          onDelete={this.onDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIncidentData: (values) => {
      dispatch({
        type: FIREAPP_ACTIONS.SET_INCIDENT_DATA,
        values,
      });
    },
    togglePopupDialog: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_ADD_INCIDENT_DIALOG,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(IncidentListTab));
