import React, { Component } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import "./panel.css";

class FleetPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: this.props.defaultExpand,
      visible: false
    };
  }

  togglePanel = () => {
    this.setState({
      expand: !this.state.expand
    });
  };

  loadLeftIcon = () => {
    return this.props.loadLeftIcon;
  };

  loadExpandIcon = () => {
    return this.props.loadExpandIcon;
  };

  loadMinimizeIcon = () => {
    return this.props.loadMinimizeIcon;
  };

  render() {
    return (
      <React.Fragment>
        <ExpansionPanel
          expanded={this.state.expand}
          classes={{
            root: "expansionPanel " + (this.state.expand ? "open" : "")
          }}
        >
          <ExpansionPanelSummary className="expansionPanelSummary">
            <span className="expansionPanelTitle" onClick={this.togglePanel}>
              {this.props.title}
            </span>
            <div className="toggleIcon" onClick={this.togglePanel}>
              <span className="expand">{this.loadExpandIcon()}</span>
              <span className="minimize">{this.loadMinimizeIcon()}</span>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="expansionPanelDetails">
            {this.props.children}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </React.Fragment>
    );
  }
}
export default FleetPanel;
