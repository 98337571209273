import React, { Component } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  ClickAwayListener,
} from "@material-ui/core";
import { connect } from "react-redux";

import { ExpandLess, ExpandMore } from "@material-ui/icons";

import "./trackmenu.css";
import { PAGE_ACTIONS } from "../../../../../../actions/PageActions";
import { TRACK_ACTIONS } from "../../../../../../actions/TrackActions";
import { DEFAULTS, FORMATS, LOCAL_STORAGE } from "../../../../../common/consts";
import { dateFunction } from "../../../../../common/datefunctions";
import { MAP_ACTIONS } from "../../../../../../actions/MapActions";

class TrackMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClose = (imei) => {
    document.getElementById("trackRecordsMenu").classList.remove("active");
    document.getElementById("voptions-" + imei).classList.remove("active");
  };

  openTrackRecordsMenu = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  openEditVehicle = () => {
    const { imei, pageReducer } = this.props;
    const { vehicleEditPopup } = pageReducer;
    this.closeMenu(imei);
    this.props.toggleEditVehicle({
      vehicleEditPopup: !vehicleEditPopup,
      selectedVehicle: imei,
    });
  };

  closeMenu = (imei) => {
    document.getElementById("voptions-" + imei).classList.toggle("active");
  };

  showTrack = (filter, imei) => {
    const { frmval, toval } = dateFunction.getFromToVal(
      filter,
      FORMATS.SQL_DATE
    );
    this.props.setTrackFilter({
      fdt: frmval,
      tdt: toval,
      selectedTrackDuration: filter,
      stopTime: DEFAULTS.DEFAULT_TRACK_STOPTIME,
    });
    this.props.setSelectedVehicle({
      selectedVehicle: imei,
    });
    this.props.setLoading({
      loadingPopover: true,
    });
    var self = this;
    setTimeout(() => {
      let mapTabCache = localStorage[LOCAL_STORAGE.MAP_TAB];
      if (mapTabCache === null || mapTabCache === undefined) {
        mapTabCache = {
          activeTab: 2,
        };
      } else {
        mapTabCache = JSON.parse(mapTabCache);
        mapTabCache.activeTab = 2;
      }
      localStorage[LOCAL_STORAGE.MAP_TAB] = JSON.stringify(mapTabCache);
      self.props.switchTab(2, "track");
    }, 500);
  };

  render() {
    const { closeTrackMenu, imei } = this.props;
    const { open } = this.state;

    return (
      <ClickAwayListener onClickAway={() => closeTrackMenu}>
        <div className="menulist" id={"voptions-" + imei}>
          <List component="nav">
            <ListItem
              button
              className="menuListItem subMenu"
              onClick={this.openTrackRecordsMenu}
            >
              <ListItemText
                inset
                primary="Track Records"
                className="menuListItemText"
              />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              className="subMenuList"
            >
              <List component="div" disablePadding>
                <ListItem
                  button
                  className="menuListItem"
                  onClick={() => this.showTrack("lasthour", imei)}
                >
                  <ListItemText
                    inset
                    primary="Last hour"
                    className="menuListItemText"
                  />
                </ListItem>
                <ListItem
                  button
                  className="menuListItem"
                  onClick={() => this.showTrack("today", imei)}
                >
                  <ListItemText
                    inset
                    primary="Today"
                    className="menuListItemText"
                  />
                </ListItem>
                <ListItem
                  button
                  className="menuListItem"
                  onClick={() => this.showTrack("yesterday", imei)}
                >
                  <ListItemText
                    inset
                    primary="Yesterday"
                    className="menuListItemText"
                  />
                </ListItem>
                <ListItem
                  button
                  className="menuListItem"
                  onClick={() => this.showTrack("thisweek", imei)}
                >
                  <ListItemText
                    inset
                    primary="This week"
                    className="menuListItemText"
                  />
                </ListItem>
                <ListItem
                  button
                  className="menuListItem"
                  onClick={() => this.showTrack("lastweek", imei)}
                >
                  <ListItemText
                    inset
                    primary="Last week"
                    className="menuListItemText"
                  />
                </ListItem>
                <ListItem
                  button
                  className="menuListItem"
                  onClick={() => this.showTrack("thismonth", imei)}
                >
                  <ListItemText
                    inset
                    primary="This month"
                    className="menuListItemText"
                  />
                </ListItem>
                <ListItem
                  button
                  className="menuListItem"
                  onClick={() => this.showTrack("lastmonth", imei)}
                >
                  <ListItemText
                    inset
                    primary="Last month"
                    className="menuListItemText"
                  />
                </ListItem>
              </List>
            </Collapse>
            {/* <ListItem button className="menuListItem">
              <ListItemText
                inset
                primary="Live Track"
                className="menuListItemText"
              />
            </ListItem>
            <ListItem button className="menuListItem">
              <ListItemText
                inset
                primary="Live Track (new Tab)"
                className="menuListItemText"
              />
            </ListItem>
            <ListItem button className="menuListItem">
              <ListItemText
                inset
                primary="Street View (new tab)"
                className="menuListItemText"
              />
            </ListItem>
            <ListItem button className="menuListItem">
              <ListItemText
                inset
                primary="Send Command"
                className="menuListItemText"
              />
            </ListItem> */}
            <ListItem button className="menuListItem">
              <ListItemText
                inset
                primary="Edit Vehicle"
                className="menuListItemText"
                onClick={this.openEditVehicle}
              />
            </ListItem>
            {/* <ListItem button className="menuListItem">
              <ListItemText
                inset
                primary="Share"
                className="menuListItemText"
              />
            </ListItem> */}
          </List>
        </div>
      </ClickAwayListener>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageReducer: state.pageReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleEditVehicle: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_EDIT_VEHICLE_MENU,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_POPOVER_LOADING,
        values,
      });
    },
    setTrackFilter: (values) => {
      dispatch({
        type: TRACK_ACTIONS.SET_TRACK_FILTER,
        values,
      });
    },
    setSelectedVehicle: (values) => {
      dispatch({
        type: MAP_ACTIONS.SELECT_VEHICLE,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackMenu);
