import { combineReducers } from "redux";

import pageReducer from "./PageReducer";
import loginReducer from "./LoginReducer";
import mapReducer from "./MapReducer";
import commonReducer from "./CommonReducer";
import trackReducer from "./TrackReducer";
import fireReducer from "./FireReducer";
import reportReducer from "./ReportReducer";
import trackAnimationReducer from "./TrackAnimationReducer";

export default combineReducers({
  pageReducer,
  loginReducer,
  mapReducer,
  commonReducer,
  trackReducer,
  fireReducer,
  reportReducer,
  trackAnimationReducer,
});
