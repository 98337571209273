import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Paper,
} from "@material-ui/core";
import Draggable from "react-draggable";

import Btn from "../button/Btn";
import { Close as CloseIcon } from "@material-ui/icons";

import "./dialog.css";
import { LABELS } from "../../common/consts";

class FleetDialog extends Component {
  getPaperComponent = (props) => {
    const { draggable } = this.props;
    return draggable ? (
      <Draggable>
        <Paper {...props} />
      </Draggable>
    ) : (
      <Paper {...props} />
    );
  };
  render() {
    return (
      <Dialog
        fullWidth={this.props.fullWidth || true}
        maxWidth={this.props.maxWidth || "sm"}
        aria-labelledby={this.props.id}
        id={this.props.id}
        open={this.props.open}
        onClose={this.props.onCloseSettings}
        disableBackdropClick={this.props.disableBackdropClick || true}
        disableEscapeKeyDown={this.props.disableEscapeKeyDown}
        className={"dialogBox " + this.props.classNameWrapper}
        classes={{
          paperScrollPaper:
            "dialogBody " +
            this.props.classNameBody +
            (this.props.draggable ? "" : " notDraggable"),
        }}
        PaperComponent={this.getPaperComponent}
        BackdropProps={{
          invisible: this.props.disableBackdrop || false,
        }}
        hideBackdrop={this.props.disableBackdrop || false}
      >
        <DialogTitle id={this.props.id} className="dialogTitle">
          {this.props.title}
          <IconButton
            aria-label="Close"
            className="dialogCloseBtn"
            onClick={this.props.onCloseSettings}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialogContent">
          {this.props.children}
        </DialogContent>
        <DialogActions className="dialogBtn">
          {this.props.saveButton && (
            <Btn onClick={this.props.saveAction}>
              {this.props.saveBtnLabel || LABELS.SAVE_CHANGES}
            </Btn>
          )}
          {this.props.cancelButton && (
            <Btn className="cancelBtn" onClick={this.props.cancelAction}>
              {this.props.cancelBtnLabel || LABELS.CANCEL}
            </Btn>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default FleetDialog;
