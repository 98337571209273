import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

//Leaflet
import { Tooltip, Marker } from "react-leaflet";
import L from "leaflet";

import "react-leaflet-markercluster/dist/styles.min.css";
import "leaflet/dist/leaflet.css";

// Images
import markerIcon from "./images/markers/marker.png";

// Custom CSS
import "./styles/fleetmap.css";

import { commonJs } from "../../common/common";
import { FIREAPP_ACTIONS } from "../../../actions/FireAppActions";

class FireAppMarkers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  getMapMarker = (status) => {
    var markerUrl = markerIcon;
    const customMarker = L.icon({
      iconUrl: markerUrl,
      iconRetinaUrl: markerUrl,
      iconAnchor: null,
      popupAnchor: [0, -3],
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(16, 16),
      className: "incidentMarker",
    });
    return customMarker;
  };

  markerClick = () => {};

  onDragMarker = (e) => {
    var self = this;
    commonJs
      .getAddress(e.target._latlng.lat, e.target._latlng.lng)
      .then((res) => {
        if (document.getElementById("incidentAddress")) {
          document.getElementById("incidentAddress").value = res.Response;
        }
        self.props.setIncidentLocation({
          latLng: e.target._latlng,
          address: res.Response,
        });
      });
  };

  componentDidMount = () => {};

  componentWillUnmount() {}

  loadMarkers = () => {
    const { latLng, address } = this.props.fireReducer;
    let markerList = [latLng];
    return markerList.map((marker) => {
      const pos = [marker.lat, marker.lng];
      return (
        <Marker
          position={pos}
          icon={this.getMapMarker(0)}
          key={"fireMarker"}
          onclick={this.markerClick}
          className="mapMarker"
          draggable={true}
          ondragend={this.onDragMarker}
        >
          <Tooltip direction="right" offset={[10, -2]} permanent>
            <span>
              {"Location" + (address === "" ? "" : " (" + address + ")")}
            </span>
          </Tooltip>
        </Marker>
      );
    });
  };

  render() {
    const { latLng } = this.props.fireReducer;
    return latLng.lng !== undefined ? this.loadMarkers() : null;
  }
}

const mapStateToProps = (state) => {
  return {
    fireReducer: state.fireReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIncidentLatLng: (values) => {
      dispatch({
        type: FIREAPP_ACTIONS.SET_INCIDENT_LATLNG,
        values,
      });
    },
    setIncidentLocation: (values) => {
      dispatch({
        type: FIREAPP_ACTIONS.SET_INCIDENT_LOCATION,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(FireAppMarkers));
