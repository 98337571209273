import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { withSnackbar } from "notistack";

import FleetDialog from "../../../ui/dialogbox/FleetDialog";
import AutoComplete from "../../../ui/autocomplete/AutoComplete";

import { PAGE_ACTIONS } from "../../../../actions/PageActions";
import { API_FireApp } from "../../../apihandler/FireApp";
import { commonJs } from "../../../common/common";
import { NOTIFY } from "../../../common/consts";
import { CircularProgress } from "@material-ui/core";

class BroadcastMsg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUsers: [],
      users: null,
    };
  }

  componentDidMount() {
    var self = this;
    API_FireApp.getUsers().then((res) => {
      let list = res.Response;
      let users = [];
      for (var i = 0; i < list.length; i++) {
        users.push({
          id: list[i].username,
          name: list[i].username,
        });
      }
      self.setState({
        users: users,
      });
    });
  }

  saveSettings = () => {
    let self = this;
    const msg = document.getElementById("broadcastMsgText").value.trim();
    let users = [];
    const { selectedUsers } = this.state;
    for (var i = 0; i < selectedUsers.length; i++) {
      users.push(selectedUsers[i].id);
    }
    let args = {
      users: users,
      msg: msg,
    };
    if (msg === "") {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Message should not empty..!"
      );
      return false;
    }

    this.props.toggleLoader({
      loadingPopover: true,
    });

    API_FireApp.sendBroadcastMessage(args).then((res) => {
      self.props.toggleLoader({
        loadingPopover: false,
      });
      if (res.Result === 200) {
        commonJs.showNotify(
          self.props,
          NOTIFY.SUCCESS,
          "Message sent successfully..!"
        );
        self.props.toggleSettingsMenu({
          broadcastDialog: false,
        });
      } else {
        commonJs.showNotify(
          self.props,
          NOTIFY.ERROR,
          "Message not sent: " + res.Reason
        );
      }
    });
  };

  toggleSettingsDialog = () => {
    this.props.toggleSettingsMenu({
      broadcastDialog: false,
    });
  };

  onChangeAutocomplete = (val) => {
    this.setState({
      selectedUsers: val,
    });
  };

  render() {
    const { users, selectedUsers } = this.state;

    return (
      <FleetDialog
        open={true}
        onCloseSettings={this.toggleSettingsDialog}
        title={"Broadcast Message"}
        maxWidth="sm"
        fullWidth={true}
        cancelButton={true}
        saveButton={true}
        cancelAction={this.toggleSettingsDialog}
        saveAction={this.saveSettings}
        saveBtnLabel="Send"
        classNameWrapper="broadcastWrapper"
        draggable={true}
      >
        <Paper square className="settingsTabContainer">
          <div className="grid">
            <div className="row">
              <TextField
                id="broadcastMsgText"
                label="Message"
                classes={{ root: "txtfield" }}
                autoFocus={true}
                variant="outlined"
                size="small"
                multiline={true}
                rows={2}
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={""}
              />
            </div>
            {users ? (
              <div className="row">
                <AutoComplete
                  label={"Select User"}
                  list={users}
                  id="broadcastUsers"
                  style={{ marginBottom: 15, width: "100%" }}
                  onChange={this.onChangeAutocomplete}
                  defaultValue={selectedUsers}
                  multiple={true}
                  size="small"
                />
              </div>
            ) : (
              <div className="primaryLoader">
                <CircularProgress className="primary" />
              </div>
            )}
          </div>
        </Paper>
      </FleetDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageReducer: state.pageReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSettingsMenu: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_BROADCAST_DIALOG,
        values,
      });
    },
    toggleLoader: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_POPOVER_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(BroadcastMsg));
