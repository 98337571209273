import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";

import Login from "./components/login/Login";
import Error404 from "./components/error/Error404";
import MapPage from "./components/map/";
import { PrivacyPolicy } from "./components/privacy_policy/PrivacyPolicy";
import { DownloadApp } from "./components/downloadapp/DownloadApp";
import VideoCall from "./components/videocall";

import VideoRoom from "./components/videoroom";

import UserDashboard from "./components/reports";
import IncidentsReport from "./components/reports/list/IncidentsReport";
import VehiclesInfoReport from "./components/reports/list/VehiclesInfoReport";
import LiveReport from "./components/reports/list/LiveReport";
import PowerUnplugReport from "./components/reports/list/PowerUnplugReport";
import KmReport from "./components/reports/list/KmReport";
import ConsolidatedReport from "./components/reports/list/ConsolidatedReport";
import JourneyReport from "./components/reports/list/JourneyReport";
import TravelReport from "./components/reports/list/TravelReport";

import {
  CONFIG,
  LOCAL_STORAGE,
  ALERTS,
  NOTIFY,
} from "./components/common/consts";
import { commonJs } from "./components/common/common";
import { API_UserLogin } from "./components/apihandler/UserLogin";
import Loading from "./components/loader";

import fleetLogo from "./images/logo/logo.png";

import { LOGIN_ACTIONS } from "./actions/LoginActions";

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      mobile: false,
    };
  }

  componentDidMount() {
    this.checkLogin();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    // if (window.innerWidth <= 760) {
    //   if (window.location.pathname !== "/download-app") {
    //     window.location = "/download-app";
    //   }
    // } else if (window.location.pathname === "/download-app") {
    //   window.location = "/";
    // }
  }

  checkLogin = () => {
    var self = this;
    var userDataJson = commonJs.getItemFromLocal(LOCAL_STORAGE.USER_DATA);
    var userSettingsJson = commonJs.getItemFromLocal(
      LOCAL_STORAGE.USER_SETTINGS
    );
    if (
      userDataJson.login &&
      userSettingsJson !== undefined &&
      userDataJson !== undefined
    ) {
      API_UserLogin.setToken(userDataJson.token);
    }
    API_UserLogin.checkLogin()
      .then((res) => {
        userSettingsJson.login = res.Response;
        self.setState({
          loading: false,
        });
        if (res.Response) {
          self.props.setLoginInfo(userSettingsJson);
        }
      })
      .catch((err) => {
        err = err.toString();
        if (err.indexOf("Network Error") > -1) {
          commonJs.showNotify(self.props, NOTIFY.WARNING, ALERTS.NO_INTERNET);
        }
      });
  };

  redirectToLandingPage = () => {
    return <MapPage />;
  };

  redirectToMapPage = () => {
    const { privileges } = this.props.loginReducer;
    if (privileges.indexOf("mp") > -1) {
      return <h1>Map Page</h1>;
    } else {
      return <Error404 />;
    }
  };

  redirectToLoginPage = () => {
    const { loading } = this.state;
    return <Login loading={loading} />;
  };

  showLoadingDiv = () => {
    return <Loading />;
  };

  renderReports = (type) => {
    if (type === "dashboard") {
      return <UserDashboard />;
    } else if (type === "incident") {
      return <IncidentsReport />;
    } else if (type === "vehicles-info") {
      return <VehiclesInfoReport />;
    } else if (type === "live") {
      return <LiveReport />;
    } else if (type === "power-unplug") {
      return <PowerUnplugReport />;
    } else if (type === "km-report") {
      return <KmReport />;
    } else if (type === "consolidated") {
      return <ConsolidatedReport />;
    } else if (type === "journey") {
      return <JourneyReport />;
    } else if (type === "travel") {
      return <TravelReport />;
    }
  };

  redirectToVideoRoom = () => {
    return <VideoRoom />;
  };

  render() {
    const { loading } = this.state;

    const { login } = this.props.loginReducer;

    return (
      <React.Fragment>
        <Helmet>
          <title>{CONFIG.SITE_FULLNAME}</title>
          <link rel="shortcut icon" href={fleetLogo} />
        </Helmet>
        <BrowserRouter>
          <Switch>
            <Route
              path="/"
              render={
                !loading
                  ? login
                    ? this.redirectToLandingPage
                    : this.redirectToLoginPage
                  : this.showLoadingDiv
              }
              exact
            />
            <Route
              path="/map"
              render={
                !loading
                  ? login
                    ? this.redirectToMapPage
                    : this.redirectToLoginPage
                  : this.showLoadingDiv
              }
              exact
            />
            <Route path="/privacy-policy" component={PrivacyPolicy} exact />
            <Route path="/download-app" component={DownloadApp} exact />
            <Route
              path="/reports/dashboard"
              render={() => this.renderReports("dashboard")}
              exact
            />
            <Route
              path="/reports"
              render={() => this.renderReports("dashboard")}
              exact
            />
            <Route
              path="/reports/incident"
              render={() => this.renderReports("incident")}
              exact
            />
            <Route
              path="/reports/vehicles-information"
              render={() => this.renderReports("vehicles-info")}
              exact
            />
            <Route
              path="/reports/live"
              render={() => this.renderReports("live")}
              exact
            />
            <Route
              path="/reports/power-unplug"
              render={() => this.renderReports("power-unplug")}
              exact
            />
            <Route
              path="/reports/km-report"
              render={() => this.renderReports("km-report")}
              exact
            />
            <Route
              path="/reports/consolidated"
              render={() => this.renderReports("consolidated")}
              exact
            />
            <Route
              path="/reports/journey"
              render={() => this.renderReports("journey")}
              exact
            />
            <Route
              path="/reports/travel"
              render={() => this.renderReports("travel")}
              exact
            />
            <Route
              path="/video-call"
              render={() => {
                return <VideoCall />;
              }}
              exact
            />
            <Route
              path="/vc"
              render={
                !loading
                  ? login
                    ? this.redirectToVideoRoom
                    : this.redirectToLoginPage
                  : this.showLoadingDiv
              }
              exact
            />
            <Route component={Error404} />
          </Switch>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: (values) => {
      dispatch({
        type: LOGIN_ACTIONS.SET_LOGIN,
        values,
      });
    },
    setLoginInfo: (values) => {
      dispatch({
        type: LOGIN_ACTIONS.SET_LOGIN_INFO,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(App));
