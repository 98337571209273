import { FIREAPP_ACTIONS } from "../actions/FireAppActions";

let initialState = {
  address: " ",
  latLng: {},
  incidentRec: {},
  onlineUsers: [],
  slowLink: false,
};

const fireReducer = (state = initialState, action) => {
  const { type, values } = action;
  switch (type) {
    case FIREAPP_ACTIONS.SET_INCIDENT_LOCATION:
      return {
        ...state,
        latLng: values.latLng,
        address: values.address,
        incidentRec: { incidentAddress: values.address },
      };
    case FIREAPP_ACTIONS.SET_INCIDENT_LATLNG:
      return { ...state, latLng: values.latLng };
    case FIREAPP_ACTIONS.SET_INCIDENT_ADDRESS:
      return {
        ...state,
        address: values.address,
        incidentRec: values.incidentRec,
      };
    case FIREAPP_ACTIONS.SET_INCIDENT_DATA:
      return { ...state, incidentRec: values.incidentRec };
    case FIREAPP_ACTIONS.SET_ONLINE_USERS:
      return { ...state, onlineUsers: values.onlineUsers };
    case FIREAPP_ACTIONS.TOGGLE_SLOW_LINK:
      return { ...state, slowLink: values.slowLink };
    default:
      break;
  }
  return state;
};

export default fireReducer;
