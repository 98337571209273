import React, { Component } from "react";
import Divider from "@material-ui/core/Divider";
import CheckBoxGroup from "../../../../ui/checkbox/CheckBoxGroup";
import FleetPanel from "../../../../ui/panel/FleetPanel";
// import { ClickAwayListener } from "@material-ui/core";
import {
  AddCircleOutline,
  RemoveCircleOutline,
  Visibility
} from "@material-ui/icons";

import "../../maptabs/maptab.css";
import SearchButton from "../../../../ui/searchbutton/SearchButton";

class FilterGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandGroups: true,
      expandCities: false,
      expandStates: false
    };
  }

  loadExpandIcon = () => {
    return <AddCircleOutline />;
  };

  loadLeftIcon = () => {
    return <Visibility />;
  };

  loadMinimizeIcon = () => {
    return <RemoveCircleOutline />;
  };

  render() {
    const { expandGroups, expandCities, expandStates } = this.state;
    return (
      <React.Fragment>
        <div id="groupFilter" className="filterBox">
          <FleetPanel
            loadExpandIcon={this.loadExpandIcon()}
            loadMinimizeIcon={this.loadMinimizeIcon()}
            defaultExpand={expandGroups}
            title="Groups"
          >
            <SearchButton
              id="groupSearch"
              placeholder="Search By Groups"
              buttonClass="pad5 iconButton searchBtn"
              inputClass="SearchInput"
              iconwidth="18"
              iconheight="18"
            />
            <CheckBoxGroup
              key="grouplist"
              name="grouplist"
              list={[
                {
                  name: "Group Group Group 1 (10)",
                  value: "group1",
                  checked: false
                },
                {
                  name: "Group 2 (50)",
                  value: "group2",
                  checked: false
                },
                {
                  name: "Group 3 (100)",
                  value: "group3",
                  checked: false
                },
                {
                  name: "Group 4 (20)",
                  value: "group4",
                  checked: false
                },
                {
                  name: "Group 5 (150)",
                  value: "group5",
                  checked: false
                },
                {
                  name: "Group 6 (300)",
                  value: "group6",
                  checked: false
                },
                {
                  name: "Group 7 (100)",
                  value: "group7",
                  checked: false
                }
              ]}
            />
          </FleetPanel>
        </div>
        <Divider className="mB10" />
        <div id="cityFilter" className="filterBox">
          <FleetPanel
            loadExpandIcon={this.loadExpandIcon()}
            loadMinimizeIcon={this.loadMinimizeIcon()}
            defaultExpand={expandCities}
            title="Cities"
          >
            <SearchButton
              id="citySearch"
              placeholder="Search By City"
              buttonClass="pad5 iconButton searchBtn"
              inputClass="SearchInput"
              iconwidth="18"
              iconheight="18"
            />
            <CheckBoxGroup
              key="citylist"
              name="citylist"
              list={[
                {
                  name: "Bangalore (5)",
                  value: "group1",
                  checked: true
                },
                {
                  name: "Delhi (50)",
                  value: "group2",
                  checked: false
                },
                {
                  name: "Mumbai (100)",
                  value: "group3",
                  checked: false
                },
                {
                  name: "Mangalore (20)",
                  value: "group4",
                  checked: false
                },
                {
                  name: "Chennai (50)",
                  value: "group5",
                  checked: false
                },
                {
                  name: "Surat (20)",
                  value: "group6",
                  checked: false
                },
                {
                  name: "Jaipur (20)",
                  value: "group7",
                  checked: false
                }
              ]}
            />
          </FleetPanel>
        </div>
        <Divider className="mB10" />
        <div id="stateFilter" className="filterBox">
          <FleetPanel
            loadExpandIcon={this.loadExpandIcon()}
            loadMinimizeIcon={this.loadMinimizeIcon()}
            defaultExpand={expandStates}
            title="States"
          >
            <SearchButton
              id="stateSearch"
              placeholder="Search By State"
              buttonClass="pad5 iconButton searchBtn"
              inputClass="SearchInput"
              iconwidth="18"
              iconheight="18"
            />
            <CheckBoxGroup
              key="statelist"
              name="statelist"
              list={[
                {
                  name: "Karnataka (100)",
                  value: "group1",
                  checked: true
                },
                {
                  name: "Maharastra (50)",
                  value: "group2",
                  checked: false
                },
                {
                  name: "Gujrath (100)",
                  value: "group3",
                  checked: false
                },
                {
                  name: "Haryana (20)",
                  value: "group4",
                  checked: false
                },
                {
                  name: "Rajasthan (20)",
                  value: "group4",
                  checked: false
                },
                {
                  name: "Bihar (20)",
                  value: "group4",
                  checked: false
                },
                {
                  name: "Andra Pradesh (10)",
                  value: "group4",
                  checked: false
                }
              ]}
            />
          </FleetPanel>
        </div>
      </React.Fragment>
    );
  }
}

export default FilterGroup;
