import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { Replay as Reload } from "@material-ui/icons";

import SearchButton from "../../../../ui/searchbutton/SearchButton";
import UsersGrid from "./UsersGrid";
import DialogConfirm from "./DialogConfirm";

import "./users.css";

class UsersTab extends Component {
  onSearch = (e) => {
    this._usersGrid.onSearch(e.target.value.trim());
  };

  routeReload = () => {};

  deleteZone = (id, name) => {
    this._dialogConfirm.toggleConfirmDialog(id, name);
  };

  deleteAllZones = () => {
    this._dialogConfirm.toggleConfirmDialog("");
  };

  reload = () => {
    this._usersGrid.loadReloadRecords();
  };

  render() {
    return (
      <div className="zonesTab usersTab">
        <ul>
          <li>
            <SearchButton
              id="searchUser"
              placeholder="Search By User Name"
              buttonClass="pad5 iconButton searchBtn"
              inputClass="SearchInput"
              iconwidth="18"
              iconheight="18"
              onChange={this.onSearch}
            />
          </li>
          <li className="poiBtn">
            <Tooltip title="Reload" placement="bottom">
              <IconButton onClick={this.reload}>
                <Reload />
              </IconButton>
            </Tooltip>
          </li>
        </ul>

        <UsersGrid
          childRef={(ref) => (this._usersGrid = ref)}
          deleteZone={this.deleteZone}
        />

        <DialogConfirm
          childRef={(ref) => (this._dialogConfirm = ref)}
          deleteZone={this.deleteZone}
        />
      </div>
    );
  }
}

export default UsersTab;
