import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import FleetDialog from "../../../ui/dialogbox/FleetDialog";
import { commonJs } from "../../../common/common";
import { NOTIFY } from "../../../common/consts";
import { PAGE_ACTIONS } from "../../../../actions/PageActions";

import AutoComplete from "../../../ui/autocomplete/AutoComplete";
import { FIREAPP_ACTIONS } from "../../../../actions/FireAppActions";
import { API_FireApp } from "../../../apihandler/FireApp";

class DialogZone extends Component {
  constructor(props) {
    super(props);
    const { incidentRec } = this.props.fireReducer;
    this.state = {
      selectedVehicles: incidentRec.imeis || [],
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  saveRecord = () => {
    const callerName = document.getElementById("callerName").value.trim();
    const callerNo1 = document.getElementById("callerNo1").value.trim();
    const callerNo2 = document.getElementById("callerNo2").value.trim();
    const incidentDetails = document
      .getElementById("incidentDetails")
      .value.trim();
    const incidentAddress = document
      .getElementById("incidentAddress")
      .value.trim();
    let { lat, lng } = this.props.fireReducer.latLng;

    if (!lat) {
      const { latitude, longitude } = this.props.fireReducer.incidentRec;
      if (latitude) {
        lat = latitude;
        lng = longitude;
      }
    }

    const { selectedVehicles } = this.state;

    let imeis = selectedVehicles.map((u) => {
      return u.imei || u;
    });

    var self = this;
    if (callerName === "") {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Caller name should not be empty..!"
      );
      return false;
    } else if (callerNo1 === "") {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Caller Phone No. should not be empty..!"
      );
      return false;
    } else if (incidentDetails === "") {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Incident Details should not be empty..!"
      );
      return false;
    } else if (incidentAddress === "") {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Incident Address should not be empty..!"
      );
      return false;
    } else if (!lat) {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Select location by clicking on map..!"
      );
      return false;
    } else if (imeis.length === 0) {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Please assign the vehicles..!"
      );
      return false;
    }

    const args = {
      incidentId: this.props.fireReducer.incidentRec.incidentId || "0",
      callerName: callerName || "",
      callerNo1: callerNo1 || "",
      callerNo2: callerNo2 || "",
      incidentDetails: incidentDetails || "",
      incidentAddress: incidentAddress || "",
      latitude: lat,
      longitude: lng,
      imeis: imeis || [],
    };

    this.props.togglePopover({
      loadingPopover: true,
    });

    API_FireApp.saveIncident(args)
      .then((res) => {
        self.props.togglePopover({
          loadingPopover: false,
        });
        if (res.Result === 200) {
          self.props.toggleDialog({
            addIncidentDialog: false,
          });
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            "Incident saved successfully..!"
          );
        } else {
          commonJs.showNotify(
            self.props,
            NOTIFY.ERROR,
            "Incident not saved: " + res.Reason
          );
        }
      })
      .catch((err) => {
        self.props.togglePopover({
          loadingPopover: false,
        });
        console.log(err);
      });
  };

  toggleDialog = (rec) => {
    const { addIncidentDialog } = this.props.pageReducer;
    this.props.toggleDialog({
      addIncidentDialog: !addIncidentDialog,
    });
  };

  closeDialog = () => {
    const { addIncidentDialog } = this.props.pageReducer;
    this.props.toggleDialog({
      addIncidentDialog: !addIncidentDialog,
    });
    this.props.setIncidentData({
      incidentRec: {},
    });
    this.props.setIncidentLocation({
      latLng: {},
      address: "",
    });
  };

  onChangeAutocomplete = (vehicle) => {
    let imeis = [];
    for (var i = 0; i < vehicle.length; i++) {
      imeis.push({ imei: vehicle[i].imei, name: vehicle[i].name });
    }
    this.setState({
      selectedVehicles: imeis,
    });
  };

  render() {
    const { selectedVehicles } = this.state;

    const { mapReducer, fireReducer, popup } = this.props;

    let { lat, lng } = fireReducer.latLng;

    const { incidentRec } = fireReducer;

    if (lat === undefined) {
      lat = "";
      lng = "";
    }

    const { tmpVehicles } = mapReducer;
    let vehiclesJson = {};
    let vehicles = tmpVehicles.map((v) => {
      let arr = {
        imei: v.imei,
        name: v.name,
      };
      vehiclesJson[v.imei] = v.name;
      return arr;
    });

    let selectedImeis = [];
    for (var i = 0; i < selectedVehicles.length; i++) {
      selectedImeis.push({
        imei: selectedVehicles[i].imei,
        name: vehiclesJson[selectedVehicles[i].imei],
      });
    }

    return (
      <FleetDialog
        id="addIncident"
        open={popup}
        onCloseSettings={this.closeDialog}
        title={incidentRec.incidentId ? "Edit Incident" : "Add Incident"}
        maxWidth="xs"
        fullWidth={false}
        cancelButton={true}
        saveButton={true}
        cancelAction={this.closeDialog}
        saveAction={this.saveRecord}
        saveBtnLabel="OK"
        disableBackdropClick={true}
        disableBackdrop={true}
        classNameBody="zonePopupBody"
        classNameWrapper="zonePopupWrapper"
        draggable
      >
        {popup && (
          <div className="grid">
            <div className="row">
              <TextField
                id="callerName"
                label="Caller Name"
                classes={{ root: "txtfield" }}
                autoFocus={false}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={incidentRec.callerName}
              />
            </div>

            <div className="row">
              <div className="col">
                <TextField
                  id="callerNo1"
                  label="Caller Contact No.1"
                  classes={{ root: "txtfield" }}
                  autoFocus={false}
                  variant="outlined"
                  size="small"
                  onMouseDown={(e) => e.stopPropagation()}
                  defaultValue={incidentRec.callerNo1}
                />
              </div>
              <div className="col">
                <TextField
                  id="callerNo2"
                  label="Caller Contact No.2"
                  classes={{ root: "txtfield" }}
                  variant="outlined"
                  size="small"
                  onMouseDown={(e) => e.stopPropagation()}
                  defaultValue={incidentRec.callerNo2}
                />
              </div>
            </div>

            <div className="row">
              <TextField
                id="incidentDetails"
                label="Incident Details"
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={incidentRec.incidentDetails}
                multiline={true}
                rows={2}
              />
            </div>

            <div className="row">
              <TextField
                id="incidentAddress"
                label="Incident Address"
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={incidentRec.incidentAddress}
                multiline={true}
                rows={2}
              />
            </div>

            <div className="row">
              <AutoComplete
                label={"Assigned Vehicles"}
                list={vehicles}
                id="assignedVehicles"
                style={{ marginBottom: 15, width: "100%" }}
                onChange={this.onChangeAutocomplete}
                defaultValue={selectedImeis}
                multiple={true}
                size="small"
              />
            </div>

            <div className="row">
              {lat !== "" && (
                <h4 style={{ margin: 0, fontSize: 10 }}>
                  Google Map Link:{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={"https://maps.google.com/maps?q=" + lat + "," + lng}
                  >
                    {lat + "," + lng}
                  </a>
                </h4>
              )}
            </div>
          </div>
        )}
      </FleetDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fireReducer: state.fireReducer,
    pageReducer: state.pageReducer,
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDialog: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_ADD_INCIDENT_DIALOG,
        values,
      });
    },
    setIncidentData: (values) => {
      dispatch({
        type: FIREAPP_ACTIONS.SET_INCIDENT_DATA,
        values,
      });
    },
    togglePopover: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_POPOVER_LOADING,
        values,
      });
    },
    setIncidentLocation: (values) => {
      dispatch({
        type: FIREAPP_ACTIONS.SET_INCIDENT_LOCATION,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DialogZone));
