import React, { Component } from "react";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";

import { PAGE_ACTIONS } from "../../../../../../../actions/PageActions";
import FleetDialog from "../../../../../../ui/dialogbox/FleetDialog";
import EditVehicle from "./EditVehicle";
import { commonJs } from "../../../../../../common/common";
import { NOTIFY } from "../../../../../../common/consts";
import { API_FireApp } from "../../../../../../apihandler/FireApp";

const DefaultDismissBtn = () => {
  return <button className="snackbar-btn">{"Dismiss"}</button>;
};

class VehicleSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      draggable: true,
      sensorPopup: false,
      sensor: {},
    };
  }

  componentDidMount() {
    this.getSettings();
  }

  showVehicleMenu = (imei) => {
    document.getElementById("voptions-" + imei).classList.toggle("active");
  };

  closeEditVehiclePopup = () => {
    this.props.toggleEditVehicle({
      vehicleEditPopup: false,
      selectedVehicle: "",
    });
  };

  saveSettings = () => {
    const { selectedVehicle } = this.props.pageReducer;
    let args = {
      imeis: [selectedVehicle],
      // vehicleName: document.getElementById("ev-VehicleName").value.trim(),
      // vehicleType: document.getElementById("ev-VehicleType").value.trim(),
      // plateNumber: document.getElementById("ev-VehiclePlateNo").value.trim(),
      // simNumber: document.getElementById("ev-VehicleSimNo").value.trim(),
      // driverName: document.getElementById("ev-VehicleDriverName").value.trim(),
      // driverNumber: document.getElementById("ev-VehicleDriverNo").value.trim(),
      status: document.getElementById("vehicleLoadStatus").value.trim(),
      incidentId: "0",
    };

    if (args.status === "") {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Please select vehicle status..!",
        <DefaultDismissBtn />
      );
      return;
    } else if (args.status === "busy") {
      args.status = "accept";
    }

    let self = this;
    API_FireApp.setAction(args)
      .then((res) => {
        if (res.Result === 200) {
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            "Saved Successfully..!",
            <DefaultDismissBtn />
          );
          self.closeEditVehiclePopup();
          self.props.reloadVehicles();
        } else {
          commonJs.showNotify(
            self.props,
            NOTIFY.WARNING,
            "Not Saved Successfully..!",
            <DefaultDismissBtn />
          );
        }
      })
      .catch((err) => {
        commonJs.showNotify(
          self.props,
          NOTIFY.WARNING,
          "Error Occured: " + err.toString(),
          <DefaultDismissBtn />
        );
      });
  };

  getSettings = () => {};

  toggleTab = (event, value) => {
    this.setState({ activeTab: value });
  };

  toggleDraggable = () => {
    const { draggable } = this.state;
    this.setState({
      draggable: !draggable,
    });
  };

  toggleSensorPopup = (type, sensor) => {
    const { sensorPopup, draggable } = this.state;
    let sensorData = sensor;
    if (sensor === undefined) {
      sensorData = { name: "", param: "", units: "", formula: "" };
    }
    this.setState({
      sensorPopup: !sensorPopup,
      draggable: !draggable,
      sensor: sensorData,
    });
  };

  render() {
    const { vehicleEditPopup, selectedVehicle } = this.props.pageReducer;

    const { draggable } = this.state;

    return (
      vehicleEditPopup && (
        <FleetDialog
          id="editVehicle"
          open={vehicleEditPopup}
          onCloseSettings={this.closeEditVehiclePopup}
          title={"Edit Vehicle"}
          maxWidth="sm"
          fullWidth={true}
          cancelButton={true}
          saveButton={true}
          cancelAction={this.closeEditVehiclePopup}
          saveAction={this.saveSettings}
          saveBtnLabel="OK"
          classNameWrapper="editVehicleWrapper"
          classNameBody="editVehicleBody"
          draggable={draggable}
        >
          {/* <Tabs
            value={activeTab}
            onChange={this.toggleTab}
            variant="fullWidth"
            scrollButtons="auto"
            className="editVehicleTab"
            classes={{
              indicator: "editVehicleTabIndicator",
              scrollButtons: "editVehicleNavTabButtons"
            }}
          >
            <Tab label={"Vehicle Details"} classes={{ root: "tab" }} />
            <Tab label={"Sensors"} classes={{ root: "tab" }} />
          </Tabs> */}

          {/* {activeTab === 0 && <EditVehicle imei={selectedVehicle} />}

          {activeTab === 1 && (
            <VehicleSensors
              imei={selectedVehicle}
              childRef={ref => (this._sensorsGrid = ref)}
              toggleDraggable={this.toggleDraggable}
              toggleSensorPopup={this.toggleSensorPopup}
              sensorPopup={sensorPopup}
              sensor={sensor} 
            />
          )} */}

          <EditVehicle imei={selectedVehicle} />
        </FleetDialog>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageReducer: state.pageReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleEditVehicle: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_EDIT_VEHICLE_MENU,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(VehicleSettings));
